import {Component, DestroyRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpEventType} from '@angular/common/http';
import {finalize, Observable, of, Subscription, switchMap} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormControl, Validators} from '@angular/forms';
import {SnackbarService} from 'src/app/services/front/snackbar.service';
import {TenantFileService} from 'src/app/services/back/tenant-file.service';
import {FileLink, FileLinkForm, FileType, FileUploadForm} from 'src/app/services/model/tenant-file.model';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';

@Component({
  selector: 'app-upload-file',
	standalone: false,
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

	@Input() defaultFiles: FileLink[] = [];
	@Input() limit: number = 5;
	@Input() sizeLimit: number = 200;

	@Output() filesChange: EventEmitter<string[]> = new EventEmitter<string[]>();
	@Output() uploading: EventEmitter<boolean> = new EventEmitter<boolean>();

	tenantId: string;

	_uploadingQueue: {}[] = [];
	fileList: FileListData[] = [];
	fileTypeSelected: FileType;
	displayFormPanel: boolean;
	linkInputForm: FormControl;

	protected readonly UploadFileStatus = UploadFileStatus;
	protected readonly FileType = FileType;

	constructor(protected currentTenantService: CurrentTenantService,
							protected tenantFileService: TenantFileService,
							protected snackbarService: SnackbarService,
							protected destroyRef: DestroyRef) {
	}

	ngOnInit() {
		this.currentTenantService.getCurrentTenantId()
			.subscribe(tenantId => this.tenantId = tenantId);
		this.buildFileList();
		this.linkInputForm = new FormControl(null, [Validators.required]);
		this.fileTypeSelected = FileType.FILE;
		this.displayFormPanel = this.fileList.length === 0;
	}

	private buildFileList(): void {
		this.fileList = this.defaultFiles.map(item => ({
			fileLinkId: item.fileLinkId,
			name: item.name,
			type: item.type,
			progress: 100,
			uploadStatus: UploadFileStatus.UPLOADED,
			toDelete: false,
			link: item.link,
			size: item.size ? this.sizePrettily(item.size) : undefined
		}));
	}

	selectFile() {
		const input: HTMLInputElement = document.createElement('input');
		input.type = 'file';
		input.multiple = true;
		// Append input to body for Safari compatibility
		document.body.appendChild(input);
		input.addEventListener('change', (event: Event) => {
			const target: HTMLInputElement = event.target as HTMLInputElement;
			document.body.removeChild(input);
			if (target.files && target.files.length > 0) {
				Array.prototype.forEach.call(target.files, (file: File) => {
					this.uploadFile(file);
				});
			}
		});
		input.click();
	}

	onDrop(event: any) {
		event.preventDefault();
		if (event.dataTransfer.files.length > 0) {
			Array.prototype.forEach.call(event.dataTransfer.files, (file) => {
				this.uploadFile(file);
			})
		}
	}

	private uploadFile(file: File): void {
		const fileData: FileListData = this.buildFileDataFromFile(file);
		if (this.isDocumentNameUnique(fileData) && this.canAddFile()) {
			this.fileList.push(fileData);
			this.displayFormPanel = false;
			if ((file.size / 1000000) > this.sizeLimit) {
				fileData.uploadStatus = UploadFileStatus.FAILED_TOO_BIG;
			} else {
				const form: FileUploadForm = {
					file: file
				};
				fileData.subscription = this.startUploading()
					.pipe(
						switchMap(() => this.tenantFileService.createTenantFileUpload(this.tenantId, form)),
						takeUntilDestroyed(this.destroyRef),
						finalize(() => this.endUploading()))
					.subscribe({
						next: (event) => {
							if (event) {
								if (event.type === HttpEventType.UploadProgress && event.total) {
									fileData.progress = Math.round(100 * event.loaded / event.total);
								} else if (event.type === HttpEventType.Response && event.body) {
									this.uploadFinalized(fileData, event.body);
								}
							} else {
								this.uploadError(fileData);
							}
						},
						error: () => {
							this.uploadError(fileData);
						}
					});
			}
		}
	}

	uploadLink() {
		if (!this.linkInputForm.value.startsWith('http://') && !this.linkInputForm.value.startsWith('https://')) {
			this.linkInputForm.setValue(`https://${this.linkInputForm.value}`)
		}
		const fileData: FileListData = this.buildFileDataFromLink(this.linkInputForm.value);
		if (this.linkInputForm.valid && this.isDocumentNameUnique(fileData) && this.canAddFile()) {
			this.fileList.push(fileData);
			const form: FileLinkForm = {
				link: fileData.link!
			};
			fileData.subscription = this.startUploading()
				.pipe(
					switchMap(() => this.tenantFileService.createTenantFileLink(this.tenantId, form)),
					takeUntilDestroyed(this.destroyRef),
					finalize(() => this.endUploading()))
				.subscribe({
					next: (fileLinkId) => {
						this.uploadFinalized(fileData, fileLinkId);
						this.resetFormState();
					},
					error: () => {
						this.uploadError(fileData);
					},
				});
		}
	}

	switchDelete(file: FileListData) {
		if (file.fileLinkId) {
			file.toDelete = !file.toDelete;
		}
		this.displayFormPanel = this.displayFormPanel && this.canAddFile();
		this.emitChanges();
	}

	cancelUpload(file: FileListData) {
		if (file.subscription) {
			file.subscription.unsubscribe();
		}
		this.fileList = this.fileList.filter(item => item.name !== file.name);
		this.emitChanges();
	}

	private isDocumentNameUnique(fileData: FileListData): boolean {
		return !this.fileList.find(data => data.name === fileData.name);
	}

	canAddFile(): boolean {
		return this.getValidFiles(false).length < this.limit;
	}

	getValidFiles(onlyUploaded: boolean): FileListData[] {
		// TODO: check performance, maybe set the result only when the list changed ?
		return this.fileList
			.filter(f => !f.toDelete && f.uploadStatus !== UploadFileStatus.FAILED && f.uploadStatus !== UploadFileStatus.FAILED_TOO_BIG)
			.filter(f => !onlyUploaded || (f.fileLinkId && f.uploadStatus === UploadFileStatus.UPLOADED));
	}

	private buildFileDataFromLink(link: string): FileListData {
		return {
			name: link,
			type: FileType.LINK,
			link: link,
			uploadStatus: UploadFileStatus.UPLOADING,
			progress: 0,
		}
	}

	private buildFileDataFromFile(file: File): FileListData {
		return {
			name: file.name,
			type: FileType.FILE,
			uploadStatus: UploadFileStatus.UPLOADING,
			progress: 0,
			size: this.sizePrettily(file.size)
		}
	}

	private uploadFinalized(fileData: FileListData, fileLinkId: string): void {
		fileData.fileLinkId = fileLinkId;
		fileData.uploadStatus = UploadFileStatus.UPLOADED;
		fileData.progress = 100;
		this.emitChanges();
		const msg: string = fileData.type === FileType.LINK ? 'Link uploaded' : 'File uploaded';
		this.snackbarService.show(msg);
	}

	private uploadError(fileData: FileListData): void {
		fileData.uploadStatus = UploadFileStatus.FAILED;
		const msg: string = fileData.type === FileType.LINK ? 'Error while uploading the link' : 'Error while uploading the file';
		this.snackbarService.show(msg, 'danger-snack');
	}

	private emitChanges(): void {
		const fileIds: string[] = this.getValidFiles(true)
			.map(f => f.fileLinkId!);
		this.filesChange.emit(fileIds);
	}

	private resetFormState() {
		this.linkInputForm.reset();
		this.displayFormPanel = false;
		this.fileTypeSelected = FileType.FILE;
	}

	private sizePrettily(size: number | undefined): string {
		if (!size) return '';
		if (size < 1024) {
			return `${size} B`;
		} else if (size < 1024 * 1024) {
			return `${Math.round(size / 1024)} KB`;
		} else if (size < 1024 * 1024 * 1024) {
			return `${Math.round(size / (1024 * 1024))} MB`;
		} else {
			return `${Math.round(size / (1024 * 1024 * 1024))} GB`;
		}
	}

	private startUploading(): Observable<{}> {
		this._uploadingQueue.push({});
		this.uploading.emit(this._uploadingQueue.length > 0);
		return of({});
	}

	private endUploading(): void {
		this._uploadingQueue.pop();
		this.uploading.emit(this._uploadingQueue.length > 0);
	}
}

enum UploadFileStatus {
	UPLOADING,
	UPLOADED,
	FAILED,
	FAILED_TOO_BIG
}

interface FileListData {
	fileLinkId?: string;
	name: string;
	type: FileType;
	progress: number;
	uploadStatus: UploadFileStatus;
	toDelete?: boolean;
	link?: string|null;
	size?: string|null;
	subscription?: Subscription;
}
