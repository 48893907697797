import {Component, OnDestroy, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {MatDialogRef} from '@angular/material/dialog';
import {CatalogApplicationService} from 'src/app/services/back/catalog-application.service';
import {distinctUntilChanged, finalize, forkJoin, mergeMap, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime, map} from 'rxjs/operators';
import {ImageUtils} from 'src/app/utils/image.utils';
import {Logo} from 'src/app/services/application-instance.service';
import {ApplicationUtils, AppType} from '../application.utils';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {CatalogApplicationOverview, CatalogCategoryStatistics} from 'src/app/services/model/catalog-application.model';
import {ApplicationCreation, HostingType} from 'src/app/services/model/new-application.model';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {Category, ApplicationCategoryForm} from 'src/app/services/model/application-category.model';
import {ApplicationCategoryService} from 'src/app/services/back/application-category.service';
import {
	PREFIX_REGEXP,
	prefixValidator,
	SUFFIX_DOMAIN_REGEXP,
	SUFFIX_PORT_REGEXP, suffixDomainValidator, suffixPortValidator,
	URL_REGEXP, urlValidator
} from "../../../../utils/forms.utils";

@Component({
	selector: "catalog",
	standalone: false,
	templateUrl: "./catalog.component.html",
	styleUrls: ["./catalog.component.scss"],
	animations: [
		trigger('in-out-animation', [
			transition(':enter', [
				style({ opacity: 0.01 }),
				animate('300ms ease-in-out', style({ opacity: 1 }))
			]),
			transition(':leave', [
				animate('300ms ease-in-out', style({ display: 'none', opacity: 0 }))
			])
		]),
		trigger('width-shrink-animation', [
			transition(':enter', [
				style({ opacity: 0, display: 'none', 'max-height': '80vh', height: '80vh' }),
				animate('300ms 300ms ease-in-out', style({ display: 'block', opacity: 1, width: '400px', 'max-height': '520px', height: '520px' })),
			])
		]),
		trigger('height-animation', [
			transition(':enter', [
				style({ opacity: 0, 'max-height': '0' }),
				animate('300ms ease-in-out', style({ opacity: 1, 'max-height': '230px' })),
			]),
			transition(':leave', [
				style({ opacity: 1, 'max-height': '230px' }),
				animate('300ms ease-in-out', style({ opacity: 0, 'max-height': '0' })),
			])
		]),
		trigger('validators-appear', [
			transition(':enter', [
				style({ opacity: 0, display: 'none', transform: 'translateY(-10px)'}),
				animate('300ms ease-in-out', style({ opacity: 1, display: 'block', transform: 'translateY(0)' })),
			])
		])
	]
})
export class CatalogComponent implements OnInit, OnDestroy {

	tenantId: string;
	subscription: Subscription = new Subscription();
	_savingNewCategory: boolean = false;

	constructor(
		public dialogRef: MatDialogRef<CatalogComponent, CatalogDialogResult>,
		protected currentTenantService: CurrentTenantService,
		protected translateService: TranslateService,
		protected applicationCatalogService: CatalogApplicationService,
		protected applicationCategoryService: ApplicationCategoryService,
		protected newApplicationService: NewApplicationService,
		protected router: Router
	) {}

	ngOnInit(): void {
		this.isLoading = true;
		this.language = this.translateService.getDefaultLang() === 'fr' ? 'fr' : 'en';

		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(
				tap(tenantId => this.tenantId = tenantId),
				mergeMap(() => forkJoin([
					this.applicationCatalogService.getCatalogCategoryStatistics(this.tenantId, this.language),
					this.applicationCategoryService.getAllApplicationCategoryByTenantId(this.tenantId)
				])))
			.subscribe({
				next: ([categoriesStats, categories]) => {
					this.catalogCategoryStatistics = categoriesStats;
					this.buildCategoryDropdown(categories);
					this.buildApplicationTypeDropdown();
					this.getApplicationBasketFromSessionStorage();
					this.isLoading = false;
				}
			}));

		this.subscription.add(this.searchCategoryControl.valueChanges.pipe(distinctUntilChanged())
			.subscribe(text => this.filterCategoryList(text)));

		// Search
		this.searchFormControl.valueChanges.pipe(debounceTime(500)).subscribe(value => {
			this.onSearch({category: this.selectedCategoryId, searchQuery: value || undefined, page: this.currentPage, queryAllCategory: this.lastSearchEvent?.queryAllCategory})
		})
	}

	protected language: string = 'en';
	clearInput: Subject<void> = new Subject<void>();
	isLoading: boolean = false;
	selectedCategoryId: string | undefined;
	selectAll: boolean = true;
	catalogCategoryStatistics: CatalogCategoryStatistics;

	onCategorySelected(categoryId: string) {
		this.selectedCategoryId = categoryId;
		this.currentPage = 1;
		this.isSearching = true;
		this.selectAll = false;
		this.searchEvent.next({category: categoryId, queryAllCategory: false, searchQuery: this.lastSearchEvent?.searchQuery, page: this.currentPage});
	}

	getCategoryName(categoryId: string | undefined): string {
		return this.catalogCategoryStatistics.categories.find(category => category.category.id === categoryId)?.category.name || '';
	}

	onClose() {
		this.dialogRef.close()
	}

	// Add custom app

	displayCustomApp(appName?: string) {
		this.isAddingCustomApp = true;
		this.customAppNameControl.setValue(appName || '');
	}

	hideCustomApp() {
		this.isAddingCustomApp = false;
		this.onClear();
	}

	customAppFormGroup = new FormGroup({
		name: new FormControl('', Validators.required),
		url: new FormControl('', [prefixValidator(), suffixDomainValidator(), suffixPortValidator(), urlValidator()]),
		category: new FormControl(''),
		type: new FormControl('', Validators.required),
		description: new FormControl('')
	})

	get customAppNameControl(): FormControl {
		return this.customAppFormGroup.get('name') as FormControl;
	}

	get customAppUrlControl(): FormControl {
		return this.customAppFormGroup.get('url') as FormControl;
	}

	get customAppCategoryControl(): FormControl {
		return this.customAppFormGroup.get('category') as FormControl;
	}

	get customAppTypeControl(): FormControl {
		return this.customAppFormGroup.get('type') as FormControl;
	}

	get customAppDescriptionControl(): FormControl {
		return this.customAppFormGroup.get('description') as FormControl;
	}

	createCategory(newCategory: string): void {
		const form: ApplicationCategoryForm = {
			name: newCategory
		};
		this.switchSavingNewCategory()
			.pipe(
				switchMap(() => this.applicationCategoryService.createApplicationCategoryForTenant(this.tenantId, form)),
				finalize(() => this.switchSavingNewCategory()))
			.subscribe(categoryId => {
				const newData: Category = {
					categoryId: categoryId,
					name: newCategory
				};
				this.categoryList.push(newData);
				this.customAppCategoryControl.setValue(newData);
				this.searchCategoryControl.reset('');
			});
	}

	switchSavingNewCategory(): Observable<{}> {
		this._savingNewCategory = !this._savingNewCategory;
		return of({});
	}

	isAddingCustomApp = false;

	onAddCustomApp() {
		this.isAddingCustomApp = false;
		this.isSearching = false;
		this.lastSearchEvent = { queryAllCategory: true,  page: 1 };

		const {backgroundColor, fontColor} = ImageUtils.getRandomColors();
		const name = ApplicationUtils.generateApplicationTileName(this.customAppNameControl.value || '');

		const customApp: CustomApplication = {
			logo: { backgroundColor, fontColor, name } as Logo,
			name: this.customAppNameControl.value ?? 'unknown',
			description: this.customAppDescriptionControl.value ?? undefined,
			type: (this.customAppTypeControl.value as AppType).id ?? undefined,
			category: (this.customAppCategoryControl.value as Category)?.categoryId ?? undefined
		};

		const url = this.customAppUrlControl.value;

		if (url) {
			const cleanUrl = this.formatUrl(url);
			if (cleanUrl === undefined) {
				this.customAppUrlControl.setErrors({url: true});
				return;
			}

			customApp.url = cleanUrl.toString();
		}

		this.applicationBasket.push(customApp);

		this.addToSessionStorage(this.applicationBasket);

		this.customAppFormGroup.reset();
	}

	categoryList: Category[] = [];
	filteredCategoryList: Category[] = [];
	searchCategoryControl: FormControl<string|null> = new FormControl('');

	private buildCategoryDropdown(categories: Category[]) {
		this.categoryList = categories.sort((a, b) => a.name.localeCompare(b.name));
		this.filterCategoryList();
	}

	filterCategoryList(filter?: string|null): void {
		this.filteredCategoryList = this.categoryList
			.filter(value => !filter || value.name.toLowerCase().includes(filter.toLowerCase()))
			.sort((a, b) => a.name.localeCompare(b.name));
	}

	applicationTypeDropdown: AppType[] = [];

	private buildApplicationTypeDropdown(): void {

		this.applicationTypeDropdown = ApplicationUtils.getApplicationTypes();

	}

	private formatUrl(url: string): URL | undefined {
		const input = url;
		let cleanUrl: string;

		if (PREFIX_REGEXP.test(input)) {
			if (SUFFIX_DOMAIN_REGEXP.test(input.split('?')[0]) || SUFFIX_PORT_REGEXP.test(input.split('?')[0].split('/').slice(0, 3).join('/'))) {
				cleanUrl = input.split('?')[0];
			} else {
				cleanUrl = `${input.split('?')[0]}.com`;
			}
		} else {
			if (SUFFIX_DOMAIN_REGEXP.test(`https://${input}`.split('?')[0]) || SUFFIX_PORT_REGEXP.test(`https://${input}`.split('?')[0])) {
				cleanUrl = `https://${input}`.split('?')[0];
			} else {
				cleanUrl = `${`https://${input}`.split('?')[0]}.com`;
			}
		}

		if (URL_REGEXP.test(cleanUrl)) {
			try {
				return new URL(cleanUrl);
			} catch (e) {
				console.error(e)
				return undefined;
			}
		} else {
			return undefined;
		}

	}

	// Search actions

	searchResults: CatalogApplicationOverview[] = [];

	isSearching = false;

	hasMoreApplication = false;

	protected lastSearchEvent: {category?: string, searchQuery?: string, queryAllCategory?: boolean,  page: number} | undefined = { queryAllCategory: true, page: 1 };

	protected currentPage = 1;

	protected searchEvent = new Subject<{category?: string, searchQuery?: string, queryAllCategory?: boolean, page: number}>();

	protected  searchFormControl = new FormControl('');

	protected searchDebounce = this.searchEvent.pipe(
			debounceTime(500),
			mergeMap(event => this.applicationCatalogService.search(this.tenantId, event.page, this.language, event)
				.pipe(map(searchResult => ({searchResult, event}))))
		).subscribe({
			next: searchResult => {
				this.isSearching = false;
				this.searchResults = searchResult.searchResult.applications;
				this.lastSearchEvent = searchResult.event;
				this.hasMoreApplication = searchResult.searchResult.total > this.searchResults.length;
			},
			error: error => {
				this.isSearching = false;
				this.hasMoreApplication = false;
				console.error(error);
			}
		})

	onSearch(query: {category?: string, searchQuery?: string, queryAllCategory?: boolean, page: number}) {
		this.currentPage = 1;
		this.isSearching = true;
		this.hasMoreApplication = false;
		if (query.searchQuery === undefined || query.searchQuery.trim() === '') {
			this.searchResults = [];
			if (this.lastSearchEvent) {
				this.lastSearchEvent.searchQuery = undefined;
			}
		}

		if ((query.searchQuery && query.searchQuery.length > 0) || query.category) {
			this.searchEvent.next(query);
		} else if (!query.category) {
			this.onClear();
			this.isSearching = false;
		} else {
			this.isSearching = false;
		}
	}

	nextSearchPage() {
		this.currentPage++;
		if (this.lastSearchEvent) {
			this.applicationCatalogService.search(this.tenantId, this.currentPage, this.language, { category: this.lastSearchEvent?.category, searchQuery: this.lastSearchEvent?.searchQuery}).subscribe({
				next: value => {
					this.searchResults.push(...value.applications);
					this.hasMoreApplication = value.total > this.searchResults.length;
				}
			})
		}
	}

	onClear() {
		this.selectAll = true;
		this.currentPage = 1;
		this.searchResults = [];
		this.lastSearchEvent = { queryAllCategory: true, page: 1 };
		this.selectedCategoryId = undefined;
		this.clearInput.next();
	}

	// Application basket
	protected addApplicationAnimation = new Set<string>()

	protected applicationBasket: Array<CatalogApplicationOverview | CustomApplication> = [];

	getVendor(application: CatalogApplicationOverview | CustomApplication): string | null {
		if (application.hasOwnProperty('vendor')) {
			return (application as CatalogApplicationOverview).vendor;
		} else {
			return null;
		}
	}

	onAddToBasket(application: CatalogApplicationOverview) {
		this.applicationBasket.push(application);
		this.addToSessionStorage(this.applicationBasket)
		this.addApplicationAnimation.add(application.id)
		setTimeout(() => this.addApplicationAnimation.delete(application.id), 3000)
	}

	removeFromBasket(position: number) {
		this.applicationBasket.splice(position, 1);
		this.addToSessionStorage(this.applicationBasket);
	}

	shouldAnimateAppCard(applicationId: string) {
		return this.addApplicationAnimation.has(applicationId)
	}

	// On submit
	protected isSubmitting = false;

	onSubmit() {
		if (!this.isSubmitting && this.applicationBasket.length > 0) {
			this.isSubmitting = true;

			const addingApps = this.applicationBasket.map(application => {
				if (application.hasOwnProperty('vendor')) {
					const app = application as CatalogApplicationOverview;
					const form: ApplicationCreation = {
						application: {
							catalogId: app.id,
							iconUrl: app.logoURL,
							name: app.name,
							description: app.description,
							hostingType: HostingType.SAAS,
							categoryId: app.category
						}
					}
					return this.newApplicationService.createApplicationInstance(this.tenantId, form);
				} else {
					const app = application as CustomApplication;
					const form: ApplicationCreation = {
						application: {
							name: app.name,
							description: app.description,
							hostingType: app.type as HostingType,
							categoryId: app.category,
							accessUrl: app.url,
						}
					};
					return this.newApplicationService.createApplicationInstance(this.tenantId, form);
				}
			})

			forkJoin(addingApps).subscribe({
				next: (ids) => {
					this.clearSessionStorage();
					const resp: CatalogDialogResult = {
						success: true,
						applicationIds: ids,
						names: this.applicationBasket.map(app => app.name)
					};
					this.dialogRef.close(resp);
					this.applicationBasket = [];
					this.isSubmitting = false;
				},
				error: error => {
					console.error(error);
					this.isSubmitting = false;
				}
			});
		}
	}

	getApplicationBasketFromSessionStorage() {
		const applicationBasket = sessionStorage.getItem('applicationBasket');
		if (applicationBasket) {
			this.applicationBasket = JSON.parse(applicationBasket);
		}
	}

	addToSessionStorage(applicationBasket: Array<CatalogApplicationOverview | CustomApplication>) {
		sessionStorage.setItem('applicationBasket', JSON.stringify(applicationBasket));
	}

	clearSessionStorage() {
		sessionStorage.removeItem('applicationBasket');
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}

export interface CustomApplication {
	logo: Logo;
	name: string;
	url?: string;
	description?: string;
	type?: string;
	category?: string;
}

export interface CatalogDialogResult {
	success: boolean;
	applicationIds: string[];
	names: string[];
}
