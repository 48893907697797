import {Component, OnDestroy, OnInit} from '@angular/core';
import {TenantOverview} from 'src/app/services/tenant.service';
import {finalize, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {TopbarService} from 'src/app/services/front/topbar.service';
import {TranslateService} from '@ngx-translate/core';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {AppRouteUrl, SETTINGS_BILLING_URL} from 'src/app/models/home/navigation.model';
import {ActivatedRoute} from '@angular/router';
import {Announcement, AnnouncementService} from 'src/app/services/announcement.service';

@Component({
  selector: 'app-extension',
	standalone: false,
  templateUrl: './extension.component.html',
  styleUrl: './extension.component.scss'
})
export class ExtensionComponent implements OnInit, OnDestroy {

	tenant: TenantOverview;

	_initializing: boolean;
	_loading: boolean;

	announcementList: Announcement[];
	settingsBillingUrl: AppRouteUrl = SETTINGS_BILLING_URL;
	subscription: Subscription = new Subscription();

	constructor(private topBarService: TopbarService,
							private translateService: TranslateService,
							private currentTenantService: CurrentTenantService,
							public activatedRoute: ActivatedRoute,
							private announcementService: AnnouncementService) {
	}

	ngOnInit() {
		this.topBarService.onTitleChange(this.translateService.instant('menu.extension'), this.translateService.instant('menu.subtitle.extension'));
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantChanges()
			.pipe(tap(tenant => this.tenant = tenant))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				switchMap(() => this.announcementService.getAnnouncements(this.tenant.configuration.id)),
				tap(data => this.setAnnouncements(data)),
				finalize(() => this.switchLoading()))
			.subscribe());
	}

	private setAnnouncements(data: Announcement[]): void {
		this.announcementList = data.map(announcement => {
			announcement.createdAt = new Date(announcement.createdAt)
			announcement.updatedAt = new Date(announcement.updatedAt)

			let teamsTooltip: {
				id: string
				parentName: string
				teams: {
					name: string
				}[]
			}[] = []

			announcement.teams?.forEach(team => {
				const parent = teamsTooltip.find(parent => parent.id === team.parentId)
				if (parent) {
					teamsTooltip.find(parent => parent.id === team.parentId)?.teams.push({ name: team.name })
				} else {
					teamsTooltip.push({
						id: team.parentId,
						parentName: team.parentName,
						teams: [{ name: team.name }]
					})
				}
			})
			announcement.teamsTooltip = teamsTooltip
			return announcement
		});
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
