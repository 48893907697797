import {Component, OnDestroy, OnInit} from '@angular/core';
import {finalize, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {TenantOverview, TenantService, TenantArchitectureStatistic} from 'src/app/services/tenant.service';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {Organization, OrganizationService, OrganizationTree} from 'src/app/services/organization.service';
import {TopbarService} from 'src/app/services/front/topbar.service';
import {MiniWidget} from 'src/app/modules/global/mini-widget/mini-widget.component';
import {AppRouteUrl, SETTINGS_BILLING_URL} from 'src/app/models/home/navigation.model';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
	standalone: false,
	templateUrl: './architecture-dashboard.component.html',
	styleUrls: ['./architecture-dashboard.component.scss']
})
export class ArchitectureDashboardComponent implements OnInit, OnDestroy {

	tenant: TenantOverview;
	organizationTree: OrganizationTree;
	filter: Subject<Organization|null|undefined> = new Subject<Organization|null|undefined>();
	lastFilter?: Organization|null;

	_initializing: boolean;
	_loading: boolean;
	_loadingOrganization: boolean;

	averageAge: number;
	applicationWidget: MiniWidget;
	resourceWidget: MiniWidget;
	mappingWidget: MiniWidget;
	documentWidget: MiniWidget;

	settingsBillingUrl: AppRouteUrl = SETTINGS_BILLING_URL;

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	constructor(private currentTenantService: CurrentTenantService,
							private organizationService: OrganizationService,
							public activatedRoute: ActivatedRoute,
							private topBarService: TopbarService,
							private tenantService: TenantService,
							private translate: TranslateService) {
	}

	ngOnInit(): void {
		this.setDefaultWidgetData();
		this.topBarService.onTitleChange(this.translate.instant('page.architectureDashboard.title'), this.translate.instant('page.architectureDashboard.subtitle'));
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantChanges()
			.pipe(
				tap(tenant => this.tenant = tenant),
				switchMap(() => this.fetchOrganizationTree()))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.filter
			.pipe(tap(filter => this.lastFilter = filter))
			.subscribe(filter => this.initialize(filter)));
	}

	fetchOrganizationTree(): Observable<{}> {
		this._loadingOrganization = true;
		return this.organizationService.getOrganizationTreeByTenantId(this.tenant.configuration.id)
			.pipe(
				tap(organizationTree => this.organizationTree = organizationTree),
				tap(() => this._loadingOrganization = false));
	}

	initialize(org?: Organization|null): void {
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				switchMap(() => this.tenantService.getTenantArchitectureStatistic(this.tenant.configuration.id, org?.organizationId)),
				tap(data => this.setTenantArchitectureStatistic(data)),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	private setTenantArchitectureStatistic(data: TenantArchitectureStatistic): void {
		this.averageAge = data.averageAge;
		this.applicationWidget.numericIndicator = data.totalApplication;
		this.mappingWidget.numericIndicator = data.totalMapping;
		this.resourceWidget.numericIndicator = data.totalServer;
		this.documentWidget.numericIndicator = data.totalDocument;
	}

	private setDefaultWidgetData(): void {
		this.applicationWidget = {
			numericSubject: 'page.financeDashboard.application',
			iconUrl: 'assets/icons/app-list.svg',
			iconSize: 'm'
		};
		this.resourceWidget = {
			numericSubject: 'page.financeDashboard.infrastructure',
			iconUrl: 'assets/icons/instances-widget.svg',
			iconSize: 'm'
		};
		this.mappingWidget = {
			numericSubject: 'flux',
			iconUrl: 'assets/icons/flow-icon.svg',
			iconSize: 'xm'
		};
		this.documentWidget = {
			numericSubject: 'documents',
			iconUrl: 'assets/icons/document.svg',
			iconSize: 'xm'
		};
	}

	refreshWithFilters(filter: Organization|null|undefined): void {
		this.filter.next(filter);
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
