import {Component, OnInit} from "@angular/core";
import {TenantOverview, TenantService} from "../../../../services/tenant.service";
import {mergeMap, of} from "rxjs";
import {Router} from "@angular/router";
import {CurrentTenantService} from '../../../../services/front/current-tenant.service';

@Component({
	selector: 'app-settings-plan',
	standalone: false,
	templateUrl: './plan.component.html',
	styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {
	constructor(protected currentTenantService: CurrentTenantService,
				protected tenantService: TenantService,
				protected router: Router) {}


	public tenant:TenantOverview | undefined;

	ngOnInit() {
		this.currentTenantService.getCurrentTenant()
			.subscribe(tenant => this.tenant = tenant);
	}

	public isLoadingBillingUrl = false;

	public onOpenBillingPortal() {
		this.isLoadingBillingUrl = true;
		this.currentTenantService.getCurrentTenant()
			.pipe(mergeMap(tenant => {
				this.tenant = tenant;
				if (tenant.configuration.role === 'admin') {
					return this.tenantService.getBillingPortalUrl(tenant.configuration.id);
				} else {
					return of(undefined);
				}
			}))
			.subscribe(url => {
				this.isLoadingBillingUrl = false;
				if (url) {
					window.open(url, '_blank');
				} else {
					// Nothing to do
				}
			});
	}
}
