import {Component, Input} from '@angular/core';
import {Size} from '../../../../utils/size';

/**
 * Colors :
 *  - primary
 *  - danger
 *  - warning
 *  - success
 *  - secondary
 *  - grey
 */
@Component({
	selector: 'app-chips',
	standalone: false,
	templateUrl: './chips.component.html',
	styleUrls: ['./chips.component.scss']
})
export class ChipsComponent {
	// TODO @TAN DELETE
	@Input() color: Color = 'secondary';
	@Input() size: Size = 'm';
	@Input() spaced: boolean = true;
	@Input() square: boolean = false;
	@Input() bold: boolean = true;

	getClass() {
		if (this.color) {
			return ['chips-' + this.color, 'chips-' + this.size];
		} else {
			return '';
		}
	}
}

export type Color = 'error' | 'danger' | 'primary' | 'warning' | 'success' | 'secondary' | 'grey' | 'none';
