<div class="flex-column flex-space-between h-100 w-100 gap-S prevent-select">
	<div class="flex-column menu-separator">
		<div class="flex-column gap-XS">
			<span [style.opacity]="(menuState$ | async)?.open ? 1 : 0" class="grey pad-left-M font-S">{{ 'menu.general' | translate }}</span>
			<div class="flex-column gap-XS">
				<!-- HOME -->
				<div class="flex-column">
					<div class="flex-align-center menu-container c-p" [ngClass]="{ 'opened': (menuState$ | async)?.open }">
						<div [tooltip]="'menu.home' | translate"
							 [tooltipDisabled]="!!(menuState$ | async)?.open"
							 tooltipPosition="right"
							 class="menu-button gap-XS"
							 [class.menu-opened]="(menuState$ | async)?.open"
							 (click)="goTo(HOME_URL)"
							 [class.selected]="isCurrentPage(HOME_URL)">
							<div class="flex-center" style="width: 30px; height: 30px">
								<img class="filter-grey" width="20" alt="" src="assets/icons/home.svg"/>
							</div>
							<div *ngIf="(menuState$ | async)?.open" class="flex-align-center" style="width: calc(100% - 50px)">
								<span class="font-M fw-500">{{ 'menu.home' | translate }}</span>
							</div>
						</div>
					</div>
				</div>
				<!-- ANALYSE -->
				<div class="flex-column">
					<div class="menu-container c-p" [ngClass]="{ 'opened': (menuState$ | async)?.open }">
						<div class="menu-button gap-XS"
							 [tooltip]="'menu.analyze' | translate"
							 [tooltipDisabled]="!!(menuState$ | async)?.open"
							 tooltipPosition="right"
							 [dropdownTrigger]="dropdown"
							 [class.menu-opened]="(menuState$ | async)?.open"
							 [class.sub-menu-hover]="isDropdownOpen"
							 [class.selected]="isCurrentPage(OPERATION_URL) || isCurrentPage(FINANCE_URL) || isCurrentPage(USAGE_URL) || isCurrentPage(ARCHITECTURE_URL)">
							<div class="flex-center" style="width: 30px; height: 30px; flex-shrink: 0">
								<img class="filter-grey" width="20" alt="" src="/assets/icons/analyze.svg"/>
							</div>
							<div *ngIf="(menuState$ | async)?.open" class="disp-f a-i-c j-c-sb w-100">
								<span>{{ 'menu.analyze' | translate }}</span>
								<img class="mr-m filter-grey" alt="" ngSrc="assets/icons/arrow-right-black.svg" height="12" width="8"/>
							</div>
						</div>
					</div>
				</div>
				<div class="p-absolute" style="top: 175px" [ngStyle]="{ 'left.px': (menuState$ | async)?.open ? 260 : 100 }">
					<app-dropdown (opened)="isDropdownOpen = true" (closed)="isDropdownOpen = false" [radius]="17" [animate]="false" [paddingX]="10" [paddingY]="10" [minimumMargin]="15" [arrow]="false" #dropdown>
						<div class="flex-column gap-S">
							<!-- OPERATION -->
							<div class="flex-align-center c-p menu-container opened" style="width: 250px; gap: 0;">
								<div class="menu-button sub-menu-opened gap-XS" [class.selected]="isCurrentPage(OPERATION_URL)" (click)="goTo(OPERATION_URL);closeDropdown();">
									<div class="flex-center" style="width: 30px; height: 30px">
										<img width="24" alt="" class="filter-grey" src="assets/icons/heartbeat-outline.svg"/>
									</div>
									<span>{{ 'menu.operation' | translate }}</span>
								</div>
							</div>
							<!-- FINANCE -->
							<div class="flex-align-center c-p menu-container opened" style="width: 250px; gap: 0;">
								<div class="menu-button sub-menu-opened gap-XS" [class.selected]="isCurrentPage(FINANCE_URL)" (click)="goTo(FINANCE_URL);closeDropdown();">
									<div class="flex-center" style="width: 30px; height: 30px">
										<img width="22" alt="" class="filter-grey" src="assets/icons/finance-outline.svg"/>
									</div>
									<span>{{ 'menu.finance' | translate }}</span>
								</div>
							</div>
							<!-- USAGE -->
							<div class="flex-align-center c-p menu-container opened" style="width: 250px; gap: 0;">
								<div class="menu-button sub-menu-opened gap-XS" [class.selected]="isCurrentPage(USAGE_URL)" (click)="goTo(USAGE_URL);closeDropdown();">
									<div class="flex-center" style="width: 30px; height: 30px">
										<img width="17" alt="" class="filter-grey" src="assets/icons/usage-outline.svg"/>
									</div>
									<span>{{ 'menu.usage' | translate }}</span>
								</div>
							</div>
							<!-- ARCHITECTURE -->
							<div class="flex-align-center c-p menu-container opened" style="width: 250px; gap: 0;">
								<div class="menu-button sub-menu-opened gap-XS" [class.selected]="isCurrentPage(ARCHITECTURE_URL)" (click)="goTo(ARCHITECTURE_URL);closeDropdown();">
									<div class="flex-center" style="width: 30px; height: 30px">
										<img width="18" alt="" class="filter-grey" src="assets/icons/compass.svg"/>
									</div>
									<span>{{ 'Architecture' }}</span>
								</div>
							</div>
						</div>
					</app-dropdown>
				</div>
				<!-- SCHEMAS -->
				<div class="flex-column">
					<div class="flex-align-center menu-container c-p" [ngClass]="{ 'opened': (menuState$ | async)?.open }">
						<div [tooltip]="'menu.diagrams' | translate"
							 [tooltipDisabled]="!!(menuState$ | async)?.open"
							 tooltipPosition="right"
							 class="menu-button gap-XS"
							 [class.menu-opened]="(menuState$ | async)?.open"
							 (click)="goTo(DIAGRAMS_URL)"
							 [class.selected]="isCurrentPage(DIAGRAMS_URL)">
							<div class="flex-center" style="width: 30px; height: 30px">
								<img class="filter-grey" width="20" alt="" src="assets/icons/schemas.svg"/>
							</div>
							<div *ngIf="(menuState$ | async)?.open" class="flex-align-center" style="width: calc(100% - 50px)">
								<span class="font-M fw-500">{{ 'menu.diagrams' | translate }}</span>
							</div>
						</div>
					</div>
				</div>
				<!-- AGENT -->
				<div class="flex-column">
					<div class="flex-align-center menu-container c-p" [ngClass]="{ 'opened': (menuState$ | async)?.open }">
						<div [tooltip]="'menu.extension' | translate"
							 [tooltipDisabled]="!!(menuState$ | async)?.open"
							 tooltipPosition="right"
							 class="menu-button gap-XS"
							 [class.menu-opened]="(menuState$ | async)?.open"
							 (click)="goTo(EXTENSION_URL)"
							 [class.selected]="isCurrentPage(EXTENSION_URL)">
							<div class="flex-center" style="width: 30px; height: 30px">
								<img class="filter-grey" width="20" alt="" src="assets/icons/extension.svg"/>
							</div>
							<div *ngIf="(menuState$ | async)?.open" class="flex-align-center" style="width: calc(100% - 50px)">
								<span class="font-M fw-500">{{ 'menu.extension' | translate }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<div class="flex-column gap-XS">
			<div class="flex-column gap-XS">
				<span [style.opacity]="(menuState$ | async)?.open ? 1 : 0" class="grey pad-left-M font-S">{{ 'menu.inventory' | translate }}</span>
				<!-- ORGANIZATION -->
				<div class="flex-column">
					<div class="flex-align-center menu-container c-p" [ngClass]="{ 'opened': (menuState$ | async)?.open }">
						<div [tooltip]="'menu.organization' | translate"
							 [tooltipDisabled]="!!(menuState$ | async)?.open"
							 tooltipPosition="right"
							 class="menu-button gap-XS"
							 [class.menu-opened]="(menuState$ | async)?.open"
							 (click)="goTo(ORGANIZATION_URL)"
							 [class.selected]="isCurrentPage(ORGANIZATION_URL)">
							<div class="flex-center" style="width: 30px; height: 30px">
								<img class="filter-grey" width="20" alt="" src="assets/icons/menu-orga-outline.svg"/>
							</div>
							<div *ngIf="(menuState$ | async)?.open" class="flex-align-center" style="width: calc(100% - 50px)">
								<span class="font-M fw-500">{{ 'menu.organization' | translate }}</span>
							</div>
						</div>
					</div>
				</div>
				<!-- APPLICATIONS -->
				<div class="flex-column">
					<div class="flex-align-center menu-container c-p" [ngClass]="{ 'opened': (menuState$ | async)?.open }">
						<div [tooltip]="'menu.applications' | translate"
							 [tooltipDisabled]="!!(menuState$ | async)?.open"
							 tooltipPosition="right"
							 class="menu-button gap-XS"
							 [class.menu-opened]="(menuState$ | async)?.open"
							 (click)="goTo(APPLICATIONS_URL)"
							 [class.selected]="isCurrentPage(APPLICATIONS_URL)">
							<div class="flex-center" style="width: 30px; height: 30px">
								<img class="filter-grey" width="20" alt="" src="assets/icons/grid.svg"/>
							</div>
							<div *ngIf="(menuState$ | async)?.open" class="disp-f a-i-c j-c-sb" style="width: calc(100% - 50px)">
								<span class="font-M fw-500">{{ 'menu.applications' | translate }}</span>
								<span *ngIf="appsCount" class="font-S fw-700" [class.app-count-bubble-selected]="isCurrentPage(APPLICATIONS_URL)" [class.app-count-bubble]="!isCurrentPage(APPLICATIONS_URL)" [class.grey]="!isCurrentPage(APPLICATIONS_URL)" [class.accent]="isCurrentPage(APPLICATIONS_URL)">{{ appsCount }}</span>
							</div>
						</div>
					</div>
				</div>
				<!-- INFRASTRUCTURE -->
				<div class="flex-column">
					<div class="flex-align-center menu-container c-p" [ngClass]="{ 'opened': (menuState$ | async)?.open }">
						<div [tooltip]="'menu.infrastructure' | translate"
							 [tooltipDisabled]="!!(menuState$ | async)?.open"
							 tooltipPosition="right"
							 class="menu-button gap-XS"
							 [class.menu-opened]="(menuState$ | async)?.open"
							 (click)="goTo(INFRASTRUCTURE_URL)"
							 [class.selected]="isCurrentPage(INFRASTRUCTURE_URL)">
							<div class="flex-center" style="width: 30px; height: 30px">
								<img class="filter-grey" width="18" alt="" src="assets/icons/menu-infra-outline.svg"/>
							</div>
							<div *ngIf="(menuState$ | async)?.open" class="flex-align-center" style="width: calc(100% - 50px)">
								<span class="font-M fw-500">{{ 'menu.infrastructure' | translate }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="flex-column gap-XS pad-bottom-L">
		<div class="flex-column gap-XS">
			<span [style.opacity]="(menuState$ | async)?.open ? 1 : 0" class="grey pad-left-M font-S">{{ 'menu.other' | translate }}</span>
			<div class="flex-column gap-XS">
				<!-- INTERCOM -->
				<div class="flex-column">
					<div class="flex-align-center menu-container c-p" [ngClass]="{ 'opened': (menuState$ | async)?.open }">
						<div [tooltip]="'menu.support' | translate"
							 [tooltipDisabled]="!!(menuState$ | async)?.open"
							 tooltipPosition="right"
							 class="menu-button gap-XS"
							 [class.menu-opened]="(menuState$ | async)?.open"
							 (click)="goTo(INTERCOM_URL)"
							 [class.selected]="isCurrentPage(INTERCOM_URL)">
							<div class="flex-center" style="width: 30px; height: 30px">
								<img width="20" alt="" src="assets/icons/support-outline.svg"/>
							</div>
							<div *ngIf="(menuState$ | async)?.open" class="flex-align-center" style="width: calc(100% - 50px)">
								<span class="font-M fw-500">{{ 'menu.support' | translate }}</span>
							</div>
						</div>
					</div>
				</div>
				<!-- SETTINGS -->
				<div class="flex-column">
					<div class="flex-align-center menu-container c-p" [ngClass]="{ 'opened': (menuState$ | async)?.open }">
						<div [tooltip]="'menu.settings' | translate"
							 [tooltipDisabled]="!!(menuState$ | async)?.open"
							 tooltipPosition="right"
							 class="menu-button gap-XS"
							 [class.menu-opened]="(menuState$ | async)?.open"
							 (click)="goTo(SETTINGS_URL)"
							 [class.selected]="isCurrentPage(SETTINGS_URL)">
							<div class="flex-center" style="width: 30px; height: 30px">
								<img class="filter-grey" width="20" alt="" src="assets/icons/menu-gear-outline.svg"/>
							</div>
							<div *ngIf="(menuState$ | async)?.open" class="flex-align-center" style="width: calc(100% - 50px)">
								<span class="font-M fw-500">{{ 'menu.settings' | translate }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
