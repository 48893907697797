import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApplicationDocumentFormData} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-document-form/application-document-form.component';

@Component({
  templateUrl: './application-document-form-dialog.component.html',
  standalone: false,
  styleUrls: ['./application-document-form-dialog.component.scss']
})
export class ApplicationDocumentFormDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ApplicationDocumentFormData,
              public dialogRef: MatDialogRef<ApplicationDocumentFormDialogComponent>) {
    dialogRef.disableClose = true;
  }

  close(updated?: boolean): void {
    this.dialogRef.close(updated);
  }
}
