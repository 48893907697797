import {Component, Directive, ElementRef, HostListener, Input, signal, ViewChild, WritableSignal} from '@angular/core';

@Component({
  selector: 'app-mini-drawer',
  standalone: false,
  templateUrl: './mini-drawer.component.html',
  styleUrls: ['./mini-drawer.component.scss']
})
export class MiniDrawerComponent {

  @ViewChild('miniDrawerContainer') miniDrawerContainer: ElementRef;

  opened: WritableSignal<boolean> = signal(false);
}

@Directive({
  selector: '[miniDrawerTriggerFor]',
  standalone: false
})
export class MiniDrawerDirective {

  @Input() miniDrawerTriggerFor: MiniDrawerComponent;
  @Input() miniDrawerDisabled: boolean = false;

  @HostListener('click')
  onClick(): void {
    this.miniDrawerTriggerFor.opened.update((opened: boolean) => this.miniDrawerDisabled ? false : !opened);
  }
}
