import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SecurityUtils} from "../security.utils";
import {catchError} from "rxjs/operators";
import {HttpClientUtils} from "../../utils/http-client.utils";
import {SchemaServer} from "./server.service";
import {Network} from "./network.service";
import {Router} from "./router.service";

@Injectable()
export class InfrastructureSchemaService {

	private backendUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/infrastructure-schema`;

	constructor(protected httpClient: HttpClient) {
	}

	getServerSchema(tenantId: string, serverId: string): Observable<InfrastructureSchema|null> {
		return this.httpClient.get<InfrastructureSchema|null>(`${this.backendUrl(tenantId)}/servers/${serverId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getNetworkSchema(tenantId: string, networkId: string): Observable<InfrastructureSchema|null> {
		return this.httpClient.get<InfrastructureSchema|null>(`${this.backendUrl(tenantId)}/networks/${networkId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}
}

export interface InfrastructureSchema {
	servers: SchemaServer[],
	network: Network
	routers: Router[]
}
