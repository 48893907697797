import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
	ApplicationComplexity,
	ApplicationObsolescence,
	CriticalityLevel,
	TenantService
} from 'src/app/services/tenant.service';
import {
	ApplicationDetailComponent,
	ApplicationDetailInput
} from 'src/app/modules/home/applications/application-detail/application-detail.component';
import {RightSliderService} from 'src/app/services/front/right-slider.service';
import {finalize, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {Organization} from 'src/app/services/organization.service';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {MostObsoleteApplicationDrawerComponent, MostObsoleteApplicationDrawerData} from 'src/app/modules/home/architecture-dashboard/most-obsolete-application/drawer/most-obsolete-application-drawer.component';
import {ExportService, ExportType} from 'src/app/services/front/export.service';
import {TranslateService} from '@ngx-translate/core';
import {ColumnDefinition} from "../../../design-system/table/table.component";

@Component({
	selector: 'app-most-obsolete-application',
	standalone: false,
	templateUrl: './most-obsolete-application.component.html',
	styleUrls: ['./most-obsolete-application.component.scss']
})
export class MostObsoleteApplicationComponent implements OnInit, OnDestroy {

	@Input() defaultFilter?: Organization|null|undefined;
	@Input() filter?: Subject<Organization|null|undefined>;
	@Input() search?: Observable<string|null|undefined>;
	@Input() export?: Subject<ExportType>;
	@Input() limit?: number;
	@Input() type: 'widget'|'drawer' = 'widget';

	tenantId: string;
	lastFilter?: Organization|null;

	_initializing: boolean;
	_loading: boolean;

	applications: ApplicationObsolescence[];
	filteredApplications: ApplicationObsolescence[];

	columnDefinitions: ColumnDefinition<ApplicationObsolescence>[];

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	@ViewChild('applicationTemplate', { static: true }) applicationTemplate: TemplateRef<any>;
	@ViewChild('criticalityTemplate', { static: true }) criticalityTemplate: TemplateRef<any>;
	@ViewChild('obsolescenceTemplate', { static: true }) obsolescenceTemplate: TemplateRef<any>;
	@ViewChild('usageTemplate', { static: true }) usageTemplate: TemplateRef<any>;

	constructor(private currentTenantService: CurrentTenantService,
							private rightSliderService: RightSliderService,
							private tenantService: TenantService,
							private exportService: ExportService,
							private translate: TranslateService) {
	}

	ngOnInit() {
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize(this.defaultFilter)));
		if (this.filter) {
			this.subscription.add(this.filter
				.pipe(tap(filter => this.lastFilter = filter))
				.subscribe(filter => this.initialize(filter)));
		}
		if (this.search) {
			this.subscription.add(this.search
				.subscribe(search => this.filterApplications(search!)));
		}
		if (this.export) {
			this.subscription.add(this.export
				.subscribe(type => this.exportData(type)));
		}
	}

	initialize(org?: Organization|null): void {
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				switchMap(() => this.tenantService.getAllApplicationObsolescenceByTenantId(this.tenantId, org?.organizationId, this.limit)),
				tap(data => this.applications = data),
				tap(data => this.filteredApplications = data),
				tap(() => this.setColumnDefinitions()),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	setColumnDefinitions(): void {
		this.columnDefinitions = [
			{
				name: 'application',
				label: 'global.table.application.name',
				template: this.applicationTemplate,
				sortFunction: (a, b) => a.application.name.localeCompare(b.application.name),
				width: 45,
			},
			{
				name: 'application.criticality',
				label: 'global.table.application.criticality',
				template: this.criticalityTemplate,
				sortFunction: (a, b) => {
					const order: (CriticalityLevel|null|undefined)[] = [CriticalityLevel.HIGH, CriticalityLevel.MEDIUM, CriticalityLevel.LOW, null, undefined];
					return order.indexOf(a.application.criticality) - order.indexOf(b.application.criticality);
				},
			},
			{
				name: 'obsolescence',
				label: 'global.table.application.obsolescence',
				template: this.obsolescenceTemplate,
				sortFunction: (a, b) => a.obsolescence.percent - b.obsolescence.percent,
			},
			{
				name: 'usage',
				label: 'global.table.application.usage',
				template: this.usageTemplate,
				sortFunction: (a, b) => {
					// undefined and null lower than 0
					const aUsage = a.usage.value === null ? -1 : a.usage.value;
					const bUsage = b.usage.value === null ? -1 : b.usage.value;
					return aUsage - bUsage;
				},
			},
		];
	}

	filterApplications(search?: string|null): void {
		this.filteredApplications = this.applications
			.filter(app => !search || app.application.name.toLowerCase().includes(search.toLowerCase()));
	}

	goToApp(applicationId: string): void {
		const data: ApplicationDetailInput = {
			applicationId: applicationId
		};
		this.rightSliderService.openComponent(ApplicationDetailComponent, data)
			.subscribe(() => this.filter?.next(this.lastFilter));
	}

	openDrawer(): void {
		if (this.type === 'widget' && this.applications.length > 0) {
			const data: MostObsoleteApplicationDrawerData = {
				filter: this.lastFilter
			};
			this.rightSliderService.openComponent(MostObsoleteApplicationDrawerComponent, data);
		}
	}

	exportData(type: ExportType): void {
		const rows: ApplicationObsolescenceCsv[] = this.filteredApplications.map(a => ({
			name: a.application.name,
			criticality: !a.application.criticality
				? '-'
				: this.translate.instant('page.appDetails.businessCriticality.' + a.application.criticality.toLowerCase()),
			obsolescence: a.obsolescence.level,
			users: a.usage.value?.toString() ?? '-',
		}));
		this.exportService.export(type, rows, 'application-obsolescence');
	}

	onSorted(event: ApplicationObsolescence[]): void {
		this.filteredApplications = event;
	}

	onRowClick(event: ApplicationObsolescence): void {
		this.goToApp(event.application.id);
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.initializeSub?.unsubscribe();
	}
}

interface ApplicationObsolescenceCsv {
	name: string;
	criticality: string;
	obsolescence: string;
	users: string;
}
