import {Component, Input} from '@angular/core';

@Component({
	selector: 'app-mini-widget',
	standalone: false,
	templateUrl: './mini-widget.component.html',
	styleUrls: ['./mini-widget.component.scss']
})
export class MiniWidgetComponent {
	// TODO @TAN REWORK / type
	@Input() miniWidget: MiniWidget;
	@Input() responsive: boolean = false;

	getUser(value: number) {
		return value.toFixed(0)
	}
}

export interface MiniWidget {
	numericIndicator?: number|null;
	numericSubject: string;
	numericTendency?: number;
	numericOutOf?: number;
	iconUrl: string;
	iconSize: 'l' | 'm' | 'xm';
	type?: string;
}
