import {Component, Input} from "@angular/core";
import {Team} from "src/app/services/usage.service";
import {Router} from "@angular/router";
import {ORGANIZATION_URL} from "src/app/models/home/navigation.model";
import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";
import {RightSliderService} from "../../../../../../services/front/right-slider.service";
import {OrganizationDetailComponent} from "../../../../organization/organization-detail/organization-detail.component";
import {OrganizationType} from "../../../../../../services/model/autodiscover.model";

@Component({
	selector: 'app-team-card',
	standalone: false,
	templateUrl: './team-card.component.html',
	styleUrls: ['./team-card.component.scss'],
	animations: [
		trigger('openClose', [
			state('open', style({
				height: '*',
				padding: '15px',
				opacity: 1
			})),
			state('closed', style({
				height: '0px',
				padding: '0px',
				opacity: 0
			})),
			transition('open => closed', [
				animate('300ms ease-in-out', keyframes([
					style({ height: '*', padding: '15px', opacity: 1, offset: 0 }),
					style({ height: '*', padding: '15px', opacity: 0.5, offset: 0.3 }),
					style({ height: '0px', padding: '15px', opacity: 0.2, offset: 0.8 }),
					style({ height: '0px', padding: '0px', opacity: 0, offset: 1.0 })
				]))
			]),
			transition('closed => open', [
				animate('300ms ease-in-out', keyframes([
					style({ height: '0px', padding: '0px', opacity: 0, offset: 0 }),
					style({ height: '0px', padding: '15px', opacity: 0.2, offset: 0.2 }),
					style({ height: '*', padding: '15px', opacity: 0.5, offset: 0.7 }),
					style({ height: '*', padding: '15px', opacity: 1, offset: 1.0 })
				]))
			])
		]),
		trigger('arrowOpenClose', [
			state('open', style({
				transform: 'rotate(90deg)',
			})),
			state('closed', style({
				transform: 'rotate(0deg)',
			})),
			transition('open <=> closed', [
				animate('300ms ease-in-out')
			])
		])
	]
})
export class TeamCardComponent {
	constructor(private rightSliderService: RightSliderService) {
	}

	@Input() team: Team;
	@Input() canDevelop: boolean;

	onTeamClick(team: Team): void {
		// set team as opened
		team.opened = !team.opened;
	}

	goToTeam(team: Team): void {
		const data = {
			organizationId: team.businessUnit,
			type: OrganizationType.BUSINESS_UNIT
		};
		this.rightSliderService.openComponent(OrganizationDetailComponent, data);
	}

	isInactiveFor30Days(date: Date): boolean {
		return new Date().getTime() - new Date(date).getTime() > 30 * 24 * 60 * 60 * 1000;
	}

	isInactiveFor10Days(date: Date): boolean {
		return new Date().getTime() - new Date(date).getTime() > 10 * 24 * 60 * 60 * 1000 && new Date().getTime() - new Date(date).getTime() < 30 * 24 * 60 * 60 * 1000;
	}

	getDiffDays(date: Date): number {
		return Math.floor((new Date().getTime() - new Date(date).getTime()) / (24 * 60 * 60 * 1000));
	}

	protected readonly Date = Date;
}
