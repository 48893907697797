import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {UsersComponent} from "./users.component";
import {TranslateModule} from "@ngx-translate/core";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {TableContentLoaderModule} from "../../../global/table-content-loader/table-content-loader.module";
import {ChipsModule} from "../../../global/chips/chips/chips.module";
import {CardModule} from "../shared/card/card.module";
import {MatButtonModule} from "@angular/material/button";
import {MiniButtonModule} from "../../../global/button/mini-button/mini-button.module";
import {DeckContentLoaderModule} from "../../../global/title-content-loader/deck-content-loader.module";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';

@NgModule({
	declarations: [
		UsersComponent
	],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    TableContentLoaderModule,
    ChipsModule,
    CardModule,
    MiniButtonModule,
    DeckContentLoaderModule,
    DesignSystemModule,
  ],
	exports: [
		UsersComponent
	]
})
export class UsersModule { }
