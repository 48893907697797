import {Component, OnInit} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {startWith, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {
	DIAGRAMS_APP_COMPARISON_URL,
	DIAGRAMS_APP_LIFE_CYCLE_URL,
	DIAGRAMS_APP_MAPPING_URL,
	DIAGRAMS_APP_MATRIX_URL, DIAGRAMS_QUADRANT_URL,
	DIAGRAMS_URL
} from 'src/app/models/home/navigation.model';

@Component({
  selector: 'app-diagram-breadcrumb',
  standalone: false,
  templateUrl: './diagram-breadcrumb.component.html',
  styleUrls: ['./diagram-breadcrumb.component.scss']
})
export class DiagramBreadcrumbComponent implements OnInit {

  diagramLabel: string|undefined;
  subscription: Subscription = new Subscription();

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.subscription.add(this.router.events
      .pipe(
        filter((evt) => evt instanceof NavigationEnd),
        map((evt) => (evt as NavigationEnd).urlAfterRedirects),
        startWith(this.router.url))
      .subscribe(url => this.setDiagramLabel(url)));
  }

  setDiagramLabel(url: string): void {
    if (url.includes(DIAGRAMS_APP_MAPPING_URL)) {
      this.diagramLabel = 'page.diagram.breadcrumb.appMapping';
    } else if (url.includes(DIAGRAMS_APP_MATRIX_URL)) {
      this.diagramLabel = 'page.diagram.breadcrumb.appMatrix';
    } else if (url.includes(DIAGRAMS_APP_LIFE_CYCLE_URL)) {
      this.diagramLabel = 'page.diagram.breadcrumb.lifeCycle';
    } else if (url.includes(DIAGRAMS_APP_COMPARISON_URL)) {
	  this.diagramLabel = 'page.diagram.breadcrumb.appComparison';
	} else if (url.includes(DIAGRAMS_QUADRANT_URL)) {
			  this.diagramLabel = 'page.diagram.breadcrumb.quadrant';
	} else {
      this.diagramLabel = undefined;
    }
  }

  back(): void {
    this.router.navigate([DIAGRAMS_URL]);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
