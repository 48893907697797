import { ChangeDetectionStrategy, Component } from '@angular/core';
import {ContentLoaderComponent} from '@ngneat/content-loader';


@Component({
	selector: 'app-title-content-loader',
	standalone: false,
	template: `
    <content-loader viewBox="0 0 476 124">
      <svg:rect x="48" y="8" width="88" height="6" rx="3" />
      <svg:rect x="48" y="26" width="52" height="6" rx="3" />
      <svg:circle cx="20" cy="20" r="20" />
    </content-loader>
  `,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitleContentLoaderComponent extends ContentLoaderComponent {}
