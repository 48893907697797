<div class="disp-f f-d-col pad-L" style="height: calc(100% - 40px);">
	<div #commentsContainer *ngIf="!loading" class="bg-rounded comments-container pt-l pb-l pr-l pl-l" style="position: relative">
		<div *ngIf="groupedComments && groupedComments.length > 0">
			<div *ngFor="let group of groupedComments">
				<div #stickyElt class="separator" style="position:sticky; z-index: 12;">
					<div class="pt-xs pb-xs pl-m pr-m separator-header">
						{{ 'global.day.' + group.dayOfWeek | translate }} {{ group.day.slice(8, 10) }} {{ (group.day.slice(5, 7).includes("10") ? 'global.month.10' : group.day.slice(5, 7).includes("0") ? 'global.month.' + group.day.slice(5, 7).replace("0", "") : 'global.month.' + group.day.slice(5, 7)) | translate }} {{ group.day.slice(0, 4) }}
					</div>
				</div>
				<div *ngFor="let comment of group.comments" class="mb-xl" style="position: relative">
					<div class="disp-f a-i-c" style="top: 20px; z-index: 10;">
                        <app-display-user [user]="comment.user" bold></app-display-user>
                        <div class="ml-s font-S fw-500 grey">{{comment.date.getHours() < 10 ? '0' + comment.date.getHours() : comment.date.getHours()}}:{{comment.date.getMinutes() < 10 ? '0' + comment.date.getMinutes() : comment.date.getMinutes()}}</div>
					</div>
					<div class="comment-body-container" style="z-index: 9;">
						<div *ngIf="comment.own && comment.deleted" class="comment-body mt-s pt-l pb-l pl-l pr-l primary disp-f j-c-sb owned-comment">
							<span>{{ 'page.application.comments.deletedComment' | translate }}</span>
							<span (click)="cancelDeleteComment(comment)" class="cancel-deletion">{{ 'button.cancel' | translate }}</span>
						</div>
						<div *ngIf="!comment.deleted">
							<div class="delete-comment" *ngIf="comment.own" (click)="deleteComment(comment)">
								<img class="filter-danger" ngSrc="assets/icons/trash.svg" height="20" width="15" alt="">
							</div>
							<div class="comment-body mt-s pt-l pb-l pl-l pr-l primary fw-500" [ngClass]="{ 'owned-comment': comment.own }">
								<span *ngFor="let part of comment.contentParsed">
									<span style="white-space: pre-line" *ngIf="part.type === 'text'">{{ part.content }}</span>
									<span style="white-space: pre-line" *ngIf="part.type === 'mention'" class="mention">{{ part.content }}</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="groupedComments && groupedComments.length <= 0" style="height: 100%">
			<div class="disp-f j-c-c a-i-c w-100" style="height: 100%;">
				<div class="disp-f f-d-col gap-XL a-i-c j-c-c">
					<img ngSrc="assets/illustrations/no-comment.svg" height="198" width="268" alt="" priority>
					<div class="font-S fw-500 grey" style="position: relative; left: 33px;">{{ 'page.application.comments.noComment' | translate }}</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="loading" class="bg-rounded comments-container border-box-full">
		<app-dashboard-page-loader></app-dashboard-page-loader>
	</div>
	<div class="bg-rounded mt-l pt-m pb-m pr-l pl-l">
		<div class="disp-f j-c-sb f-d-col">
			<div>
				<div class="disp-f gap-M">
                    <app-display-user-logo [user]="currentUser"></app-display-user-logo>
					<div class="disp-f j-c-c a-i-c w-100">
						<div *ngIf="mentionConfig" contenteditable="true"
							 [mentionConfig]="mentionConfig"
							 [mentionListTemplate]="mentionListTemplate"
							 (itemSelected)="itemSelected($event)"
							 (input)="onInput($event)"
							 (focusout)="onFocusOut($event)"
							 #textAreaElement
							 appTextareaAutoresize
							 class="w-100 fw-500 content-editable"
							 [attr.data-ph]="'page.application.comments.addComment' | translate"
						>
						</div>
						<ng-template #mentionListTemplate let-item="item">
							<div class="disp-f a-i-c gap-S">
                                <app-display-user [user]="asUserMentionConfig(item).tenantAccount"></app-display-user>
							</div>
						</ng-template>
					</div>
				</div>
				<div class="separator-input input-sep mt-m mb-m"></div>
			</div>
			<div class="disp-f a-i-c j-c-fe">
				<button class="send-btn" [ngClass]="{'disabled': actionMade || message.invalid}"
                        [disabled]="!currentUser || actionMade || message.invalid"
                        (click)="sendMessage()">
					<img *ngIf="!actionMade" ngSrc="assets/icons/arrow-right-comment.svg" class="filter-white"
						 height="24" width="24" alt="">
					<div *ngIf="actionMade" class="lds-ring-comment">
						<div></div><div></div><div></div><div></div>
					</div>
				</button>
			</div>
		</div>
	</div>
</div>
