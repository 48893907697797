import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import {FormControl, FormControlOptions, Validators} from '@angular/forms';
import {distinctUntilChanged, Subscription} from 'rxjs';
import {InsertOpenedService} from "../../../../services/front/insert-opened.service";

@Component({
	selector: 'app-select-insert',
	standalone: false,
	templateUrl: './select-insert.component.html',
	styleUrl: '../multi-select.component.scss'
})
export class SelectInsertComponent implements OnInit, OnDestroy {

	constructor(private cdr: ChangeDetectorRef,
				private insertOpenedService: InsertOpenedService) {
	}

	@Input() titlePath: string = 'dropdown.defaultCreateFieldHeader';
	@Input() saving: boolean = false;
	@Input() withToggle: boolean = false;
	@Input() toggleText: string = 'dropdown.defaultCreateFieldToggle';
	@Input() placeholder: string = 'global.writeHere';
	@Input() validators: Validators[] = [Validators.required, Validators.minLength(1)];

	@Output() create: EventEmitter<string> = new EventEmitter<string>();
	@Output() createWithToggle: EventEmitter<{ value: string, toggle: boolean }> = new EventEmitter<{
		value: string,
		toggle: boolean
	}>();

	@ViewChild('search') searchElement: ElementRef;

	createForm: FormControl<string | null>;
	toggleFormControl: FormControl<boolean | null>;
	openForm: boolean = false;
	subscription: Subscription = new Subscription();

	ngOnInit(): void {
		this.createForm = new FormControl<string>('', this.validators as FormControlOptions);
		if (this.withToggle) {
			this.toggleFormControl = new FormControl<boolean>(true);
		}
		this.subscription.add(this.createForm.valueChanges
			.pipe(distinctUntilChanged())
			.subscribe(value => value?.trim()));
	}

	onCreate(): void {
		if (this.formValid) {
			if (this.withToggle) {
				this.createWithToggle.emit({value: this.createForm.value!, toggle: this.toggleFormControl.value!});
			} else {
				this.create.emit(this.createForm.value!);
			}
			this.createForm.reset('');
			this.openForm = false;
		}
	}

	setFormOpened(): void {
		this.openForm = !this.openForm;
		this.insertOpenedService.sendInsertOpenState(this.openForm);
		if (this.openForm) {
			this.cdr.detectChanges();
			this.searchElement.nativeElement.focus();
		}
	}

	get formValid(): boolean {
		return this.createForm.valid && this.createForm.dirty;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
