import {Component, Input, OnInit} from '@angular/core';
import {ApplicationLifeCycle} from 'src/app/services/model/application-life-cycle.model';
import {finalize, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {Organization} from 'src/app/services/organization.service';
import {TranslateService} from '@ngx-translate/core';
import {TenantService} from 'src/app/services/tenant.service';

@Component({
	selector: 'app-system-age-distribution',
	standalone: false,
	templateUrl: './system-age-distribution.component.html',
	styleUrls: ['./system-age-distribution.component.scss']
})
export class SystemAgeDistributionComponent implements OnInit {

	@Input() filter: Subject<Organization|null|undefined>;

	tenantId: string;

	_initializing: boolean;
	_loading: boolean;

	distributions = Object.values(AgeDistribution);
	ageQuantity: Record<AgeDistribution, number>;
	agePercent: Record<AgeDistribution, number|undefined>;
	ageLabel: Record<AgeDistribution, string>;

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	constructor(private currentTenantService: CurrentTenantService,
				private translateService: TranslateService,
				private tenantService: TenantService) {
	}

	ngOnInit() {
		this.setLabel();
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.filter
			.subscribe(filter => this.initialize(filter)));
	}

	initialize(org?: Organization|null): void {
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				switchMap(() => this.tenantService.getAllApplicationLifeCycleByTenantId(this.tenantId, org?.organizationId)),
				tap(data => this.setLifeCycles(data)),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	private setLabel(): void {
		this.ageLabel = {
			[AgeDistribution.MORE_THAN_10]: this.translateService.instant('page.architectureDashboard.ageDistribution.more_than_10'),
			[AgeDistribution.FROM_5_TO_10]: this.translateService.instant('page.architectureDashboard.ageDistribution.from_5_to_10'),
			[AgeDistribution.FROM_2_TO_5]: this.translateService.instant('page.architectureDashboard.ageDistribution.from_2_to_5'),
			[AgeDistribution.FROM_1_TO_2]: this.translateService.instant('page.architectureDashboard.ageDistribution.from_1_to_2'),
			[AgeDistribution.LESS_THAN_1]: this.translateService.instant('page.architectureDashboard.ageDistribution.less_than_1')
		};
	}

	setLifeCycles(lifeCycles: ApplicationLifeCycle[]): void {
		this.ageQuantity = {
			[AgeDistribution.MORE_THAN_10]: this.countApplicationBetween(lifeCycles, 10),
			[AgeDistribution.FROM_5_TO_10]: this.countApplicationBetween(lifeCycles, 5, 10),
			[AgeDistribution.FROM_2_TO_5]: this.countApplicationBetween(lifeCycles, 2, 5),
			[AgeDistribution.FROM_1_TO_2]: this.countApplicationBetween(lifeCycles, 1, 2),
			[AgeDistribution.LESS_THAN_1]: this.countApplicationBetween(lifeCycles, 0, 1)
		};
		const max = Object.values(this.ageQuantity).reduce((a, b) => Math.max(a, b));
		this.agePercent = {
			[AgeDistribution.MORE_THAN_10]: this.countLevelPercentage(AgeDistribution.MORE_THAN_10, max),
			[AgeDistribution.FROM_5_TO_10]: this.countLevelPercentage(AgeDistribution.FROM_5_TO_10, max),
			[AgeDistribution.FROM_2_TO_5]: this.countLevelPercentage(AgeDistribution.FROM_2_TO_5, max),
			[AgeDistribution.FROM_1_TO_2]: this.countLevelPercentage(AgeDistribution.FROM_1_TO_2, max),
			[AgeDistribution.LESS_THAN_1]: this.countLevelPercentage(AgeDistribution.LESS_THAN_1, max)
		};
	}

	private countApplicationBetween(lifeCycles: ApplicationLifeCycle[], from: number, to?: number): number {
		const yearTime = new Date('2000-01-01').getTime() - new Date('1999-01-01').getTime();
		const todayTime = new Date().getTime();
		return lifeCycles
			.filter(a => !!a.deployedDate)
			.map(a => new Date(a.deployedDate!))
			.filter(date => (todayTime - date.getTime() > yearTime * from) && (!to || todayTime - date.getTime() < yearTime * to))
			.length;
	}

	private countLevelPercentage(level: AgeDistribution, max: number): number|undefined {
		return !max ? undefined : (this.ageQuantity[level] / max) * 100;
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.initializeSub?.unsubscribe();
	}
}

enum AgeDistribution {
	MORE_THAN_10 = 'more_than_10',
	FROM_5_TO_10 = 'from_5_to_10',
	FROM_2_TO_5 = 'from_2_to_5',
	FROM_1_TO_2 = 'from_1_to_2',
	LESS_THAN_1 = 'less_than_1'
}
