import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {ApplicationAutodiscoverService} from "src/app/services/back/application-autodiscover.service";
import {ApplicationAutodiscover, DiscoveredAppStatus, ApplicationAutodiscoverStatusForm} from "src/app/services/model/autodiscover.model";
import {ButtonRemote} from "../../../global/button/mini-button/mini-button.component";
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {skip, Subject, switchMap} from 'rxjs';
import {Organization} from 'src/app/services/organization.service';
import {TranslateService} from "@ngx-translate/core";
import {ApplicationSortColumn} from "../../../../services/model/new-application.model";

@Component({
	templateUrl: './autodiscover.component.html',
	standalone: false,
	styleUrls: ['./autodiscover.component.scss']
})
export class AutodiscoverComponent {

	@Input() data: AutoDiscoverComponentData;

	selectedTab: TabSelected = TabSelected.ACTIVE
	tabSelectedEnum = TabSelected
	displayedApplications: ApplicationAutodiscover[] = []
	discoverAppStatus: typeof DiscoveredAppStatus = DiscoveredAppStatus;

	sorting: {
		key: "name" | "teams" | "users" | "date";
		order: "asc" | "desc"
	} = {
		key: "users",
		order: "desc"
	}

	private intersectionSubject = new Subject<boolean>(); // Subject to emit visibility updates

	isStickyElementPinned: boolean = false;

	observer!: IntersectionObserver;

	constructor(protected autodiscoverService: ApplicationAutodiscoverService,
				private currentTenantService: CurrentTenantService,
				private translateService: TranslateService,
				private elRef: ElementRef) {
	}

	ngOnInit() {
		this.updateTableView();
	}

	ngAfterViewInit() {
		const stickyElement = this.elRef.nativeElement.querySelector('.sticky-element');
		this.observer = new IntersectionObserver(
			([entry]) => {
				this.intersectionSubject.next(entry.intersectionRatio < 1);
			},
			{
				threshold: [1]
			}
		);
		this.observer.observe(stickyElement);

		this.intersectionSubject
			.pipe(skip(1))
			.subscribe((isPinned) => {
				this.isStickyElementPinned = isPinned;
			});
	}

	protected updateTableView() {
		this.displayedApplications =  this.data.discoveredApplications
			.filter(app => app.status.toUpperCase() === TabSelected[this.selectedTab].toUpperCase())
			.sort((a, b) => {
				if (this.sorting.key === 'date') {
					const aDate = new Date(a.discoveredAt)
					const bDate = new Date(b.discoveredAt)
					return this.sorting.order === 'asc' ? aDate.getTime() - bDate.getTime() : bDate.getTime() - aDate.getTime()
				} else if (this.sorting.key === 'name') {
					const nameSort = this.sorting.order === 'asc' ? a.application.name.localeCompare(b.application.name) : b.application.name.localeCompare(a.application.name)
					if (nameSort === 0) {
						const aDate = new Date(a.discoveredAt)
						const bDate = new Date(b.discoveredAt)
						return this.sorting.order === 'asc' ? aDate.getTime() - bDate.getTime() : bDate.getTime() - aDate.getTime()
					}
					return nameSort
				} else if (this.sorting.key === 'teams') {
					// sort by number of teams then by date
					const aTeams = a.businessUnits.length
					const bTeams = b.businessUnits.length
					if (aTeams === bTeams) {
						const aDate = new Date(a.discoveredAt)
						const bDate = new Date(b.discoveredAt)
						return this.sorting.order === 'asc' ? aDate.getTime() - bDate.getTime() : bDate.getTime() - aDate.getTime()
					}
					return this.sorting.order === 'asc' ? aTeams - bTeams : bTeams - aTeams
				} else {
					// sort by number of users then by date
					const aUsers = a.totalUniqueUser || 0
					const bUsers = b.totalUniqueUser || 0
					if (aUsers === bUsers) {
						const aDate = new Date(a.discoveredAt)
						const bDate = new Date(b.discoveredAt)
						return this.sorting.order === 'asc' ? aDate.getTime() - bDate.getTime() : bDate.getTime() - aDate.getTime()
					}
					return this.sorting.order === 'asc' ? aUsers - bUsers : bUsers - aUsers
				}
			})
	}

	buildTeamTooltipView(businessUnits: Organization[]): Map<string, { name: string, businessServices: string[] }> {
		const structureMap = new Map<string, { name: string, businessServices: string[] }>;
		businessUnits.forEach(bu => {
				const parent = bu.parentOrganization
				if (parent && structureMap.has(parent.organizationId)) {
					structureMap.get(parent.organizationId)!.businessServices.push(bu.name)
				} else if (parent) {
					structureMap.set(parent.organizationId, { name: parent.name, businessServices: [bu.name] })
				} else {
					structureMap.set(bu.organizationId, { name: bu.name, businessServices: [] })
				}
		})
		return structureMap
	}

	onTabSelectionChange(tab:TabSelected) {
		this.selectedTab = tab
		this.updateTableView()
	}

	onUpdateStatus(event: ButtonRemote, autodiscoverId: string, status: DiscoveredAppStatus) {
		event.displayLoader()
		const form: ApplicationAutodiscoverStatusForm = {
			status: status,
		}
		this.currentTenantService.getCurrentTenantId()
			.pipe(switchMap(tenantId => this.autodiscoverService.updateApplicationAutodiscoverStatus(tenantId, autodiscoverId, form)))
			.subscribe({
				next: () => {
					event.becomeActive()
					this.data.discoveredApplications.map(r => {
						if (r.autodiscoverId === autodiscoverId)
							r.status = status
						return r
					})
					this.updateTableView()
				},
				error: () => event.becomeActive()
			});
	}

	@Output() onClose = new EventEmitter<any>();

	close(data?: any): void {
		this.onClose.emit(data);
	}

	truncate(str: string, n: number){
		return (str.length > n) ? str.slice(0, n-1) + '...' : str;
	}

	formattedDateTime(date: string): string {
		const dateTime = new Date(date);
		const month = dateTime.getMonth() + 1;
		return dateTime.getDate() + ' ' + this.translateService.instant('global.monthShort.' + month) + ' ' + dateTime.getFullYear();
	}

	sortBy(sort: "name" | "teams" | "users" | "date", direction: "asc" | "desc"): void {
		this.sorting = {
			key: sort,
			order: direction
		}
		this.displayedApplications = this.displayedApplications.sort((a, b) => {
			if (sort === 'date') {
				const aDate = new Date(a.discoveredAt)
				const bDate = new Date(b.discoveredAt)
				return this.sorting.order === 'asc' ? aDate.getTime() - bDate.getTime() : bDate.getTime() - aDate.getTime()
			} else if (sort === 'name') {
				return direction === 'asc' ? a.application.name.localeCompare(b.application.name) : b.application.name.localeCompare(a.application.name)
			} else if (sort === 'teams') {
				return direction === 'asc' ? a.businessUnits.length - b.businessUnits.length : b.businessUnits.length - a.businessUnits.length
			} else {
				const aUsers = a.totalUniqueUser || 0
				const bUsers = b.totalUniqueUser || 0
				return direction === 'asc' ? aUsers - bUsers : bUsers - aUsers
			}
		})
	}

	ngOnDestroy() {
		this.observer.disconnect();
	}

	protected readonly column = ApplicationSortColumn;
}

export enum TabSelected {
	ACTIVE,
	ACCEPTED,
	DISMISSED,
}

export interface AutoDiscoverComponentData {
	discoveredApplications: ApplicationAutodiscover[];
}
