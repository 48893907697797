import {Component, OnDestroy, OnInit} from '@angular/core';
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {ApplicationInstanceService, ApplicationPerformanceHistory, PerformanceHistory} from 'src/app/services/application-instance.service';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {ApplicationUrlService} from 'src/app/services/front/application-url.service';
import {ApplicationInstance} from 'src/app/services/model/new-application.model';
import {DailyHealth, HealthHistory, HealthService} from "src/app/services/back/health.service";
import {Announcement, AnnouncementService} from "src/app/services/announcement.service";
import {filter} from 'rxjs/operators';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';

@Component({
	selector: 'app-operation-tab',
	standalone: false,
	templateUrl: './operation-tab.component.html',
	styleUrls: ['./operation-tab.component.scss']
})
export class OperationTabComponent implements OnInit, OnDestroy {

	tenantId: string;
	isEditor: boolean = false;
	application: ApplicationInstance;

	_initializing: boolean;
	_loading: boolean;
	initialized: boolean = false;

	graph: DailyHealth[] = [];
	performanceGraph: PerformanceHistory[] = [];
	health: number|undefined|null;
	announcements: Announcement[] = [];

	subscriptions: Subscription = new Subscription();

	constructor(private applicationDetailService: ApplicationDetailService,
							private applicationService: ApplicationInstanceService,
							private announcementService: AnnouncementService,
							private healthService: HealthService,
							private applicationUrlService: ApplicationUrlService) {
	}

	ngOnInit(): void {
		this.subscriptions.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscriptions.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));
	}

	private setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.application = data.instance;
	}

	initialize(): void {
		this.subscriptions.add(this.switchLoading()
			.pipe(
				switchMap(() => forkJoin([
					this.fetchPerformanceHistory(),
					this.fetchApplicationHealth(),
					this.fetchApplicationAnnouncements()
				])),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	fetchPerformanceHistory(): Observable<{}> {
		return this.applicationService.getApplicationPerformanceHistory(this.tenantId, this.application.applicationId)
			.pipe(tap(data => this.setPerformanceHistory(data)));
	}

	fetchApplicationHealth(): Observable<{}|null> {
		return this.healthService.getHealthHistoryByApplicationId(this.tenantId, this.application.applicationId, QueryRangeType.PAST_1_MONTH)
			.pipe(tap(data => this.setHealth(data)));
	}

	fetchApplicationAnnouncements(): Observable<{}> {
		return this.announcementService.getApplicationAnnouncements(this.tenantId, this.application.applicationId)
			.pipe(tap(data => this.setAnnouncements(data)));
	}

	private setPerformanceHistory(response: ApplicationPerformanceHistory): void {
		const historyList = response.webVitals;
		const firstEverPerformance = response.firstEverPerformance
		historyList.forEach((item) => {
			if (item.lcp) {
				item.lcp.value = Math.round(item.lcp.value / 1000 * 100) / 100;
			}
			if (item.fcp) {
				item.fcp.value = Math.round(item.fcp.value / 1000 * 100) / 100;
			}
		});
		historyList.forEach((item, index) => {
			if (item.globalRating === null && item.timestamp > firstEverPerformance) {
				if (index < historyList.length - 1) {
					for (let i = index + 1; i < historyList.length; i++) {
						if (historyList[i].globalRating !== null) {
							item.globalRating = historyList[i].globalRating;
							item.lcp = historyList[i].lcp;
							item.fcp = historyList[i].fcp;
							break;
						}
					}
				}
				if (item.globalRating === null) {
					if (index > 0) {
						for (let i = index - 1; i >= 0; i--) {
							if (historyList[i].globalRating !== null) {
								item.globalRating = historyList[i].globalRating;
								item.lcp = historyList[i].lcp;
								item.fcp = historyList[i].fcp;
								break;
							}
						}
					}
				}
			}
		})
		this.performanceGraph = historyList;
	}

	private setHealth(data: HealthHistory|null): void {
		this.graph = data?.healthHistory ?? [];
		this.health = data?.health.percent;
	}

	private setAnnouncements(data: Announcement[]): void {
		this.announcements = data.map((announcement) => {
			announcement.createdAt = new Date(announcement.createdAt);
			announcement.updatedAt = new Date(announcement.updatedAt);
			return announcement;
		});
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	navigateToUsageParameter(): void {
		if (!this.application.usageActivated) {
			this.applicationUrlService.navigateInAndOutOfParameter(true);
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
