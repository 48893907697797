import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AccountModule} from "./account/account.module";
import {PlanModule} from "./plan/plan.module";
import {TenantModule} from "./tenant/tenant.module";
import {UsersModule} from "./users/users.module";
import {SettingsComponent} from "./settings.component";
import {SubmenuModule} from "../../global/submenu/submenu.module";
import {RouterModule} from "@angular/router";
import {MatButtonModule} from "@angular/material/button";
import {MatSelectModule} from "@angular/material/select";
import {settingsRoutes} from "./settings-routing.module";
import {CardModule} from "./shared/card/card.module";
import {SnackbarModule} from "../../global/snackbar/snackbar.module";
import {ConfirmModule} from "../../global/dialog/confirm/confirm.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatRippleModule} from "@angular/material/core";

@NgModule({
		declarations: [
			SettingsComponent
		],
	imports: [
		CommonModule,
		MatButtonModule,
		MatSelectModule,
		ConfirmModule,
		AccountModule,
		PlanModule,
		TenantModule,
		UsersModule,
		SubmenuModule,
		RouterModule.forChild(settingsRoutes),
		CardModule,
		SnackbarModule,
		TranslateModule,
		MatRippleModule
	],
		exports: []
})
export class SettingsModule { }
