import {Component, Input} from "@angular/core";

@Component({
	selector: 'app-icon-button',
	standalone: false,
	templateUrl: './icon-button.component.html',
	styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {

	@Input() img: `assets/${string}.svg`|`assets/${string}.png`;

	@Input() disabled: boolean = false;
	@Input() loading: boolean = false;

	@Input() size: 'xs'|'s'|'m'|'l'|'xl' = 'm';
	@Input() color: 'primary'|'accent'|'accent-secondary'|'warn'|'success'|'warning'|'danger'|'transparent' = 'accent';
	@Input() type: 'default'|'stroked'|'flat'|'dashed' = 'default';
	@Input() shape: 'default'|'square'|'round' = 'default';

	private _focused: boolean
	@Input()
	get focused() { return this._focused; }
	set focused(value: any) { this._focused = value != null && `${value}` !== 'false'; }

	private _background: boolean;
	@Input()
	get background() { return this._background; }
	set background(value: any) { this._background = value != null && `${value}` !== 'false'; }

	@Input() panelClass: string = '';

	get diameter(): number {
		switch (this.size) {
			case 'xs': return 15;
			case 's': return 17;
			case 'm': return 20;
			case 'l': return 23;
			case 'xl': return 26;
			default: return 22;
		}
	}
}
