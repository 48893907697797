import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ApplicationTechnologyService} from 'src/app/services/back/application-technology.service';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {finalize, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {CatalogTechnologyService} from 'src/app/services/back/catalog-technology.service';
import {map} from 'rxjs/operators';
import {ApplicationTechnologyFormList, Technology} from 'src/app/services/model/application-technology.model';
import {CatalogTechnologySelect, CatalogTechnologyVersion} from 'src/app/services/model/catalog-technology.model';

@Component({
	selector: 'app-application-technology-form',
	standalone: false,
	templateUrl: './application-technology-form.component.html',
	styleUrls: ['./application-technology-form.component.scss']
})
export class ApplicationTechnologyFormComponent implements OnInit, OnDestroy {

	@Input() data: ApplicationTechnologyFormData;

	@Output() cancel: EventEmitter<void> = new EventEmitter<void>();
	@Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();

	_initializing: boolean;
	_saving: boolean;

	formGroup: FormGroup;
	formName = Form;
	formElementName = FormElement;

	subscription: Subscription = new Subscription();

	constructor(private applicationTechnologyService: ApplicationTechnologyService,
				private technologyCatalogService: CatalogTechnologyService) {
	}

	ngOnInit(): void {
		this.createForm();
		this.subscription.add(this.switchInitializing()
			.pipe(
				switchMap(() => !this.data.preloaded?.technologies
					? this.technologyCatalogService.getAllCatalogTechnologyByTenantId(this.data.tenantId)
					: of(this.data.preloaded.technologies)),
				tap(technologies => this.setTechnologies(technologies)),
				finalize(() => this.switchInitializing()))
			.subscribe(() => this.setDefaultData()));
	}

	private createForm(): void {
		this.formGroup = new FormGroup({
			[Form.search]: new FormControl('', []),
			[Form.technologies]: new FormArray([])
		});
		this.subscription.add(this.formGroup.get(Form.search)?.valueChanges
			.subscribe(() => this.filterTechnologyFormGroups()));
	}

	private setTechnologies(technologies: CatalogTechnologySelect[]): void {
		technologies
			.map(tech => new FormGroup({
				[FormElement.technology]: new FormControl(tech, []),
				[FormElement.selected]: new FormControl(undefined, []),
				[FormElement.version]: new FormControl(undefined, [])
			}))
			.forEach(group => this.technologyFormArray.push(group));
	}

	private setDefaultData(): void {
		this.technologyFormGroups.forEach(group => {
			const selected: Technology|undefined = this.data.defaultData?.find(t => t.technology.technologyId === this.getTechnology(group).technology.technologyId);
			const version: CatalogTechnologyVersion|undefined = this.getTechnology(group).versions.find(v => v.versionId === selected?.version?.versionId);
			group.get(FormElement.selected)?.setValue(!!selected);
			group.get(FormElement.version)?.setValue(version);
		});
		this.filterTechnologyFormGroups();
	}

	save(): void {
		this.switchSaving()
			.pipe(
				map(() => this.buildApplicationTechnologyForm()),
				switchMap(form => this.applicationTechnologyService.updateApplicationTechnologyList(this.data.tenantId, this.data.applicationId, form)),
				finalize(() => this.switchSaving()))
			.subscribe(updated => this.saved.emit(updated));
	}

	private buildApplicationTechnologyForm(): ApplicationTechnologyFormList {
		return {
			technologies: this.technologyFormGroups
				.filter(group => !!group.get(FormElement.selected)!.value)
				.map(group => ({
					technologyId: (group.get(FormElement.technology)!.value as CatalogTechnologySelect).technology.technologyId,
					versionId: (group.get(FormElement.version)!.value as CatalogTechnologyVersion)?.versionId
				}))
		};
	}

	getTechnology(group: FormGroup): CatalogTechnologySelect {
		return group.get(FormElement.technology)!.value as CatalogTechnologySelect;
	}

	getVersionFormControl(index: number): FormControl {
		return this.technologyFormGroups[index].get(FormElement.version) as FormControl;
	}

	get searchFormControl(): FormControl {
		return this.formGroup.get(Form.search)! as FormControl;
	}

	get technologyFormArray(): FormArray {
		return this.formGroup.get(Form.technologies)! as FormArray;
	}

	get technologyFormGroups(): FormGroup[] {
		return this.technologyFormArray.controls as FormGroup[];
	}

	private filterTechnologyFormGroups(): FormGroup[] {
		return this.technologyFormGroups.sort(this.sortTechnologies);
	}

	private sortTechnologies = (form1: FormGroup, form2: FormGroup) => {
		const matchG1: boolean = this.isFormMatchSearch(form1);
		const matchG2: boolean = this.isFormMatchSearch(form2);
		if (matchG1 && !matchG2) {
			return -1;
		} else if (!matchG1 && matchG2) {
			return 1;
		} else {
			return this.getTechnology(form1).technology.name.localeCompare(this.getTechnology(form2).technology.name);
		}
	}

	filteredOut(form: FormGroup): boolean {
		return !this.isFormMatchSearch(form);
	}

	private isFormMatchSearch(form: FormGroup): boolean {
		return this.getTechnology(form).technology.name.toLowerCase().includes(this.formGroup.get(Form.search)!.value?.toLowerCase());
	}

	switchSaving(): Observable<{}> {
		this._saving = !this._saving;
		this.checkFormDisabled();
		return of({});
	}

	switchInitializing(): Observable<{}> {
		this._initializing = !this._initializing;
		this.checkFormDisabled();
		return of({});
	}

	private checkFormDisabled(): void {
		if (this._initializing || this._saving) {
			this.formGroup.disable({emitEvent: false});
		} else {
			this.formGroup.enable({emitEvent: false});
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}

export interface ApplicationTechnologyFormData {
	tenantId: string;
	applicationId: string;
	defaultData?: Technology[];
	preloaded?: {
		technologies?: CatalogTechnologySelect[];
	}
}

enum Form {
	search = 'search',
	technologies = 'technologies'
}

enum FormElement {
	technology = 'technology',
	selected = 'selected',
	version = 'version'
}
