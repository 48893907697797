import {Component, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-finance-tab',
  standalone: false,
  templateUrl: './finance-tab.component.html',
  styleUrls: ['./finance-tab.component.scss']
})
export class FinanceTabComponent {
  refreshFinancialData: EventEmitter<void> = new EventEmitter<void>();
}
