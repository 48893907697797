import {Component, Input} from '@angular/core';
import {Color} from '../chips/chips/chips.component';
import {InfrastructureUtils} from '../../home/infrastructure/infrastructure.utils';
import {ResourceMapping} from 'src/app/services/model/resource-mapping.model';
import {Logo} from "../../../services/application-instance.service";
import {DataCollectionStatus} from "../../../services/model/resource.model";
import {map} from "rxjs/operators";

@Component({
	selector: 'app-resource-mapping-card',
	standalone: false,
	templateUrl: './resource-mapping-card.component.html',
	styleUrls: ['./resource-mapping-card.component.scss']
})
export class ResourceMappingCardComponent {
	// TODO @TAN design-system/DisplayResourceComponent and handle disabled and delete logic in parent component
	@Input() mapping: ResourceMapping;
	@Input() disabled: boolean = false;
	@Input() delete?: (mappingId: string) => void;

	get statusColor(): Color {
		// TODO @TAN enum
		switch (this.mapping.server.dataCollectionStatus) {
			case DataCollectionStatus.ACTIVE: return 'success';
			case DataCollectionStatus.OUTDATED: return 'warning';
			case DataCollectionStatus.ERROR: return 'danger';
			default: return 'danger';
		}
	}

	getResourceIcon(): Logo {
		return InfrastructureUtils.buildSystemLogo(this.mapping.server.system);
	}

	protected readonly map = map;
	protected readonly DataCollectionStatus = DataCollectionStatus;
	protected readonly InfrastructureUtils = InfrastructureUtils;
}
