<div class="flex-column gap-L">
	<div class="flex-space-between">
		<div class="flex-align-center gap-S">
			<div class="font-MM fw-700">{{'page.application.authentication.common.title'|translate}}</div>
			<div *ngIf="advancementPercent < 99.99" [style.background-color]="advancementPercent > 0 ? '#F2F2F8' : '#DADADA'" style=" height: 5px; border-radius: 2px; width: 30px;">
				<div class="bkg-accent completion-anim" style="height: 5px; border-radius: 2px;" [style.width.%]="advancementPercent == 99.99 ? 100 : advancementPercent"></div>
			</div>
			<img [src]="advancementPercent >= 99.99 ? 'assets/icons/check-circle-green.svg' : 'assets/icons/check-circle.svg'" height="14px" alt="">
		</div>
		<span (click)="updateApplicationAuthentication()" *ngIf="_editing" class="c-p accent underlined-hover">{{'page.application.authentication.common.confirm'|translate}}</span>
		<span (click)="switchEditing()" *ngIf="!_editing" class="c-p accent underlined-hover">{{'page.application.authentication.common.edit'|translate}}</span>
	</div>
	<div class="flex-column">
		<div class="flex-align-center border-light-grey-top" style="height: 50px;">
			<div class="flex-align-center pad-S w-50 border-box">
				<div class="flex-align-center gap-XS w-50">
					<img src="assets/icons/cube-outline.svg" height="14px" alt="">
					<span class="font-M fw-500 grey">{{'page.application.authentication.common.type'|translate}} :</span>
				</div>
				<div class="flex-align-center w-50">
					<app-multi-select *ngIf="_editing" style="width: 230px" [inputControl]="authenticationTypeFormControl"
									  [disabled]="_initializing || _loading || _saving">
						@if (!authenticationTypeFormControl.value) {
							<app-chips-button select-trigger>
								<img src="assets/icons/add.svg" class="filter-grey" height="12px" alt="">
								<span class="font-M fw-500 grey">{{'page.application.authentication.common.addAType'|translate}}</span>
							</app-chips-button>
						} @else if (!!authenticationTypeFormControl.value) {
							<app-select-trigger class="w-100">
								<div class="flex-align-center" style="max-width: 125px;">
									<span class="text-overflow-ellipsis">{{('page.application.authentication.type.' + authenticationTypeFormControl.value!.id.toLowerCase())|translate}}</span>
								</div>
							</app-select-trigger>
						} @else {
							<app-select-trigger class="w-100">-</app-select-trigger>
						}
						<app-select-option [value]="null">-</app-select-option>
						<app-select-option *ngFor="let type of authenticationTypeChoices" [value]="type">
							<div class="flex-align-center" style="width: 210px;">
								<span>{{('page.application.authentication.type.' + type.id.toLowerCase())|translate}}</span>
							</div>
						</app-select-option>
					</app-multi-select>
					<span class="ml-s" *ngIf="!_editing && application.authenticationType">{{'page.application.authentication.type.' + application.authenticationType.toLowerCase()|translate}}</span>
					<span class="ml-s" *ngIf="!_editing && !application.authenticationType">-</span>
				</div>
			</div>
			<div class="border-light-grey-left" style="height: 25px"></div>
			<div class="flex-align-center pad-S w-50 border-box">
				<div class="flex-align-center gap-XS w-50">
					<img src="assets/icons/check-square.svg" height="14px" alt="">
					<span class="font-M fw-500 grey">{{'page.application.authentication.common.primaryFactor'|translate}} :</span>
				</div>
				<div class="flex-align-center w-50">
					<app-multi-select *ngIf="_editing" style="width: 230px" [inputControl]="authenticationPrimaryFactorFormControl"
									  [disabled]="_initializing || _loading || _saving">
						@if (!authenticationPrimaryFactorFormControl.value) {
							<app-chips-button select-trigger>
								<img src="assets/icons/add.svg" class="filter-grey" height="12px" alt="">
								<span class="font-M fw-500 grey">{{'page.application.authentication.common.addAFactor'|translate}}</span>
							</app-chips-button>
						} @else if (!!authenticationPrimaryFactorFormControl.value) {
							<app-select-trigger class="w-100">
								<div class="flex-align-center" style="max-width: 125px;">
									<span class="text-overflow-ellipsis">{{('page.application.authentication.primary_factor.' + authenticationPrimaryFactorFormControl.value!.id.toLowerCase())|translate}}</span>
								</div>
							</app-select-trigger>
						} @else {
							<app-select-trigger class="w-100">-</app-select-trigger>
						}
						<app-select-option [value]="null">-</app-select-option>
						<app-select-option *ngFor="let factor of authenticationPrimaryFactorChoices" [value]="factor">
							<div class="flex-align-center" style="width: 210px;">
								<span>{{('page.application.authentication.primary_factor.' + factor.id.toLowerCase())|translate}}</span>
							</div>
						</app-select-option>
					</app-multi-select>
					<span class="ml-s" *ngIf="!_editing && application.authenticationPrimaryFactor">{{'page.application.authentication.primary_factor.' + application.authenticationPrimaryFactor.toLowerCase()|translate}}</span>
					<span class="ml-s" *ngIf="!_editing && !application.authenticationPrimaryFactor">-</span>
				</div>
			</div>
		</div>
		<div class="flex-align-center border-light-grey-top" style="height: 50px;">
			<div class="flex-align-center pad-S w-50 border-box">
				<div class="flex-align-center gap-XS w-50">
					<img src="assets/icons/file-lock.svg" height="14px" alt="">
					<span class="font-M fw-500 grey">{{'page.application.authentication.common.protocol'|translate}} :</span>
				</div>
				<div class="flex-align-center w-50">
					<app-multi-select *ngIf="_editing" style="width: 230px" [inputControl]="authenticationProtocolFormControl"
									  [disabled]="_initializing || _loading || _saving">
						@if (!authenticationProtocolFormControl.value) {
							<app-chips-button select-trigger>
								<img src="assets/icons/add.svg" class="filter-grey" height="12px" alt="">
								<span class="font-M fw-500 grey">{{'page.application.authentication.common.addAProtocol'|translate}}</span>
							</app-chips-button>
						} @else if (!!authenticationProtocolFormControl.value) {
							<app-select-trigger class="w-100">
								<div class="flex-align-center" style="max-width: 125px;">
									<span class="text-overflow-ellipsis">{{('page.application.authentication.protocol.' + authenticationProtocolFormControl.value!.id.toLowerCase())|translate}}</span>
								</div>
							</app-select-trigger>
						} @else {
							<app-select-trigger class="w-100">-</app-select-trigger>
						}
						<app-select-option [value]="null">-</app-select-option>
						<app-select-option *ngFor="let protocol of authenticationProtocolChoices" [value]="protocol">
							<div class="flex-align-center" style="width: 210px;">
								<span>{{('page.application.authentication.protocol.' + protocol.id.toLowerCase())|translate}}</span>
							</div>
						</app-select-option>
					</app-multi-select>
					<span class="ml-s" *ngIf="!_editing && application.authenticationProtocol">{{'page.application.authentication.protocol.' + application.authenticationProtocol.toLowerCase()|translate}}</span>
					<span class="ml-s" *ngIf="!_editing && !application.authenticationProtocol">-</span>
				</div>
			</div>
			<div class="border-light-grey-left" style="height: 25px"></div>
			<div class="flex-align-center pad-S w-50 border-box">
				<div class="flex-align-center gap-XS w-50">
					<img src="assets/icons/check-done.svg" height="14px" alt="">
					<span class="font-M fw-500 grey">{{'page.application.authentication.common.secondaryFactor'|translate}} :</span>
				</div>
				<div class="flex-align-center w-50">
					<app-multi-select *ngIf="_editing" style="width: 230px" [inputControl]="authenticationSecondaryFactorFormControl"
									  [disabled]="_initializing || _loading || _saving">
						@if (!authenticationSecondaryFactorFormControl.value) {
							<app-chips-button select-trigger>
								<img src="assets/icons/add.svg" class="filter-grey" height="12px" alt="">
								<span class="font-M fw-500 grey">{{'page.application.authentication.common.addAFactor'|translate}}</span>
							</app-chips-button>
						} @else if (!!authenticationSecondaryFactorFormControl.value) {
							<app-select-trigger class="w-100">
								<div class="flex-align-center" style="max-width: 125px;">
									<span class="text-overflow-ellipsis">{{('page.application.authentication.secondary_factor.' + authenticationSecondaryFactorFormControl.value!.id.toLowerCase())|translate}}</span>
								</div>
							</app-select-trigger>
						} @else {
							<app-select-trigger class="w-100">-</app-select-trigger>
						}
						<app-select-option [value]="null">{{'page.application.authentication.secondary_factor.none' | translate}}</app-select-option>
						<app-select-option *ngFor="let factor of authenticationSecondaryFactorChoices" [value]="factor">
							<div class="flex-align-center" style="width: 210px;">
								<span>{{('page.application.authentication.secondary_factor.' + factor.id.toLowerCase())|translate}}</span>
							</div>
						</app-select-option>
					</app-multi-select>
					<span class="ml-s" *ngIf="!_editing && application.authenticationSecondaryFactor">{{'page.application.authentication.secondary_factor.' + application.authenticationSecondaryFactor.toLowerCase()|translate}}</span>
					<span class="ml-s" *ngIf="!_editing && !application.authenticationSecondaryFactor">-</span>
				</div>
			</div>
		</div>
	</div>
</div>

