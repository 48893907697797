import {Component, OnInit} from '@angular/core';
import {TopbarService} from 'src/app/services/front/topbar.service';
import {FormControl, FormGroup} from '@angular/forms';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {ApplicationGeneric} from 'src/app/services/model/new-application.model';
import {merge, tap} from 'rxjs';
import {TenantAccountService} from 'src/app/services/back/tenant-account.service';
import {TenantAccount} from 'src/app/services/model/account.model';
import {Organization, OrganizationService, OrganizationTree} from 'src/app/services/organization.service';
import {OrganizationType} from 'src/app/services/model/autodiscover.model';

@Component({
  templateUrl: './demo.component.html',
  standalone: false,
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {
  // TODO create one component for each demo

  tenantId: string;

  applications: ApplicationGeneric[] = [];
  organizationTree: OrganizationTree;
  users: TenantAccount[] = [];

  iconButtonForm: FormGroup;
  textButtonForm: FormGroup;
  displayApplicationForm: FormGroup;
  displayUserForm: FormGroup;
  chipsButtonForm: FormGroup;
  dropdownForm: FormGroup;
  multiSelectForm: FormGroup;
  progressBarIndicatorForm: FormGroup;
  progressBarLoaderForm: FormGroup;
  selectOrInviteUserForm: FormGroup;
  chipsCountForm: FormGroup;

  constructor(private tobbarService: TopbarService,
              private tenantAccountService: TenantAccountService,
              private currentTenantService: CurrentTenantService,
              private organizationService: OrganizationService,
              private applicationService: NewApplicationService) {
    this.tobbarService.onTitleChange('Design System', 'This page is intended for development purposes only.', '\'_\'');
  }

  ngOnInit() {
    this.createForms();
    this.currentTenantService.getCurrentTenantId()
      .pipe(tap(tenantId => this.tenantId = tenantId))
      .subscribe(() => {
        this.fetchApplications();
        this.fetchOrganizationTree();
        this.fetchUsers();
      });
  }

  createForms(): void {
    this.iconButtonForm = new FormGroup({
      'img': new FormControl('trash'),
      'size': new FormControl('m'),
      'color': new FormControl('accent'),
      'type': new FormControl('default'),
      'shape': new FormControl('default'),
      'disabled': new FormControl(false),
      'loading': new FormControl(false),
      'focused': new FormControl(false),
      'background': new FormControl(false),
      'panelClass': new FormControl(''),
    });
    this.textButtonForm = new FormGroup({
      'content': new FormControl('Click me !'),
      'contentIcon': new FormControl(false),
      'color': new FormControl('accent'),
      'type': new FormControl('default'),
      'shape': new FormControl('default'),
      'disabled': new FormControl(false),
      'loading': new FormControl(false),
      'badge': new FormControl(''),
      'badgePosition': new FormControl('above after'),
      'panelClass': new FormControl(''),
    });
    this.displayApplicationForm = new FormGroup({
      'application': new FormControl(undefined),
      'description': new FormControl(''),
      'criticality': new FormControl(false),
      'size': new FormControl('M'),
      'bold': new FormControl(false),
      'refreshView': new FormControl(false),
    });
    this.displayApplicationForm.get('size')!.valueChanges.subscribe(() => {
      this.displayApplicationForm.get('refreshView')!.setValue(true);
      setTimeout(() => this.displayApplicationForm.get('refreshView')!.setValue(false));
    });
    this.displayUserForm = new FormGroup({
      'user': new FormControl(undefined),
      'email': new FormControl(false),
      'size': new FormControl('M'),
      'bold': new FormControl(false),
    });
    this.chipsButtonForm = new FormGroup({
      'content': new FormControl('Click me !'),
      'disabled': new FormControl(false),
    });
    this.dropdownForm = new FormGroup({
      'content': new FormControl('This is an example'),
      'minimumMargin': new FormControl(35),
      'opened': new FormControl(0),
      'closed': new FormControl(0),
    });
    this.multiSelectForm = new FormGroup({
      'inputControl': new FormControl(),
      'multiple': new FormControl(false),
      'disabled': new FormControl(false),
      'loading': new FormControl(false),
      'trigger': new FormControl('component'),
      'appearance': new FormControl('chips'),
      'search': new FormControl(undefined),
      'searchControl': new FormControl(),
      'insert': new FormControl(undefined),
      'insertControl': new FormControl(),
      'saving': new FormControl(false),
      'isGrouped': new FormControl(false),
      'options': new FormControl([]),
      'filteredOptions': new FormControl([]),
      'optionGroups': new FormControl([]),
      'filteredOptionGroups': new FormControl([]),
      'noOptionPrefix': new FormControl(false),
      'refreshView': new FormControl(false),
    });
    this.multiSelectForm.get('multiple')!.valueChanges.subscribe(multiple => {
      if (multiple) this.multiSelectForm.get('inputControl')!.setValue([]);
      else this.multiSelectForm.get('inputControl')!.setValue(undefined);
    });
    this.multiSelectForm.get('searchControl')!.valueChanges.subscribe(search => {
      const options: Organization[] = this.multiSelectForm.get('options')!.value.filter((option: Organization) => !search || option.name.toLowerCase().includes(search.toLowerCase()));
      this.multiSelectForm.get('filteredOptions')!.setValue(options);
      const optionGroups: OrganizationTree[] = JSON.parse(JSON.stringify(this.multiSelectForm.get('optionGroups')!.value.filter((group: OrganizationTree) => !search || group.organization.name.toLowerCase().includes(search.toLowerCase()) || group.children.some((option: OrganizationTree) => option.organization.name.toLowerCase().includes(search.toLowerCase())))));
      optionGroups.forEach((group: OrganizationTree) => group.children = group.children.filter((option: OrganizationTree) => !search || option.organization.name.toLowerCase().includes(search.toLowerCase())));
      this.multiSelectForm.get('filteredOptionGroups')!.setValue(optionGroups);
    });
    merge(
      this.multiSelectForm.get('multiple')!.valueChanges,
      this.multiSelectForm.get('isGrouped')!.valueChanges,
      this.multiSelectForm.get('noOptionPrefix')!.valueChanges,
      this.multiSelectForm.get('trigger')!.valueChanges,
      this.multiSelectForm.get('appearance')!.valueChanges,
      this.multiSelectForm.get('search')!.valueChanges,
      this.multiSelectForm.get('insert')!.valueChanges,
    ).subscribe(() => {
      this.multiSelectForm.get('refreshView')!.setValue(true);
      setTimeout(() => this.multiSelectForm.get('refreshView')!.setValue(false));
    });
    this.progressBarIndicatorForm = new FormGroup({
      'levelPercent': new FormControl(50),
      'label': new FormControl('Niveau'),
      'color': new FormControl('auto'),
      'unit': new FormControl('percent'),
      'size': new FormControl('M'),
      'lowerBest': new FormControl(false),
    });
    this.progressBarLoaderForm = new FormGroup({
      'loadingTime': new FormControl(2000),
      'onStart': new FormControl(undefined),
      'onFinish': new FormControl(undefined),
      'refreshView': new FormControl(false),
    });
    this.progressBarLoaderForm.get('loadingTime')!.valueChanges.subscribe(() => {
      this.progressBarLoaderForm.get('onStart')!.setValue(undefined);
      this.progressBarLoaderForm.get('onFinish')!.setValue(undefined);
      this.progressBarLoaderForm.get('refreshView')!.setValue(true);
      setTimeout(() => this.progressBarLoaderForm.get('refreshView')!.setValue(false));
    });
    this.selectOrInviteUserForm = new FormGroup({
      'value': new FormControl(undefined),
      'list': new FormControl([]),
      'searchPlaceholder': new FormControl('Search'),
      'disabled': new FormControl(false),
      'onSelect': new FormControl(undefined),
      'onInvite': new FormControl(undefined),
    });
    this.chipsCountForm = new FormGroup({
      'value': new FormControl(undefined),
      'type': new FormControl('user'),
      'color': new FormControl('grey'),
      'bold': new FormControl(false),
      'refreshView': new FormControl(false),
    });
    this.chipsCountForm.get('type')!.valueChanges.subscribe(() => {
      this.chipsCountForm.get('refreshView')!.setValue(true);
      setTimeout(() => this.chipsCountForm.get('refreshView')!.setValue(false));
    });
  }

  fetchApplications(): void {
    this.applicationService.getAllApplication(this.tenantId).subscribe(applications => {
      this.applications = applications;
      this.displayApplicationForm.get('application')!.setValue(this.applications[0]);
    });
  }

  fetchOrganizationTree(): void {
    this.organizationService.getOrganizationTreeByTenantId(this.tenantId).subscribe(organization => {
      this.organizationTree = organization;
      this.multiSelectForm.get('options')!.setValue(organization.children.map(c => c.children).flat().map(c => c.organization));
      this.multiSelectForm.get('optionGroups')!.setValue(organization.children);
      this.multiSelectForm.get('searchControl')!.setValue('');
    });
  }

  fetchUsers(): void {
    this.tenantAccountService.getAllTenantAccountByTenantId(this.tenantId).subscribe(users => {
      this.users = users;
      this.displayUserForm.get('user')!.setValue(this.users[0]);
      this.selectOrInviteUserForm.get('value')!.setValue(this.users[0]);
      this.selectOrInviteUserForm.get('list')!.setValue(this.users);
    });
  }

  createTest(name: string) {
    this.multiSelectForm.get('saving')!.setValue(true);
    setTimeout(() => {
      const newData: Organization = {
        organizationId: Math.random().toString(36).substring(7),
        parentOrganization: null,
        name: name,
        type: OrganizationType.BUSINESS_UNIT,
        description: null,
        icon: null
      };
      if (!this.multiSelectForm.get('isGrouped')!.value) {
        this.multiSelectForm.get('options')!.value.push(newData);
      } else {
        this.multiSelectForm.get('optionGroups')!.value[0].children.push(newData);
      }
      if (!this.multiSelectForm.get('multiple')!.value) {
        this.multiSelectForm.get('inputControl')!.setValue(newData);
      } else {
        this.multiSelectForm.get('inputControl')!.value.push(newData);
      }
      this.multiSelectForm.get('searchControl')!.reset('');
      this.multiSelectForm.get('saving')!.setValue(false);
    }, 1000);
  }
}
