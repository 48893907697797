import {Component, Input} from '@angular/core';
import {Server} from "../../../services/model/resource.model";

@Component({
  selector: 'app-server-icon',
  standalone: false,
  templateUrl: './server-icon.component.html',
  styleUrl: './server-icon.component.scss'
})
export class ServerIconComponent {
	@Input() server: Server;
	@Input() size: number = 30;
}
