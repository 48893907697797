import {Component} from '@angular/core';
import {DiagramPath} from 'src/app/models/home/navigation.model';

@Component({
  selector: 'app-diagram-overview',
  standalone: false,
  templateUrl: './diagram-overview.component.html',
  styleUrls: ['./diagram-overview.component.scss']
})
export class DiagramOverviewComponent {
  path: typeof DiagramPath = DiagramPath;
}
