import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {EquipmentMenu} from "../add-equipment.component";
import {animate, style, transition, trigger} from "@angular/animations";
import {RouterForm, RouterService, RouterType} from "../../../../../services/back/router.service";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {ipAddressValidator} from "../../../../../utils/forms.utils";
import {finalize, Observable, of, Subscription, switchMap} from "rxjs";
import {CurrentTenantService} from "../../../../../services/front/current-tenant.service";
import {filter} from "rxjs/operators";
import {DesignSystemModule} from "../../../../design-system/design-system.module";
import {NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-add-router',
  standalone: true,
	imports: [
		DesignSystemModule,
		FormsModule,
		NgIf,
		TranslateModule,
		ReactiveFormsModule
	],
  templateUrl: './add-router.component.html',
  styleUrl: './add-router.component.scss',
	animations: [
		trigger('validators-appear', [
			transition(':enter', [
				style({opacity: 0, display: 'none', transform: 'translateY(-10px)'}),
				animate('300ms ease-in-out', style({opacity: 1, display: 'block', transform: 'translateY(0)'})),
			])
		])
	]
})
export class AddRouterComponent {
	@Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() onGoBack: EventEmitter<void> = new EventEmitter<void>();

	@Input() type: EquipmentMenu.ROUTER | EquipmentMenu.FIREWALL;

	formGroup: FormGroup = new FormGroup({
		name: new FormControl<string | null>(null, {validators: [Validators.required]}),
		ipAddress: new FormControl<string | null>(null, [ipAddressValidator()]),
		location: new FormControl<string | null>(null),
		description: new FormControl<string | null>(null),
	});

	subscription: Subscription = new Subscription();

	_saving: boolean = false;

	routerService: RouterService = inject(RouterService);
	currentTenantService: CurrentTenantService = inject(CurrentTenantService);

	tenantId: string;

	ngOnInit(): void {
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges().subscribe(tenantId => {
			this.tenantId = tenantId;
		}));
	}

	closeDialog(event: boolean): void {
		this.close.emit(event);
	}

	submit(): void {
		this.subscription.add(this.switchSaving()
			.pipe(
				filter(() => this.formGroup.valid),
				switchMap((form) => this.routerService.create(this.tenantId, this.buildForm())),
				finalize(() => this.switchSaving())
			).subscribe(result => {
				this.closeDialog(true);
			}));
	}

	buildForm(): RouterForm {
		return {
			name: this.nameFormControl.value,
			type: this.type === EquipmentMenu.FIREWALL ? RouterType.FIREWALL : RouterType.ROUTER,
			ipAddress: this.ipAddressFormControl.value,
			location: this.locationFormControl.value,
			description: this.descriptionFormControl.value,
			internetConnection: undefined
		}
	}

	switchSaving(): Observable<{}> {
		this._saving = !this._saving;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	get nameFormControl(): FormControl {
		return this.formGroup.get('name') as FormControl;
	}

	get ipAddressFormControl(): FormControl {
		return this.formGroup.get('ipAddress') as FormControl;
	}

	get locationFormControl(): FormControl {
		return this.formGroup.get('location') as FormControl;
	}

	get descriptionFormControl(): FormControl {
		return this.formGroup.get('description') as FormControl;
	}

	protected readonly EquipmentMenu = EquipmentMenu;
}
