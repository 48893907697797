import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {finalize, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {Organization, OrganizationService, OrganizationTree} from 'src/app/services/organization.service';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {ApplicationOptimization, ApplicationOptimizationForm} from 'src/app/services/model/new-application.model';
import {filter, map} from 'rxjs/operators';
import {ContractInfo} from 'src/app/services/model/application-contract.model';
import {ApplicationContractFormDialogComponent, ContractFormDialogData} from 'src/app/modules/home/applications/application-detail/finance-tab/application-contract-form-dialog/application-contract-form-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-application-optimizations-drawer',
  standalone: false,
  templateUrl: './application-optimizations-drawer.component.html',
  styleUrls: ['./application-optimizations-drawer.component.scss']
})
export class ApplicationOptimizationsDrawerComponent implements OnInit, OnDestroy {

  @Input() data: ApplicationOptimizationsDrawerData;

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  tenantId: string;

  _initializing: boolean;
  _loading: boolean;
  _updating: boolean;
  _editing: boolean;

  applications: ApplicationOptimization[] = [];
  minUser: Record<string, number> = {};
  expand: Record<string, boolean> = {};
  displayArchived: boolean = false;
  organization?: OrganizationTree;

  subscription: Subscription = new Subscription();

  constructor(private currentTenantService: CurrentTenantService,
              private applicationService: NewApplicationService,
              private organizationService: OrganizationService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.subscription.add(this.currentTenantService.getInitializingChanges()
      .subscribe(initializing => this._initializing = initializing));
    this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
      .pipe(tap(tenantId => this.tenantId = tenantId))
      .subscribe(() => this.initialize(this.data.filter)));
  }

  initialize(org?: Organization|null): void {
    this.switchLoading()
      .pipe(
        switchMap(() => this.applicationService.getAllApplicationOptimizationByTenantId(this.tenantId, org?.organizationId)),
        tap(data => this.setApplicationOptimizations(data)),
        finalize(() => this.switchLoading()))
      .subscribe();
  }

  setApplicationOptimizations(data: ApplicationOptimization[]): void {
    this.applications = data
      .sort((a, b) => (b.potentialEarnings.value ?? 0) - (a.potentialEarnings.value ?? 0));
    this.applications
      .forEach(app => this.minUser[app.application.id] = app.contracts
        .map(c => c.licensedUsers!)
        .reduce((a, b) => Math.min(a, b)));
  }

  updateApplicationOptimization(app: ApplicationOptimization): void {
    this.switchUpdating()
      .pipe(
        map(() => this.buildApplicationOptimizationForm(app)),
        switchMap(form => this.applicationService.updateApplicationOptimization(this.tenantId, app.application.id, form)),
        finalize(() => this.switchUpdating()))
      .subscribe(() => app.archived = !app.archived);
  }

  private buildApplicationOptimizationForm(app: ApplicationOptimization): ApplicationOptimizationForm {
    return {
      archived: !app.archived
    };
  }

  editContract(applicationId: string, contract: ContractInfo): void {
    // TODO uncomment html or delete this method
    this.switchEditing()
      .pipe(
        switchMap(() => !this.organization
          ? this.organizationService.getOrganizationTreeByTenantId(this.tenantId)
          : of(this.organization)),
        tap(org => this.organization = org),
        map(() => this.buildContractFormDialogData(applicationId, contract)),
        switchMap(data => this.dialog.open(ApplicationContractFormDialogComponent, {position: { right:'162.5px' }, width: '475px', data: data }).afterClosed()),
        filter(result => result))
      .subscribe(() => this.initialize(this.data.filter))
  }

  private buildContractFormDialogData(applicationId: string, contract: ContractInfo): ContractFormDialogData {
    return {
      applicationId: applicationId,
      organization: this.organization!,
      info: contract
    }
  }

  close(): void {
    this.onClose.emit('');
  }

  private switchLoading(): Observable<{}> {
    this._loading = !this._loading;
    return of({});
  }

  private switchUpdating(): Observable<{}> {
    this._updating = !this._updating;
    return of({});
  }

  private switchEditing(): Observable<{}> {
    this._editing = !this._editing;
    return of({});
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

export interface ApplicationOptimizationsDrawerData {
  filter?: Organization|null;
}
