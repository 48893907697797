import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-linear-gauge',
	standalone: false,
  templateUrl: './linear-gauge.component.html',
  styleUrls: ['./linear-gauge.component.scss']
})
export class LinearGaugeComponent implements OnInit {
	@Input() percent: number | undefined;
	@Input() legend: string;

  constructor() { }

  ngOnInit(): void {
  }

  getGaugeIcon(percent: number): string {
	  if (percent < 60) {
		  return 'check-mark-outline.svg'
	  } else if (percent >= 60 && percent < 80 ) {
		  return 'warning-outline.svg'
	  } else {
		  return 'warning-outline.svg'
	  }
  }
  getGaugeColor(percent: number | undefined): string {
	  if (percent == undefined)
		  return 'secondary'
	  else if (percent < 60) {
		  return 'success'
	  } else if (percent >= 60 && percent < 80 ) {
		  return 'warning'
	  } else {
		  return 'danger'
	  }
  }
}
