import {
	AfterViewInit,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	QueryList, SimpleChanges,
	ViewChild
} from "@angular/core";
import {TabComponent} from "./tab/tab.component";
import {debounceTime} from "rxjs/operators";
import {skip, Subject} from "rxjs";

@Component({
	selector: 'app-tabs-group',
	standalone: false,
	styleUrls: ['./tabs.component.scss'],
	templateUrl: './tabs.component.html'
})
export class TabsComponent implements OnChanges, AfterViewInit {
	@ContentChildren(TabComponent) viewChildren!: QueryList<TabComponent>;

	@Input() selectedIndex: number = 0;

	@Output() selectedIndexChange = new EventEmitter<number>();

	public tabs: Tab[] = [];

	get tabCount() { return this.viewChildren.length }

	private intersectionSubject = new Subject<boolean>(); // Subject to emit visibility updates

	isStickyElementPinned: boolean = false;

	observer!: IntersectionObserver;

	constructor(private elRef: ElementRef) {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.selectedIndex) {
			this.displayTab(this.tabs[this.selectedIndex])
		}
	}

	ngAfterViewInit() {
		const stickyElement = this.elRef.nativeElement.querySelector('.sticky-element');
		this.observer = new IntersectionObserver(
			([entry]) => {
				this.intersectionSubject.next(entry.intersectionRatio < 1);
			},
			{
				threshold: [1]
			}
		);
		this.observer.observe(stickyElement);

		this.intersectionSubject
			.pipe(skip(1))
			.subscribe((isPinned) => {
				this.isStickyElementPinned = isPinned;
			});

		setTimeout(() => {
			this.tabs = this.viewChildren.map((item: any, idx) => ({item: item, label: () => item.tabLabel, badge:() => item.tabBadge, icon:() => item.tabIcon, id:idx}));

			this.tabs.forEach((item: any, idx) => {
				if (idx === this.selectedIndex) {
					item.item.display();
				} else {
					item.item.hide();
				}
			})
		})
	}

	displayTab(tab: Tab) {
		this.tabs.forEach((item: any) => {
			if (item.id === tab.id) {
				item.item.display();
				this.selectedIndexChange.emit(tab.id)
			} else {
				item.item.hide();
			}
		})
	}

	ngOnDestroy() {
		this.observer.disconnect();
	}
}

export interface Tab {
	id: number,
	item: TabComponent,
	label: () => string,
	badge: () => string | undefined,
	icon: () => string | undefined
}
