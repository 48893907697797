import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {finalize, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {Organization} from 'src/app/services/organization.service';
import {HourlyAmplitude, UsageService} from 'src/app/services/usage.service';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';

@Component({
  selector: 'app-hourly-amplitude-radial',
	standalone: false,
  templateUrl: './hourly-amplitude-radial.component.html',
  styleUrls: ['./hourly-amplitude-radial.component.scss']
})
export class HourlyAmplitudeRadialComponent implements OnInit, OnDestroy {

	@Input() filter: Subject<Organization|null|undefined>;

	tenantId: string;

	_initializing: boolean;
	_loading: boolean;

	data: HourlyAmplitude;
	startAngle: number = 0;
	endAngle: number = 0;
	timeDiff?: {hour: number, minute: number};
	protected readonly AngleType = AngleType;
	protected readonly PosType = PosType;

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	constructor(private currentTenantService: CurrentTenantService,
							private usageService: UsageService) {
	}

	ngOnInit() {
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.filter
			.subscribe(filter => this.initialize(filter)));
	}

	initialize(org?: Organization|null): void {
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				switchMap(() => this.usageService.getHourlyAmplitudeByTenantId(this.tenantId, org?.organizationId, QueryRangeType.PAST_1_MONTH)),
				tap(data => this.setHourlyAmplitudeRadial(data)),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	setHourlyAmplitudeRadial(data: HourlyAmplitude): void {
		this.data = data;
		this.startAngle = 0;
		this.endAngle = 0;
		if (data && (data.beginning || data.beginning === 0) && (data.end || data.end === 0)) {
			this.timeDiff = {
				hour: data.end?.hour - data.beginning?.hour,
				minute: data.end?.minute - data.beginning?.minute
			};
			if (this.timeDiff.minute < 0) {
				this.timeDiff.hour -= 1;
				this.timeDiff.minute += 60;
			}
		} else {
			this.timeDiff = undefined;
		}
		if (data && (data.beginning || data.beginning === 0) && (data.end || data.end === 0)) {
			this.startAngle = data.beginning?.hour / 23 * 180;
			this.endAngle = data.end?.hour / 23 * 180;
		}
	}

	polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number): {x: number, y: number} {
		const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
		return {
			x: centerX + (radius * Math.cos(angleInRadians)),
			y: centerY + (radius * Math.sin(angleInRadians))
		};
	}

	describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number): string{
		const start = this.polarToCartesian(x, y, radius, endAngle);
		const end = this.polarToCartesian(x, y, radius, startAngle);
		const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
		return [
			"M", start.x, start.y,
			"A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
		].join(" ");
	}

	describeBlueArc(x: number, y: number, radius: number, startAngle: number, endAngle: number): string{
		if (startAngle == endAngle) {
			startAngle = startAngle - 0.1;
			endAngle = endAngle + 0.1;
		}
		let start = this.polarToCartesian(x, y, radius, endAngle - (startAngle + endAngle) / 2);
		let end = this.polarToCartesian(x, y, radius, startAngle - (startAngle + endAngle) / 2);
		const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
		return [
			"M", start.x, start.y,
			"A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
		].join(" ");
	}

	circlePos(x: number, y: number, radius: number, angle: number, angleType: AngleType): number{
		const startAngle = this.startAngle - 90
		const endAngle = this.endAngle - 90
		const coords = this.polarToCartesian(x, y, radius, angle - (startAngle + endAngle) / 2);
		return angleType == AngleType.X ? coords.x : coords.y;
	}

	textPos(x: number, y: number, radius: number, angle: number, type: AngleType, pos: PosType): number{
		const startAngle = this.startAngle - 90
		const endAngle = this.endAngle - 90
		const ang = angle - (startAngle + endAngle) / 2
		const coords = this.polarToCartesian(x, y, radius, ang);
		if (type === AngleType.X) {
			if (pos === PosType.LEFT) {
				return coords.x - 15;
			}
			if (pos === PosType.RIGHT) {
				return coords.x + 1;
			}
			return coords.x - 3;
		}
		if (ang > 90 && ang < 270) {
			return coords.y + 6;
		}
		return coords.y - 6;
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.initializeSub?.unsubscribe();
	}
}

enum AngleType {
	X,
	Y
}

enum PosType {
	LEFT,
	RIGHT,
	CENTER
}
