import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import {
	ApplicationMappingApplicationFormData
} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-mapping-application-form/application-mapping-application-form.component';
import {Dialog} from "@angular/cdk/dialog";
import {RightSliderService} from "../../../../../../../services/front/right-slider.service";
import {tap} from "rxjs/operators";

@Component({
	templateUrl: './application-mapping-application-form-dialog.component.html',
	standalone: false,
	styleUrls: ['./application-mapping-application-form-dialog.component.scss']
})
export class ApplicationMappingApplicationFormDialogComponent {
	protected innerState = ApplicationDialogState.LOADING;

	constructor(@Inject(MAT_DIALOG_DATA) public data: ApplicationMappingApplicationFormData,
				public dialog: Dialog,
				public rightService: RightSliderService,
				public dialogRef: MatDialogRef<ApplicationMappingApplicationFormDialogComponent>) {
		dialogRef.disableClose = true;
	}

	handlePlaceholderAction(event: { reason: 'close' | 'cancel' | 'apply' }): void {
		if (event.reason === 'apply') {
			this.dialogRef.afterClosed().pipe(tap(() => this.rightService.close())).subscribe()
			this.dialogRef.close()
		} else {
			this.close();
		}
	}

	changeState(hasApplication: boolean): void {
		this.innerState = hasApplication ? ApplicationDialogState.READY : ApplicationDialogState.EMPTY;
	}

	close(updated?: boolean): void {
		this.dialogRef.close(updated);
	}

	protected readonly applicationDialogState = ApplicationDialogState;
}

export enum ApplicationDialogState {
	LOADING= 'LOADING',
	EMPTY= 'EMPTY',
	READY= 'READY'
}
