import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
	Organization, OrganizationIcon,
	OrganizationService,
	OrganizationSettingGeneralForm
} from "../../../../../../services/organization.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {finalize, mergeMap, Observable, of, Subscription, takeWhile, tap} from "rxjs";
import {
	OrganizationDetailData,
	OrganizationDetailService
} from "../../../../../../services/front/organization-detail.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {SnackbarService} from "../../../../../../services/front/snackbar.service";
import {filter, first, map} from "rxjs/operators";
import {OrganizationType} from "../../../../../../services/model/autodiscover.model";
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {CopyTextModule} from "../../../../../global/input/copy-text/copy-text.module";

@Component({
  selector: 'app-organization-setting-general',
  standalone: true,
	imports: [CommonModule, ReactiveFormsModule, TranslateModule, DesignSystemModule, CopyTextModule],
  templateUrl: './organization-setting-general.component.html',
  styleUrl: './organization-setting-general.component.scss'
})
export class OrganizationSettingGeneralComponent implements OnInit, OnDestroy {
	tenantId: string;
	isEditor: boolean;
	organization: Organization;

	_initializing: boolean;
	_loading: boolean;
	initialized = false;

	generalForm: FormGroup;
	formName = Form;

	iconSelection = false;

	icons: string[] = Object.values(OrganizationIcon);

	updateGeneralOnBlur = new EventEmitter<Form>();

	subscription: Subscription = new Subscription();

	constructor(private organizationDetailService: OrganizationDetailService,
				private organizationService: OrganizationService,
				private translate: TranslateService,
				private snackbar: SnackbarService) {
	}

	ngOnInit() {
		this.buildForm();
		this.subscription.add(this.organizationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.organizationDetailService.getOrganizationDetailDataChanges()
			.pipe(tap(data => this.setOrganizationDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				tap(() => this.setDefaultData()),
				tap(() => this.fixMarkAsTouched()),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	private buildForm(): void {
		this.generalForm = new FormGroup({
			[Form.name]: new FormControl(undefined, [Validators.required]),
			[Form.icon]: new FormControl(undefined),
		});
		this.generalForm.disable();
		this.subscription.add(this.updateGeneralOnBlur
			.pipe(filter(() => !this.isGeneralFormValid()))
			.subscribe(() => this.setGeneralFormDefaultData(this.organization)));
		this.updateGeneralOnBlur
			.pipe(
				filter(formName => this.isGeneralFormValid() && this.checkGeneralFormChanged(formName)),
				map(() => this.buildOrganizationSettingGeneralForm()),
				mergeMap(form => this.organizationService.updateOrganizationSettingGeneral(this.tenantId, this.organization.organizationId, form)),
				tap(updateSucceed => this.displayUpdatedSucceed(updateSucceed)),
				tap(() => this.organizationDetailService.refreshOrganization()),
				takeWhile(() => !this.subscription.closed))
			.subscribe();
	}

	private fixMarkAsTouched(): void {
		Object.values(Form)
			.map(name => this.generalForm.get(name)!)
			.filter(control => !!control.validator)
			.forEach(control => control.valueChanges.pipe(first()).subscribe(() => control.markAsTouched()));
	}

	private setOrganizationDetailData(data: OrganizationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.organization = data.organization;
	}

	private setDefaultData(): void {
		this.setGeneralFormDefaultData(this.organization);
		this.checkGeneralFormDisabled();
	}

	private setGeneralFormDefaultData(data: Organization): void {
		this.generalForm.get(Form.name)!.setValue(data.name);
		if (data.type === OrganizationType.BUSINESS_UNIT) {
			this.generalForm.get(Form.icon)!.setValue(data.icon);
		}
	}

	onUpdateIcon(icon: string): void {
		this.generalForm.get(Form.icon)!.setValue(icon);
		this.generalForm.markAsDirty();
		this.updateGeneralOnBlur.emit(Form.icon);
		this.iconSelection = false;
	}

	checkGeneralFormDisabled(): void {
		if (!this.isEditor) {
			this.generalForm.disable();
		} else {
			this.generalForm.enable();
		}
	}

	isGeneralFormValid(): boolean {
		return this.generalForm.dirty && this.generalForm.valid && this.generalForm.enabled;
	}

	checkGeneralFormChanged(formName?: Form): boolean {
		return !formName
			?  Object.values(Form).map(formName => this.checkGeneralForm(formName)).reduce((a, b) => a || b)
			: this.checkGeneralForm(formName);
	}

	private checkGeneralForm(formName: Form): boolean {
		switch (formName) {
			case Form.name: return this.generalForm.get(Form.name)!.value !== this.organization.name;
			case Form.icon: return this.generalForm.get(Form.icon)!.value !== this.organization.icon;
		}
	}

	private buildOrganizationSettingGeneralForm(): OrganizationSettingGeneralForm {
		return {
			name: this.generalForm.get(Form.name)!.value,
			icon: this.generalForm.get(Form.icon)!.value,
		};
	}

	displayUpdatedSucceed(updateSucceed: boolean): void {
		if (updateSucceed) {
			this.snackbar.show(this.translate.instant('page.application.detail.update.success'));
		} else {
			this.snackbar.show(this.translate.instant('page.application.detail.update.fail'), 'danger-snack');
		}
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	get iconValue(): string {
		return this.generalForm.get(Form.icon)!.value;
	}

	get nameFormControl(): FormControl {
		return this.generalForm.get(Form.name) as FormControl;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	protected readonly OrganizationType = OrganizationType;
}

enum Form {
	name = 'name',
	icon = 'icon',
}
