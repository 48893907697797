import {NgModule} from '@angular/core';
import {AlertComponent} from './alert.component';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBar} from "@angular/material/progress-bar";

@NgModule({
	imports: [
		CommonModule,
		MatIconModule,
		MatProgressBar
	],
	declarations: [
		AlertComponent,
	],
	exports: [
		AlertComponent,
	]
})
export class AlertModule {
}
