import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-chips-count',
  standalone: false,
  templateUrl: './chips-count.component.html',
  styleUrls: ['./chips-count.component.scss']
})
export class ChipsCountComponent implements OnInit {
  @Input() value: number|string|undefined|null;
  @Input() type: 'user'|'application'|'flux'|'server'|'star'|'visibility';
  @Input() color: 'grey'|'primary'|'accent'|'accent-secondary'|'warn'|'success'|'warning'|'danger' = 'grey';

  private _bold: boolean;
  @Input()
  get bold() {
    return this._bold;
  }
  set bold(value: any) {
    this._bold = value != null && `${value}` !== 'false';
  }

  src: string;
  srcHeight: number;

  ngOnInit(): void {
    this.src = this.getIconByType(this.type);
    this.srcHeight = this.getSrcHeightByType(this.type);
  }

  private getIconByType(type: 'user'|'application'|'flux'|'server'|'star'|'visibility'): string {
    switch (type) {
      case 'user': return 'assets/icons/user-2.svg';
      case 'application': return 'assets/icons/app-list.svg';
      case 'flux': return 'assets/icons/flow.svg';
      case 'server': return 'assets/icons/server-04.svg';
      case 'star': return 'assets/icons/star.svg';
      case 'visibility': return 'assets/icons/eye-active.svg';
    }
  }

  private getSrcHeightByType(type: 'user'|'application'|'flux'|'server'|'star'|'visibility'): number {
    switch (type) {
      case 'user': return 16;
      case 'application': return 16;
      case 'flux': return 16;
      case 'server': return 16;
      case 'star': return 10;
      case 'visibility': return 12;
    }
  }
}
