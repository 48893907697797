import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {finalize, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {filter, map} from "rxjs/operators";
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {ApplicationContractFormDialogComponent, ContractFormDialogData} from 'src/app/modules/home/applications/application-detail/finance-tab/application-contract-form-dialog/application-contract-form-dialog.component';
import {TenantFileService} from 'src/app/services/back/tenant-file.service';
import {ApplicationContractService} from 'src/app/services/back/application-contract.service';
import {FileLink, FileType} from 'src/app/services/model/tenant-file.model';
import {ContractType, DurationUnit, ServiceType, ContractInfo} from 'src/app/services/model/application-contract.model';
import {OrganizationService, OrganizationTree} from 'src/app/services/organization.service';
import {DeleteDialogComponent} from "../../../../../design-system/delete-dialog/delete-dialog.component";

@Component({
  selector: 'app-application-contract-preview',
	standalone: false,
  templateUrl: './application-contract-preview.component.html',
  styleUrls: ['./application-contract-preview.component.scss']
})
export class ApplicationContractPreviewComponent implements OnInit, OnDestroy {

	@Input() refreshFinancialData: EventEmitter<void>;

	tenantId: string;
	applicationId: string;

	_initializing: boolean;
	_loading: boolean;
	_deleting: string|undefined;
	initialized: boolean = false;

	contractInfos: ContractInfo[] = [];
	contracts: ContractTableData[] = [];
	organizationTree?: OrganizationTree;
	fileType: typeof FileType = FileType;

	subscriptions: Subscription = new Subscription();

	constructor(private applicationDetailService: ApplicationDetailService,
							private applicationContractService: ApplicationContractService,
							private organizationService: OrganizationService,
							private tenantFileService: TenantFileService,
							private translate: TranslateService,
							private dialog: MatDialog) {
	}

	ngOnInit(): void {
		this.subscriptions.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscriptions.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.subscriptions.add(this.switchLoading()
			.pipe(
				switchMap(() => this.fetchContracts()),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	private setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.applicationId = data.instance.applicationId;
	}

	fetchContracts(): Observable<{}> {
		return this.applicationContractService.getAllApplicationContractInfo(this.tenantId, this.applicationId)
			.pipe(tap(contract => this.setAllContractInfo(contract)));
	}

	private setAllContractInfo(contractInfos: ContractInfo[]): void {
		this.contractInfos = contractInfos;
		this.contracts = contractInfos.map((info): ContractTableData => ({
			contractId: info.contract.contractId,
			type: info.type,
			status: this.getContractStatus(info),
			expiryDate: info.contract.endDate,
			totalAmount: info.amount,
			recurrencePeriod: info.isRecurringPayment ? info.paymentDurationUnit : null,
			files: info.contract.documents,
			updatedAt: info.contract.updatedAt
		}))
			.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
	}

	private getContractStatus(info: ContractInfo): 'active'|'expired'|'waiting' {
		const today: number = new Date().getTime();
		const startDate: number = new Date(info.contract.startDate).getTime();
		const expiryDate: number|null = !info.contract.endDate ? null : new Date(info.contract.endDate).getTime();
		if (startDate > today) return 'waiting';
		if (expiryDate && expiryDate < today) return 'expired';
		return 'active';
	}

	deleteContract(contract: ContractTableData): void {
		this.dialog.open(DeleteDialogComponent, {
			width: '475px',
			position: {
				right: '162.5px'
			},
			data: {
				icon: '/assets/illustrations/delete/contract.svg',
				title: this.translate.instant('page.appDetails.finance.contracts.delete.title'),
				subtitle: this.translate.instant('page.appDetails.finance.contracts.delete.subtitle'),
				warningMessage: this.translate.instant('page.appDetails.finance.contracts.delete.warningMessage'),
				deleteButton: this.translate.instant('page.appDetails.finance.contracts.delete.deleteButton')
			}})
			.afterClosed()
			.pipe(
				filter(result => result),
				switchMap(() => this.switchDeleting(contract.contractId)),
				switchMap(() => this.applicationContractService.deleteContract(this.tenantId, this.applicationId, contract.contractId)),
				switchMap(() => this.fetchContracts()),
				switchMap(() => this.switchDeleting(contract.contractId)))
			.subscribe(() => this.refreshFinancialData.emit());
	}

	openContractDialog(contract?: ContractTableData): void {
		of(this.organizationTree)
			.pipe(
				switchMap(organizationTree => !organizationTree
					? this.organizationService.getOrganizationTreeByTenantId(this.tenantId)
					: of(organizationTree)),
				tap(tree => this.organizationTree = tree),
				map(tree => this.buildContractFormDialogData(tree, contract)),
				switchMap(data => this.dialog.open(ApplicationContractFormDialogComponent, {position: { right:'162.5px' }, width: '475px', data: data }).afterClosed()),
				filter(result => result),
				switchMap(() => this.fetchContracts()))
			.subscribe(() => this.refreshFinancialData.emit());
	}

	private buildContractFormDialogData(tree: OrganizationTree, contract?: ContractTableData): ContractFormDialogData {
		return {
			applicationId: this.applicationId,
			organization: tree,
			info: !contract ? undefined : this.contractInfos.find(c => c.contract.contractId === contract.contractId),
		}
	}

	sizePrettily(size: number | undefined): string {
		if (!size) return '';
		if (size < 1024) {
			return `${size} B`;
		} else if (size < 1024 * 1024) {
			return `${Math.round(size / 1024)} KB`;
		} else if (size < 1024 * 1024 * 1024) {
			return `${Math.round(size / (1024 * 1024))} MB`;
		} else {
			return `${Math.round(size / (1024 * 1024 * 1024))} GB`;
		}
	}

	downloadFile(upload: FileLink) {
		this.tenantFileService.downloadTenantFile(this.tenantId, upload.fileLinkId)
			.subscribe((blob) => {
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = upload.name;
				link.click();
			});
	}

	openLink(link: string) {
		window.open(link, '_blank');
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	private switchDeleting(contractId: string): Observable<{}> {
		this._deleting = !this._deleting ? contractId : undefined;
		return of({});
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}

interface ContractTableData {
	contractId: string;
	type: ContractType|ServiceType;
	status: 'active'|'expired'|'waiting';
	expiryDate: string|null;
	totalAmount: number|null;
	recurrencePeriod: DurationUnit|null;
	files: FileLink[];
	updatedAt: string;
}
