import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SimpleTextInputComponent} from "./simple-text-input.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {MatFormField} from "@angular/material/form-field";



@NgModule({

	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatIconModule,
		TranslateModule,
		MatFormField
	],
	declarations: [
		SimpleTextInputComponent
	],
	exports: [
		SimpleTextInputComponent
	]
})
export class SimpleTextInputModule { }
