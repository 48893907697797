import {Component, Input, OnInit} from '@angular/core';
import {Color} from "../chips/chips.component";

@Component({
  selector: 'app-tendency-chips',
	standalone: false,
  templateUrl: './tendency-chips.component.html',
  styleUrls: ['./tendency-chips.component.scss']
})
export class TendencyChipsComponent implements OnInit {
	@Input() numericTendency: number | undefined
	@Input() color: Color | 'followTendency' | 'invertedTendency' = 'primary'

  constructor() { }

  ngOnInit(): void {
  }

	getAbsValue(value: number | undefined): string  {
		if (value != undefined)
			return Math.abs(value).toFixed(0);
		return '-';
	}

	getTendencyColor(): Color {
		if (this.getAbsValue(this.numericTendency) == '-')
			return 'secondary'
		else if (this.color == 'followTendency') {
			if (this.numericTendency == undefined)
				return 'secondary'
			else if (this.getAbsValue(this.numericTendency) == '0')
				return 'primary'
			else if (this.numericTendency > 0)
				return 'success'
			else
				return 'danger'
		} else if (this.color == 'invertedTendency') {
			if (this.numericTendency == undefined)
				return 'secondary'
			else if (this.getAbsValue(this.numericTendency) == '0')
				return 'primary'
			else if (this.numericTendency > 0)
				return 'danger'
			else
				return 'success'
		} else
			return this.color
	}

	getArrowFilter(): string {
		let color = this.getTendencyColor()
		if (color == "primary")
			return "filter-accent"
		else if(color == "secondary")
			return "filter-grey"
		else
			return "filter-" + color
	}


}
