import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TenantAccount} from 'src/app/services/model/account.model';

@Component({
  selector: 'app-display-user-logo',
  standalone: false,
  templateUrl: './display-user-logo.component.html',
  styleUrls: ['./display-user-logo.component.scss']
})
export class DisplayUserLogoComponent implements OnChanges {

  @Input() user: TenantAccount | undefined | null;
  @Input() size: number = 30;
  @Input() fontSize: number | undefined = undefined;

  background: `#${string}` = '#3DC0FF';
  color: `#${string}` = '#FFFFFF';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user?.previousValue?.account.accountId !== changes.user?.currentValue?.account.accountId) {
      this.background = this.getBgColor();
      this.color = this.getContrastColor(this.background);
    }
  }

  private getBgColor(): `#${string}` {
    const name: string = !this.user ? 'ka' : this.user.account.firstName[0].toLowerCase() + this.user.account.lastName[0].toLowerCase();
    const value: number = parseInt(name[0], 36) * parseInt(name[1], 36);
    return this.getRandomColor(value);
  }

  private getRandomColor(value: number): `#${string}` {
    switch (value % 6) {
      case 0: return '#257399';
      case 1: return '#FF9900';
      case 2: return '#625BF6';
      case 3: return '#FF5555';
      case 4: return '#3DC0FF';
      case 5:
      default: return '#4AB04A';
    }
  }

  private getContrastColor(bgColor: `#${string}` = '#3DC0FF', lighter: `#${string}` = '#FFFFFF', darker: `#${string}` = '#0C2633'): `#${string}` {
    const r: number = parseInt(bgColor.substring(1, 3), 16);
    const g: number = parseInt(bgColor.substring(3, 5), 16);
    const b: number = parseInt(bgColor.substring(5, 7), 16);
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ? darker : lighter;
  }
}
