import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ApplicationDetailComponent, ApplicationDetailInput} from '../../applications/application-detail/application-detail.component';
import {RightSliderService} from 'src/app/services/front/right-slider.service';
import {ApplicationContractDeadline, ApplicationCostUsage} from 'src/app/services/model/application-contract.model';
import {finalize, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {TenantFinanceService} from 'src/app/services/back/tenant-finance.service';
import {Organization} from 'src/app/services/organization.service';
import {MostExpensiveAppsDrawerComponent, MostExpensiveAppsDrawerData} from 'src/app/modules/home/finance-dashboard/most-expensive-apps/drawer/most-expensive-apps-drawer.component';
import {ExportService, ExportType} from 'src/app/services/front/export.service';
import {TranslateService} from '@ngx-translate/core';
import {ColumnDefinition} from "../../../design-system/table/table.component";
import {CriticalityLevel} from "../../../../services/tenant.service";

@Component({
  selector: 'app-most-expensive-apps',
	standalone: false,
  templateUrl: './most-expensive-apps.component.html',
  styleUrls: ['./most-expensive-apps.component.scss']
})
export class MostExpensiveAppsComponent implements OnInit, OnDestroy {

	@Input() defaultFilter?: Organization|null|undefined;
	@Input() filter?: Subject<Organization|null|undefined>;
	@Input() search?: Observable<string|null|undefined>;
	@Input() export?: Subject<ExportType>;
	@Input() limit?: number;
	@Input() type: 'widget'|'drawer' = 'widget';

	tenantId: string;
	lastFilter?: Organization|null;

	_initializing: boolean;
	_loading: boolean;

	applications: ApplicationCostUsage[] = [];
	filteredApplications: ApplicationCostUsage[] = [];

	columnDefinitions: ColumnDefinition<ApplicationCostUsage>[];

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	@ViewChild('applicationTemplate', { static: true }) applicationTemplate: TemplateRef<any>;
	@ViewChild('criticalityTemplate', { static: true }) criticalityTemplate: TemplateRef<any>;
	@ViewChild('usageTemplate', { static: true }) usageTemplate: TemplateRef<any>;
	@ViewChild('annualCostTemplate', { static: true }) annualCostTemplate: TemplateRef<any>;

	constructor(private currentTenantService: CurrentTenantService,
							private tenantFinanceService: TenantFinanceService,
							private rightSliderService: RightSliderService,
							private exportService: ExportService,
							private translate: TranslateService) {
	}

	ngOnInit() {
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize(this.defaultFilter)));
		if (this.filter) {
			this.subscription.add(this.filter
				.pipe(tap(filter => this.lastFilter = filter))
				.subscribe(filter => this.initialize(filter)));
		}
		if (this.search) {
			this.subscription.add(this.search
				.subscribe(search => this.filterApplications(search!)));
		}
		if (this.export) {
			this.subscription.add(this.export
				.subscribe(type => this.exportData(type)));
		}
	}

	initialize(org?: Organization|null): void {
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				tap(() => this.setColumnDefinitions()),
				switchMap(() => this.tenantFinanceService.getAllMostExpensiveApplicationCost(this.tenantId, org?.organizationId, this.limit)),
				tap(data => this.applications = data),
				tap(data => this.filteredApplications = data),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	setColumnDefinitions(): void {
		this.columnDefinitions = [
			{
				name: 'application',
				label: 'global.table.application.name',
				template: this.applicationTemplate,
				sortFunction: (a, b) => a.application.name.localeCompare(b.application.name),
				width: 40,
			},
			{
				name: 'application.criticality',
				label: 'global.table.application.criticality',
				template: this.criticalityTemplate,
				sortFunction: (a, b) => {
					const order: (CriticalityLevel|null|undefined)[] = [CriticalityLevel.HIGH, CriticalityLevel.MEDIUM, CriticalityLevel.LOW, null, undefined];
					return order.indexOf(a.application.criticality) - order.indexOf(b.application.criticality);
				},
			},
			{
				name: 'usage',
				label: 'global.table.application.usage',
				template: this.usageTemplate,
				sortFunction: (a, b) => {
					// undefined and null lower than 0
					const aUsage = a.usage.value === null ? -1 : a.usage.value;
					const bUsage = b.usage.value === null ? -1 : b.usage.value;
					return aUsage - bUsage;
				},			},
			{
				name: 'cost',
				label: 'global.table.contract.annualCost',
				template: this.annualCostTemplate,
				sortFunction: (a, b) => (a.cost.value || 0) - (b.cost.value || 0)
			}
		];
	}

	filterApplications(search?: string|null): void {
		this.filteredApplications = this.applications
			.filter(app => !search || app.application.name.toLowerCase().includes(search.toLowerCase()));
	}

	goToApp(applicationInstanceId: string): void {
		const data: ApplicationDetailInput = {
			applicationId: applicationInstanceId
		};
		this.rightSliderService.openComponent(ApplicationDetailComponent, data)
			.subscribe(() => this.filter?.next(this.lastFilter));
	}

	openDrawer(): void {
		if (this.type === 'widget' && this.applications.length > 0) {
			const data: MostExpensiveAppsDrawerData = {
				filter: this.lastFilter
			};
			this.rightSliderService.openComponent(MostExpensiveAppsDrawerComponent, data);
		}
	}

	exportData(type: ExportType): void {
		const rows: ApplicationCostCsv[] = this.filteredApplications.map(a => ({
			name: a.application.name,
			criticality: !a.application.criticality
				? '-'
				: this.translate.instant('page.appDetails.businessCriticality.' + a.application.criticality.toLowerCase()),
			users: a.usage.value?.toString() ?? '-',
			cost: !a.cost.value ? '-' : a.cost.value + ' ' + localStorage.getItem('currency')
		}));
		this.exportService.export(type, rows, 'application-deadlines');
	}

	onSorted(event: ApplicationCostUsage[]): void {
		this.filteredApplications = event;
	}

	onRowClick(event: ApplicationCostUsage): void {
		this.goToApp(event.application.id);
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.initializeSub?.unsubscribe();
	}
}

interface ApplicationCostCsv {
	name: string;
	criticality: string;
	users: string;
	cost: string;
}
