import {ActivatedRoute, Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";

@Injectable()
export class ServerUrlService {
	constructor(protected activatedRoute: ActivatedRoute, protected router: Router) {
	}

	public getCurrentServerTab(): number {
		let index = this.activatedRoute.snapshot.queryParamMap.get('selectedServerTab');
		if (index != null)
			return parseInt(index)
		return 0;
	}

	public getParameter(): boolean {
		let settingsPresent = this.activatedRoute.snapshot.queryParamMap.get('settings');
		return settingsPresent != null && settingsPresent != 'false';
	}

	public changeCurrentServerTab(index: number | null) {
		this.router.navigate([this.router.url.split('?')[0]], {
			queryParams: {'selectedServerTab': index},
			queryParamsHandling: "merge"
		})
	}

	public changeCurrentServer(id: string | null) {
		if (id !== null)
			this.router.navigate([], {queryParams: {serverId: id}, queryParamsHandling: "merge"})
		else
			this.router.navigate([], {
				queryParams: {serverId: null, selectedApplicationTab: null, settings: null},
				queryParamsHandling: "merge"
			})
	}

	navigateInAndOutOfParameter(parameter: boolean) {
		this.navigateInAndOut(parameter);
	}

	private navigateInAndOut(setting: boolean): void {
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: {settings: setting},
			queryParamsHandling: 'merge'
		});
	}
}
