<app-snackbar></app-snackbar>
<div class="w-100 h-100">
	<div class="header-height">
		<!-- Title loader -->
		<div class="pl-l pr-l pt-l pb-l" *ngIf="_loading || _initializing" style="height: 37px">
			<app-title-content-loader></app-title-content-loader>
		</div>

		<!-- Title -->
		<div class="pl-l pr-l pt-l pb-l slider-header" *ngIf="!_loading && !_initializing" >
			<div class="flex-align-center gap-S w-100">
				<div class="flex-center bkg-accent-light radius-10" style="height: 45px; width: 45px">
					<img *ngIf="organization.type === OrganizationType.AFFILIATE"
						src="assets/icons/building.svg"
						class="svg-24 filter-accent" alt="">
					<img *ngIf="organization.type === OrganizationType.BUSINESS_UNIT"
						 [src]="organization.icon ? ('/assets/icons/team_icon/' + organization.icon + '.svg') : 'assets/icons/team.svg'"
						 class="svg-24 filter-accent">
				</div>

				<div class="flex-column flex-align-start gap-XS" style="line-height: 0.8rem">
					<!-- Title -->
					<div class="font-L fw-700 primary text-overflow-ellipsis" style="max-width: 550px;">
						<label>{{ organization.name }}</label>
					</div>
					<div *ngIf="organization.type === OrganizationType.BUSINESS_UNIT" class="font-M fw-500 primary text-overflow-ellipsis" style="max-width: 550px;">
						<label>{{ organization.parentOrganization?.name }}</label>
					</div>
				</div>
			</div>

			<div class="flex-align-center gap-S">
				<app-icon-button type="stroked" background [img]="'assets/icons/close-drawer.svg'"
								 (click)="close()">
				</app-icon-button>
				<app-icon-button type="stroked" background [img]="'assets/icons/save.svg'"
								 *ngIf="!!settings"
								 (click)="onSaveSettings()">
				</app-icon-button>
				<app-icon-button type="stroked" background [img]="!settings ? 'assets/icons/cog-inactive.svg' : 'assets/icons/cog-active.svg'"
								 [focused]="!!settings"
								 (click)="onOpenOrganizationSettings()">
				</app-icon-button>
				<app-icon-button type="stroked" background color="danger" [img]="'assets/icons/trash.svg'"
								 (click)="onOpenDeleteOrganizationPopup()">
				</app-icon-button>
			</div>
		</div>
	</div>

	<div *ngIf="!!settings" [ngClass]="{'settings-triangle': !!settings}"></div>

	<div class="content-height border-box overflow-y above-triangle">
		<app-tabs-group [hidden]="!!settings"
						[selectedIndex]="sliderIndex"
						(selectedIndexChange)="onSliderChange($event)">

			<!-- Tab Overview -->
			<app-tab [icon]="'overview'" label="{{ 'menu.overview2' | translate }}">
				<div *ngIf="lazyLoadedTabs[0]" class="flex-column gap-XL" style="margin-top: 17px;">
					<!--<app-overview-tab [tabActive]="sliderIndex === 0 && !settings && !comments"></app-overview-tab>-->
					<app-organization-overview-tab></app-organization-overview-tab>
				</div>
			</app-tab>

			<!-- Tab Applications -->
			<app-tab [icon]="'applications'" [badge]="autodiscoveredApplicationsCount > 0 && displayAutodiscoverApplicationsNotification && organization.type === OrganizationType.BUSINESS_UNIT ? autodiscoveredApplicationsCount.toString() : undefined" label="{{ 'Applications' }}">
				<div *ngIf="lazyLoadedTabs[1]" class="flex-column gap-XL" style="margin-top: 17px;">
					<app-organization-applications-tab (autodiscoveredApplicationsChanged)="onAutodiscoverApplicationsChanged($event)" (autodiscoveredApplicationsSeen)="setLocalStorageApplicationsCount()"></app-organization-applications-tab>
				</div>
			</app-tab>

			<!-- Tab Finance -->
			<app-tab [icon]="'finance'" label="{{ 'menu.finance' | translate }}">
				<div *ngIf="lazyLoadedTabs[2]" class="flex-column gap-XL" style="margin-top: 17px;">
					<app-organization-finance-tab></app-organization-finance-tab>
				</div>
			</app-tab>

			<!-- Tab Usage -->
			<app-tab [icon]="'usage'" label="{{ 'menu.usage' | translate }}">
				<div *ngIf="lazyLoadedTabs[3]" class="flex-column gap-XL" style="margin-top: 17px;">
					<app-organization-usage-tab></app-organization-usage-tab>
				</div>
			</app-tab>
		</app-tabs-group>
		<app-organization-settings *ngIf="!!settings" [settings]="settings"></app-organization-settings>
	</div>
</div>
