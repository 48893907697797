import {Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {mergeMap, switchMap, tap} from 'rxjs';
import {MentionConfig} from 'angular-mentions';
import {NotificationService} from 'src/app/services/notification.service';
import {ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {map} from 'rxjs/operators';
import {ApplicationComment, ApplicationCommentForm} from 'src/app/services/model/application-comment.model';
import {ApplicationCommentService} from 'src/app/services/back/application-comment.service';
import {TenantAccountService} from 'src/app/services/back/tenant-account.service';
import {TenantAccount} from 'src/app/services/model/account.model';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';

@Component({
  selector: 'app-application-comments',
	standalone: false,
  templateUrl: './application-comments.component.html',
  styleUrls: ['./application-comments.component.scss']
})
export class ApplicationCommentsComponent implements OnInit {

	@ViewChild("commentsContainer") commentsContainer: ElementRef;
	@ViewChild("textAreaElement") textAreaElement: ElementRef;
	@ViewChildren("stickyElt") sticky: QueryList<ElementRef>;

	@Output() onCommentsCountChange = new EventEmitter<number>();

	tenantId: string;
	applicationId: string;
	currentUser: TenantAccount;

	loading = false;

	users: UserMentionConfig[] = [];
	message = new FormControl('', [Validators.required, Validators.maxLength(10000)]);
	formControl = new FormControl('', {updateOn: 'blur'});
	rawComments: Comment[] = [];
	groupedComments: CommentGroup[] = [];
	deletedComments: Comment[] = [];
	actionMade = false;
	mentionConfig: MentionConfig;

	constructor(protected applicationDetailService: ApplicationDetailService,
							protected currentTenantService: CurrentTenantService,
							protected applicationCommentService: ApplicationCommentService,
							protected tenantAccountService: TenantAccountService,
							protected notificationService: NotificationService) {
	}

	addCommentsInfo(comments: Comment[]) {
		comments.forEach(comment => {
			comment.date = new Date(comment.date);
			comment.own = comment.user?.account.accountId === this.currentUser.account.accountId;
			comment.deleted = false;
		});
	}

	ngOnInit() {
		this.loading = true;
		this.currentTenantService.getTenantAccountChanges()
			.pipe(
				tap(currentUser => this.currentUser = currentUser),
				switchMap(() => this.applicationDetailService.getApplicationDetailDataChanges()))
			.subscribe(data => {
				this.tenantId = data.tenantId;
				this.applicationId = data.instance.applicationId;

				this.notificationService.markCommentNotificationsRead(this.tenantId, this.applicationId)
					.subscribe(() => this.notificationService.recheckNotifications());

				this.applicationCommentService.getApplicationComments(this.tenantId, this.applicationId)
					.pipe(map(comments => this.parseComments(comments)))
					.subscribe(comments => {
						this.addCommentsInfo(comments)
						this.rawComments = comments.concat(this.deletedComments);
						this.refreshMessage();
						this.scrollToBottom();
						this.loading = false;
					});

				this.tenantAccountService.getAllTenantAccountByTenantId(this.tenantId, false).subscribe(accounts => {
					this.users = accounts
						.filter(tenantAccount => tenantAccount.account.accountId !== this.currentUser.account.accountId)
						.map(tenantAccount => ({
							id: tenantAccount.account.accountId,
							tenantAccount: tenantAccount,
							fullName: `${tenantAccount.account.firstName} ${tenantAccount.account.lastName}`,
							div: `<div class="tag" style="color: #3DC0FF">${tenantAccount.account.firstName} ${tenantAccount.account.lastName}</div>`
						}));

					this.mentionConfig = {
						items: this.users,
						labelKey: 'fullName',
						maxItems: 10,
						dropUp: true,
						returnTrigger: true,
						allowSpace: true,
						mentionSelect: (item: any) => {
							return '##' + item.label + '##';
						}
					}
				});
			});

		const observer = new IntersectionObserver(
			([e]) => e.target.classList.toggle('is-sticky', e.intersectionRatio < 1),
			{threshold: [1]}
		);

		setTimeout(() => {
			this.sticky.forEach((el) => observer.observe(el.nativeElement));
		});
	}

	scrollToBottom(): void {
		setTimeout(() => {
			this.commentsContainer.nativeElement.scrollTop = this.commentsContainer.nativeElement.scrollHeight;
		}, 0);
	}

	refreshMessage(): void {
		this.groupedComments = [];
		const regex = /(<mention>.*?<\/mention>)/;
		this.rawComments.forEach(comment => {
			const monthFormatted = comment.date.getMonth() + 1 < 10 ? '0' + (comment.date.getMonth() + 1) : comment.date.getMonth() + 1;
			const dayFormatted = comment.date.getDate() < 10 ? '0' + comment.date.getDate() : comment.date.getDate();
			const day = `${comment.date.getFullYear()}/${monthFormatted}/${dayFormatted}`;

			const dayOfWeek = comment.date.getDay() == 0 ? 7 : comment.date.getDay();

			const parts = comment.content.split(regex).filter(Boolean);

			const result: CommentContent[] = parts.map(part => {
				if (part.startsWith("<mention>") && part.endsWith("</mention>")) {
					return {
						type: 'mention',
						content: part.substring(9, part.length - 10) // Remove <mention> and </mention>
					};
				} else {
					return {
						type: 'text',
						content: part
					};
				}
			});

			const commentToDisplay: Comment = {
				...comment,
				contentParsed: result
			}

			let keyFound = false;
			this.groupedComments.forEach((group) => {
				if (group.day === day) {
					group.comments.push(commentToDisplay);
					keyFound = true;
				}
			})
			if (!keyFound) {
				this.groupedComments.push({
					day,
					dayOfWeek,
					comments: [commentToDisplay]
				})
			}
		});

		this.onCommentsCountChange.emit(this.groupedComments.flatMap(group => group.comments).length);

		this.groupedComments.sort((a, b) => {
			const aDate = new Date(a.day);
			const bDate = new Date(b.day);
			return aDate.getTime() - bDate.getTime();
		}).forEach(group => {
			group.comments.sort((a, b) => {
				return a.date.getTime() - b.date.getTime();
			})
		});
	}

	decodeHtml(html: string) {
		const txt = document.createElement("textarea");
		txt.innerHTML = html;
		return txt.value;
	}

	sendMessage(): void {
		if (this.currentUser && this.message.value) {
			this.actionMade = true;
			const htmlElt = document.createElement('div');
			htmlElt.innerHTML = this.message.value;
			const spans = htmlElt.querySelectorAll('span');
			const mentions: string[] = [];
			spans.forEach((span: any) => {
				const id = span.dataset.id;
				const user = this.users.find(user => user.id === id);
				if (user) {
					mentions.push(user.id);
					span.outerHTML = '<mention>' + user.fullName + '</mention>';
				}
			});

			// can be spoofed, but it's just for the UI
			if(navigator.userAgent.includes("Firefox")) {
				// for firefox, first remove all brs
				const brs = htmlElt.getElementsByTagName("br");
				for (let i = 0; i < brs.length; i++) {
					brs[i].parentNode?.removeChild(brs[i]);
				}

				// then replace all divs by \n except for the last one
				const divs = htmlElt.querySelectorAll('div');
				divs.forEach((div: any, index) => {
					if (div.innerHTML === '<br>' || div.innerHTML === '<br/>' || div.innerHTML === '<br />') {
						div.outerHTML = '\n';
					} else {
						if (index < divs.length - 1) {
							div.outerHTML = div.innerHTML + '\n';
						} else {
							div.outerHTML = div.innerHTML.replace(/<br>/g, '');
						}
					}
				});
			}

			this.message.setValue(this.decodeHtml(htmlElt.innerHTML));
			const form: ApplicationCommentForm = {
				date: new Date().toISOString(),
				content: this.message.value,
				mentions: mentions
			}
			this.applicationCommentService.sendApplicationComment(this.tenantId, this.applicationId, form).pipe(
				mergeMap(() => this.applicationCommentService.getApplicationComments(this.tenantId, this.applicationId)),
				map(comments => this.parseComments(comments))
			).subscribe(comments => {
				this.message.setValue('');
				this.textAreaElement.nativeElement.innerHTML = '';
				this.addCommentsInfo(comments)
				this.rawComments = comments.concat(this.deletedComments);
				this.refreshMessage();
				this.scrollToBottom();
				this.textAreaElement.nativeElement.style.height = '20px';
				this.actionMade = false;
			});
		}
	}

	deleteComment(comment: Comment): void {
		comment.deleted = true;
		this.actionMade = true;
		this.applicationCommentService.deleteApplicationComment(this.tenantId, this.applicationId, comment.id).pipe(
			mergeMap(() => this.applicationCommentService.getApplicationComments(this.tenantId, this.applicationId)),
			map(comments => this.parseComments(comments))
		).subscribe(comments => {
			comment.timeoutId = setTimeout(() => {
				this.rawComments = this.rawComments.filter(c => c.id !== comment.id);
				if (this.rawComments.length === 0)
					this.groupedComments = [];
				else
					this.groupedComments.forEach(group => {
						group.comments = group.comments.filter(c => c.id !== comment.id);
					});
				this.deletedComments = this.deletedComments.filter(c => c.id !== comment.id);
				this.refreshMessage();
			}, 10000)
			this.deletedComments.push(comment);
			comments.forEach(comment => {
				comment.date = new Date(comment.date);
				comment.own = comment.user?.account.accountId === this.currentUser.account.accountId;
				if (!comment.deleted)
				comment.deleted = false;
			});
			this.rawComments = comments.concat(this.deletedComments);
			this.refreshMessage();
			this.actionMade = false;
		});
	}

	cancelDeleteComment(comment: Comment): void {
		this.actionMade = true;
		const form: ApplicationCommentForm = {
			date: comment.date.toISOString(),
			content: comment.content,
			mentions: [],
			revertDeletedCommentId: comment.id
		}
		this.applicationCommentService.sendApplicationComment(this.tenantId, this.applicationId, form).pipe(
			mergeMap(() => this.applicationCommentService.getApplicationComments(this.tenantId, this.applicationId)),
			map(comments => this.parseComments(comments))
		).subscribe(comments => {
			clearTimeout(comment.timeoutId);
			comment.timeoutId = undefined;
			this.deletedComments = this.deletedComments.filter(c => c.id !== comment.id);
			this.addCommentsInfo(comments)
			this.rawComments = comments.concat(this.deletedComments);
			this.refreshMessage();
			this.actionMade = false;
		});
	}

	itemSelected(event: any) {
		setTimeout(() => {
			this.textAreaElement.nativeElement.innerHTML = this.textAreaElement.nativeElement.innerHTML.replace(
				'##' + event.label + '##',
				'<span contenteditable="false" style="color:#3DC0FF" data-id="'+ event.id +'">' + event.fullName + '</span> '
			);
			this.selectEnd();
		}, 10);
	}

	selectEnd() {
		let range, selection;
		range = document.createRange();
		range.selectNodeContents(this.textAreaElement.nativeElement);
		range.collapse(false);
		selection = window.getSelection();
		if (selection) {
			selection.removeAllRanges();
			selection.addRange(range);
		}
	}

	onInput(event: any) {
		setTimeout(() => {
			this.message.setValue(this.textAreaElement.nativeElement.innerHTML);
		}, 10)
	}

	// for firefox to remove the <br> when empty and make placeholder work
	onFocusOut(event: any) {
		if (event.target.innerHTML === '<br>') {
			event.target.innerHTML = '';
		}
	}

	private parseComments(comments: ApplicationComment[]): Comment[] {
		return comments.map(comment => ({
			id: comment.commentId,
			user: comment.user,
			date: comment.date,
			content: comment.content,
			contentParsed: [],
			own: undefined,
			deleted: undefined,
			timeoutId: undefined
		}));
	}

	asUserMentionConfig(item: UserMentionConfig): UserMentionConfig {
		return item;
	}
}

export interface Comment {
	id: string;
	user?: TenantAccount;
	date: Date;
	content: string;
	contentParsed: CommentContent[];
	own?: boolean;
	deleted?: boolean;
	timeoutId?: any;
}

export interface CommentGroup {
	day: string;
	dayOfWeek: number;
	comments: Comment[];
}

export interface CommentContent {
	type: 'mention' | 'text';
	content: string;
}

interface UserMentionConfig {
	id: string;
	tenantAccount: TenantAccount;
	fullName: string;
	div: string;
}
