import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
	ApplicationMapping,
	ApplicationMappingData,
	ApplicationMappingDataForm,
	ApplicationMappingForm, ExchangeFrequencyType, FormatType,
	ProtocolType
} from 'src/app/services/model/application-mapping.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {ApplicationMappingService} from 'src/app/services/back/application-mapping.service';
import {map} from 'rxjs/operators';
import {ApplicationGeneric} from 'src/app/services/model/new-application.model';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {PORTS_RANGE_REGEXP} from "../../../../../../utils/forms.utils";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'app-application-mapping-application-form',
	standalone: false,
	templateUrl: './application-mapping-application-form.component.html',
	styleUrls: ['./application-mapping-application-form.component.scss']
})
export class ApplicationMappingApplicationFormComponent implements OnInit, OnDestroy {

	@Input() data: ApplicationMappingApplicationFormData;

	@Output() cancel: EventEmitter<void> = new EventEmitter<void>();
	@Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() hasApplication: EventEmitter<boolean> = new EventEmitter<boolean>();

	_initializing: boolean = false;
	_saving: boolean = false;
	_savingData: boolean = false;
	_uploading: boolean;

	formGroup: FormGroup;
	isSource: boolean;

	tabIndex: 0|1|2 = 0;

	applicationList: ApplicationGeneric[] = [];
	mappingDataList: ApplicationMappingData[] = [];
	protocolTypeChoices: ProtocolTypeChoice[] = [];
	formatTypeChoices: FormatTypeChoice[] = [];
	exchangeFrequencyTypeChoices: ExchangeFrequencyTypeChoice[] = [];
	flowEncryptionTypeChoices: FlowEncryptionTypeChoice[] = [];
	portTypeChoices: PortTypeChoice[] = [];
	searchSourceControl: FormControl;
	sourceFilteredList: ApplicationGeneric[] = [];
	searchTargetControl: FormControl;
	targetFilteredList: ApplicationGeneric[] = [];
	searchDataControl: FormControl;
	mappingDataFilteredList: ApplicationMappingData[] = [];

	searchProtocolControl: FormControl;
	protocolFilteredList: ProtocolTypeChoice[] = [];
	searchFormatControl: FormControl;
	formatFilteredList: FormatTypeChoice[] = [];

	iconReversed: boolean|undefined;
	formName: typeof Form = Form;

	subscription: Subscription = new Subscription();

	constructor(private applicationMappingService: ApplicationMappingService,
							private translate: TranslateService,
							private applicationService: NewApplicationService) {
	}

	ngOnInit(): void {
		this.createForm();
		this.buildSelectionLists();
		this.subscription.add(this.switchInitializing()
			.pipe(
				switchMap(() => !this.data.preloaded
					? forkJoin([
						this.applicationService.getAllApplication(this.data.tenantId),
						this.applicationMappingService.getAllApplicationMappingDataByTenantId(this.data.tenantId)])
						.pipe(map(([applicationList, mappingDataList]) => ({applicationList, mappingDataList})))
					: of(this.data.preloaded)),
				tap((formData) => this.hasApplication.emit(formData.applicationList.length > 1)),
				tap((formData) => this.setApplicationMappingFormData(formData.applicationList, formData.mappingDataList)),
				finalize(() => this.switchInitializing()))
			.subscribe(() => this.setDefaultData()));
	}

	private createForm(): void {
		this.formGroup = new FormGroup({
			[Form.source]: new FormControl(undefined, [Validators.required]),
			[Form.target]: new FormControl(undefined, [Validators.required]),
			[Form.mappingDataList]: new FormControl([], []),
			[Form.comment]: new FormControl(undefined, [Validators.maxLength(200)]),
			[Form.protocol]: new FormControl(undefined, []),
			[Form.format]: new FormControl(undefined, []),
			[Form.exchange_frequency]: new FormControl(undefined, []),
			[Form.port_type]: new FormControl(undefined, []),
			[Form.port]: new FormControl(undefined, [Validators.pattern(PORTS_RANGE_REGEXP)]),
			[Form.flow_encryption]: new FormControl(undefined, []),
			[Form.fileLinkIds]: new FormControl([], []),
		});
		this.searchSourceControl = new FormControl('');
		this.subscription.add(this.searchSourceControl.valueChanges
			.subscribe(text => this.filterSourceApplicationList(text)));
		this.searchTargetControl = new FormControl('');
		this.subscription.add(this.searchTargetControl.valueChanges
			.subscribe(text => this.filterTargetApplicationList(text)));
		this.searchDataControl = new FormControl('');
		this.subscription.add(this.searchDataControl.valueChanges
			.subscribe(text => this.filterMappingDataList(text)));
		this.searchProtocolControl = new FormControl('');
		this.searchFormatControl = new FormControl('');
		this.subscription.add(this.searchProtocolControl.valueChanges
			.subscribe(text => this.filterProtocolList(text)));
		this.subscription.add(this.searchFormatControl.valueChanges
			.subscribe(text => this.filterFormatList(text)));
	}

	private buildSelectionLists(): void {
		this.protocolTypeChoices = [
			{id: ProtocolType.HTTP, name: 'HTTP'},
			{id: ProtocolType.FTP, name: 'FTP'},
			{id: ProtocolType.SFTP, name: 'SFTP'},
			{id: ProtocolType.MQTT, name: 'MQTT'},
			{id: ProtocolType.AMQP, name: 'AMQP'},
			{id: ProtocolType.gRPC, name: 'gRPC'},
			{id: ProtocolType.SMB, name: 'SMB'},
			{id: ProtocolType.NFS, name: 'NFS'},
			{id: ProtocolType.LDAP, name: 'LDAP'},
			{id: ProtocolType.CIFS, name: 'CIFS'},
			{id: ProtocolType.RTSP, name: 'RTSP'},
			{id: ProtocolType.DICOM, name: 'DICOM'},
			{id: ProtocolType.HL7, name: 'HL7'},
			{id: ProtocolType.FHIR, name: 'FHIR'},
			{id: ProtocolType.XDS, name: 'XDS'},
			{id: ProtocolType.IHE, name: 'IHE'},
			{id: ProtocolType.CDA, name: 'CDA'},
			{id: ProtocolType.NCPDP, name: 'NCPDP'},
			{id: ProtocolType.CCDA, name: 'CCDA'},
			{id: ProtocolType.AS2, name: 'AS2'},
			{id: ProtocolType.EDI, name: 'EDI'},
			{id: ProtocolType.SEPA, name: 'SEPA'},
			{id: ProtocolType.EBICS, name: 'EBICS'},
		];
		this.protocolFilteredList = this.protocolTypeChoices;

		this.formatTypeChoices = [
			{id: FormatType.JSON, name: 'JSON'},
			{id: FormatType.CSV, name: 'CSV'},
			{id: FormatType.XML, name: 'XML'},
			{id: FormatType.YAML, name: 'YAML'},
			{id: FormatType.PLAIN_TEXT, name: 'Plain text'},
			{id: FormatType.HTML, name: 'HTML'},
			{id: FormatType.BINARY, name: this.translate.instant('page.appDetails.architecture.dialog.binary')},
		];
		this.formatFilteredList = this.formatTypeChoices;

		this.exchangeFrequencyTypeChoices = [
			{id: ExchangeFrequencyType.REAL_TIME, name: this.translate.instant('page.appDetails.architecture.dialog.realTime')},
			{id: ExchangeFrequencyType.DAILY, name: this.translate.instant('page.appDetails.architecture.dialog.daily')},
			{id: ExchangeFrequencyType.WEEKLY, name: this.translate.instant('page.appDetails.architecture.dialog.weekly')},
			{id: ExchangeFrequencyType.MONTHLY, name: this.translate.instant('page.appDetails.architecture.dialog.monthly')},
		];

		this.portTypeChoices = [
			{id: 'tcp', name: 'TCP'},
			{id: 'udp', name: 'UDP'},
		];

		this.flowEncryptionTypeChoices = [
			{id: true, name: this.translate.instant('page.appDetails.architecture.dialog.yes')},
			{id: false, name: this.translate.instant('page.appDetails.architecture.dialog.no')},
		];
	}

	private setApplicationMappingFormData(applicationList: ApplicationGeneric[], mappingDataList: ApplicationMappingData[]): void {
		this.isSource = !this.data.defaultData || this.data.defaultData.source.id === this.data.applicationId;
		this.applicationList = applicationList;
		this.mappingDataList = mappingDataList;
	}

	private setDefaultData(): void {
		if (!!this.data.defaultData) {
			const source: ApplicationGeneric|undefined = this.applicationList.find(a => a.id === this.data.defaultData!.source.id);
			this.formGroup.get(Form.source)!.setValue(source);
			const target: ApplicationGeneric|undefined = this.applicationList.find(a => a.id === this.data.defaultData!.target.id);
			this.formGroup.get(Form.target)!.setValue(target);
			const dataList: ApplicationMappingData[] = this.mappingDataList.filter(m => this.data.defaultData!.mappingDataList.map(d => d.dataId).includes(m.dataId));
			this.formGroup.get(Form.mappingDataList)!.setValue(dataList);
			this.formGroup.get(Form.comment)!.setValue(this.data.defaultData.description);
			this.formGroup.get(Form.protocol)!.setValue(this.protocolTypeChoices.find(p => p.id === this.data.defaultData?.protocol));
			this.formGroup.get(Form.format)!.setValue(this.formatTypeChoices.find(f => f.id === this.data.defaultData?.format));
			this.formGroup.get(Form.exchange_frequency)!.setValue(this.exchangeFrequencyTypeChoices.find(e => e.id === this.data.defaultData?.exchangeFrequency));
			this.formGroup.get(Form.port_type)!.setValue(this.portTypeChoices.find(p => p.id === this.data.defaultData?.portType));
			this.formGroup.get(Form.port)!.setValue(this.data.defaultData.port);
			this.formGroup.get(Form.flow_encryption)!.setValue(this.flowEncryptionTypeChoices.find(f => f.id === this.data.defaultData?.flowEncryption));
			this.formGroup.get(Form.fileLinkIds)!.setValue(this.data.defaultData.documents.map(f => f.fileLinkId));
		} else {
			this.formGroup.get(Form.source)!.setValue(this.applicationList.find(a => a.id === this.data.applicationId));
		}
		this.initializeFilteredList();
	}

	toggleDirection(): void {
		this.isSource = !this.isSource;
		this.iconReversed = !this.iconReversed;
		const source: ApplicationGeneric|undefined = this.sourceFormValue;
		const target: ApplicationGeneric|undefined = this.targetFormValue;
		this.formGroup.get(Form.source)!.setValue(target);
		this.formGroup.get(Form.target)!.setValue(source);
		this.initializeFilteredList();
	}

	private initializeFilteredList(): void {
		this.filterSourceApplicationList();
		this.filterTargetApplicationList();
		this.filterMappingDataList()
	}

 	private filterSourceApplicationList(filter?: string): void {
		this.sourceFilteredList = this.getFilteredApplicationList(true, filter);
	}

	private filterTargetApplicationList(filter?: string): void {
		this.targetFilteredList = this.getFilteredApplicationList(false, filter);
	}

	private getFilteredApplicationList(filterSource: boolean, filter?: string): ApplicationGeneric[] {
		return this.applicationList
			.filter(app => (((filterSource && this.isSource) || (!filterSource && !this.isSource))
					&& app.id === this.data.applicationId)
				|| (((filterSource && !this.isSource) || (!filterSource && this.isSource))
					&& app.id !== this.data.applicationId
					&& (!filter || app.name.toLowerCase().includes(filter.toLowerCase()))))
			.sort((a, b) => a.name.localeCompare(b.name))
	}

	private filterMappingDataList(filter?: string): void {
		this.mappingDataFilteredList = this.mappingDataList
			.filter(app => !filter || app.name.toLowerCase().includes(filter.toLowerCase()))
			.sort((a, b) => a.name.localeCompare(b.name));
	}

	private filterProtocolList(filter?: string): void {
		this.protocolFilteredList = this.protocolTypeChoices
			.filter(p => !filter || p.name.toLowerCase().includes(filter.toLowerCase()));
	}

	private filterFormatList(filter?: string): void {
		this.formatFilteredList = this.formatTypeChoices
			.filter(f => !filter || f.name.toLowerCase().includes(filter.toLowerCase()));
	}

	createData(newDataFlow: string): void {
		const form: ApplicationMappingDataForm = {
			name: newDataFlow
		};
		this.switchSavingData()
			.pipe(
				switchMap(() => this.applicationMappingService.createApplicationMappingData(this.data.tenantId, form)),
				finalize(() => this.switchSavingData()))
			.subscribe(dataId => {
				const newData: ApplicationMappingData = {
					dataId: dataId,
					name: newDataFlow
				};
				this.mappingDataList.push(newData);
				const values: ApplicationMappingData[] = [...this.formGroup.get(Form.mappingDataList)!.value, newData];
				this.formGroup.get(Form.mappingDataList)!.setValue(values);
				this.searchDataControl.reset('');
			});
	}

	save(): void {
		this.switchSaving()
			.pipe(
				map(() => this.buildApplicationMappingForm()),
				switchMap(form => !this.data.defaultData
					? this.applicationMappingService.createApplicationMapping(this.data.tenantId, form).pipe(map(() => true))
					: this.applicationMappingService.updateApplicationMapping(this.data.tenantId, this.data.defaultData.flowId, form)),
				finalize(() => this.switchSaving()))
			.subscribe(updated => this.saved.emit(updated));
	}

	private buildApplicationMappingForm(): ApplicationMappingForm {
		return {
			sourceId: this.sourceFormValue!.id,
			targetId: this.targetFormValue!.id,
			mappingDataList: this.mappingDataFormValue.map(d => d.dataId),
			description: this.formGroup.get(Form.comment)!.value,
			protocol: (this.protocolFormControl.value as ProtocolTypeChoice)?.id,
			format: (this.formatFormControl.value as FormatTypeChoice)?.id,
			exchangeFrequency: (this.exchangeFrequencyFormControl.value as ExchangeFrequencyTypeChoice)?.id,
			portType: (this.portTypeFormControl.value as PortTypeChoice)?.id,
			port: this.portFormControl.value,
			flowEncryption: (this.flowEncryptionFormControl.value as FlowEncryptionTypeChoice)?.id,
			documents: this.formGroup.get(Form.fileLinkIds)!.value,
		}
	}

	get sourceFormControl(): FormControl {
		return this.formGroup.get(Form.source)! as FormControl;
	}

	get targetFormControl(): FormControl {
		return this.formGroup.get(Form.target)! as FormControl;
	}

	get mappingDataFormControl(): FormControl {
		return this.formGroup.get(Form.mappingDataList)! as FormControl;
	}

	get sourceFormValue(): ApplicationGeneric|undefined {
		return this.formGroup.get(Form.source)!.value;
	}

	get targetFormValue(): ApplicationGeneric|undefined {
		return this.formGroup.get(Form.target)!.value;
	}

	get mappingDataFormValue(): ApplicationMappingData[] {
		return this.formGroup.get(Form.mappingDataList)!.value;
	}

	get protocolFormControl(): FormControl {
		return this.formGroup.get(Form.protocol)! as FormControl;
	}

	get formatFormControl(): FormControl {
		return this.formGroup.get(Form.format)! as FormControl;
	}

	get exchangeFrequencyFormControl(): FormControl {
		return this.formGroup.get(Form.exchange_frequency)! as FormControl;
	}

	get portTypeFormControl(): FormControl {
		return this.formGroup.get(Form.port_type)! as FormControl;
	}

	get portFormControl(): FormControl {
		return this.formGroup.get(Form.port)! as FormControl;
	}

	get flowEncryptionFormControl(): FormControl {
		return this.formGroup.get(Form.flow_encryption)! as FormControl;
	}

	get dataSelectorName(): string {
		return this.mappingDataFormValue.length > 0
			? (this.mappingDataFormValue[0]!.name
				+ (this.mappingDataFormValue.length == 1 ? '' : ' (+ ' + (this.mappingDataFormValue.length - 1) + ')'))
			: '-';
	}

	private switchInitializing(): Observable<{}> {
		this._initializing = !this._initializing;
		return of({});
	}

	private switchSaving(): Observable<{}> {
		this._saving = !this._saving;
		return of({});
	}

	private switchSavingData(): Observable<{}> {
		this._savingData = !this._savingData;
		return of({});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}

export interface ApplicationMappingApplicationFormData {
	tenantId: string;
	applicationId: string;
	defaultData?: ApplicationMapping;
	preloaded?: {
		applicationList: ApplicationGeneric[];
		mappingDataList: ApplicationMappingData[];
	}
}

enum Form {
	source = 'source',
	target = 'target',
	mappingDataList = 'mappingDataList',
	comment = 'comment',
	protocol = 'protocol',
	format = 'format',
	exchange_frequency = 'exchange_frequency',
	port_type = 'port_type',
	port = 'port',
	flow_encryption = 'flow_encryption',
	fileLinkIds = 'fileLinkIds',
}

interface ProtocolTypeChoice {
	id: ProtocolType;
	name: string;
}

interface FormatTypeChoice {
	id: FormatType;
	name: string;
}

interface ExchangeFrequencyTypeChoice {
	id: ExchangeFrequencyType;
	name: string;
}

interface PortTypeChoice {
	id: 'tcp'|'udp';
	name: string;
}

interface FlowEncryptionTypeChoice {
	id: boolean;
	name: string;
}
