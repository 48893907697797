import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-application-settings',
  standalone: false,
  templateUrl: './application-settings.component.html',
  styleUrls: ['./application-settings.component.scss']
})
export class ApplicationSettingsComponent {
}
