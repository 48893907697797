import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TopbarService} from "src/app/services/front/topbar.service";
import {TranslateService} from "@ngx-translate/core";
import {RightSliderService} from "src/app/services/front/right-slider.service";
import {HomePageDrawerComponent} from "./home-page-drawer/home-page-drawer.component";
import {finalize, Observable, of, Subject, Subscription, tap} from "rxjs";
import {AuthenticationService, UserInfo} from "../../../services/authentication.service";
import {DateTranslateRef, DateUtils} from "../../../utils/date.utils";
import {HomePageLogHistoryComponent} from "./home-page-log-history/home-page-log-history.component";
import {
	CatalogComponent as CatalogV2Component,
	CatalogDialogResult
} from "../applications/catalog-v2/catalog.component";
import {InviteUsersComponent} from "../settings/users/invite-users.component";
import {
	ApplicationDetailComponent,
	ApplicationDetailInput
} from "../applications/application-detail/application-detail.component";
import {filter, switchMap} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {APPLICATIONS_URL, INFRASTRUCTURE_SERVERS_URL} from "src/app/models/home/navigation.model";
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {Notification, NotificationService, NotificationType} from "src/app/services/notification.service";
import {AnnouncementComponent} from "../announcement/announcement.component";
import {SnackbarService} from "src/app/services/front/snackbar.service";
import {ActivityLogService} from 'src/app/services/back/activity-log.service';
import {ApplicationActivityLog, sortApplicationActivityLogs} from 'src/app/services/model/activity-log.model';
import {AddEquipmentComponent, EquipmentMenu} from "../infrastructure/add-equipment/add-equipment.component";
import {NewsService} from "../../../services/front/news.service";
import * as Bowser from "bowser";
import {AccountService} from "../../../services/account.service";

@Component({
	selector: 'app-home-page',
	standalone: false,
	templateUrl: './home-page.component.html',
	styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {

	constructor(private authenticationService: AuthenticationService,
				private currentTenantService: CurrentTenantService,
				private rightSliderService: RightSliderService,
				private activityLogService: ActivityLogService,
				private topbarService: TopbarService,
				private translate: TranslateService,
				private dialog: MatDialog,
				private router: Router,
				private accountService: AccountService,
				private notificationService: NotificationService,
				private newsService: NewsService,
				private snackbarService: SnackbarService) {
	}

	tenantId: string;
	userAccount: UserInfo | null;

	subscription: Subscription = new Subscription();

	logs: ApplicationActivityLog[] = [];

	loading: boolean;

	@ViewChild('carouselElt')
	scrollContainer: ElementRef;

	leftScroll: boolean = false;
	rightScroll: boolean = true;

	hovered: boolean = false;

	notifications: Notification[];

	showNewsModal = true;
	newsAvailable = false;

	unread = 0;

	getDateGap(date: string): string {
		let translateRef: DateTranslateRef | null = DateUtils.getDateTranslateRef(date)
		return !!translateRef
			? this.translate.instant('page.home.homepage.history.date.' + translateRef.translateRef, {value: translateRef.value})
			: '-'
	}

	tipCarousels: TipCarousel[] = [
		{
			title: 'beginWithKabeen',
			cards: [
				{
					id: 'firstStep',
					picture: 'assets/illustrations/home-tips/start.svg',
					video: '459647fb664e4b68b17afa295e7d23a0',
					sid: '1fa6f0bc-b669-4b46-955d-f164bf5a57c2',
					documentation: 'https://help.kabeen.io/fr/collections/8544893-bien-demarrer-sur-kabeen',
					listSize: 2
				}, {
					id: 'organizationModelization',
					picture: 'assets/illustrations/home-tips/modelization.svg',
					video: '18a690bd7ca6413598bec23456c171d9',
					sid: 'b6c0c5c6-eae3-4061-83be-d46bc1327fb8',
					documentation: 'https://help.kabeen.io/fr/articles/9003759-modelisation-de-l-organisation',
					listSize: 3
				}, {
					id: 'applicationAdd',
					picture: 'assets/illustrations/home-tips/application.svg',
					video: '3b0d4509a6b04b648cf237d7ec259f03',
					sid: '02f8d439-402a-4330-954c-1f5735f71030',
					documentation: 'https://help.kabeen.io/fr/articles/9173076-ajouter-une-application',
					listSize: 2
				}, {
					id: 'roverDeploy',
					picture: 'assets/illustrations/home-tips/rover.svg',
					video: undefined,
					sid: '',
					documentation: 'https://help.kabeen.io/fr/collections/5446894-extension-de-navigateur',
					listSize: 2
				}, {
					id: 'kapsulDeploy',
					picture: 'assets/illustrations/home-tips/kapsul.svg',
					video: undefined,
					sid: '',
					documentation: 'https://help.kabeen.io/fr/collections/3331016-deploiement-de-l-agent-kapsul',
					listSize: 1
				}
			]
		}
	]

	fastActions: FastAction[] = [
		{
			icon: "assets/icons/fast_action/application-add.svg",
			id: FastActionType.APPLICATION
		},
		{
			icon: "assets/icons/fast_action/instance-add.svg",
			id: FastActionType.INSTANCE
		},
		{
			icon: "assets/icons/fast_action/user-add.svg",
			id: FastActionType.USER
		},
		{
			icon: "assets/icons/fast_action/bullhorn.svg",
			id: FastActionType.ANNOUNCEMENT
		}
	]

	ngOnInit(): void {
		this.topbarService.onTitleChange(this.translate.instant('menu.home'), this.translate.instant('menu.subtitle.home'));
		this.userAccount = this.authenticationService.getUserInfo();
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(tenantId => this.getNotifications(tenantId)));
		this.subscription.add(this.currentTenantService.getApplicationCountChanges()
			.subscribe(() => this.fetchLogs(this.tenantId)));
		if (this.newsService.unseenNews.length > 0) {
			this.subscription.add(this.accountService.getSignupDate()
				.subscribe(signupDate => {
					this.newsAvailable = this.newsService.unseenNews.some(news => new Date(signupDate) < news.creationDate);
				}));
		} else {
			this.newsAvailable = false;
		}
	}

	ngAfterViewInit(): void {
		const isMobile = Bowser.getParser(window.navigator.userAgent).getPlatform().type === 'mobile';
		const mobileAppBannerDismissed = sessionStorage.getItem('mobileAppBannerDismissed');
		if (isMobile) {
			if (!mobileAppBannerDismissed) {
				this.showNewsModal = false;
			}
		}
	}

	getNotifications(tenantId: string) {
		this.unread = 0;
		this.subscription.add(this.notificationService.getNotifications(tenantId).subscribe(notifications => {
			notifications.notifications
				.filter(n => n.type !== NotificationType.COMMENT_ADDED)
				.forEach(n => {
					if (n.status === 'unread') {
						this.unread++;
					}
				})
		}))
	}

	private fetchLogs(tenantId: string): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				switchMap(() => this.activityLogService.getAllTenantActivityLog(tenantId)),
				tap(logs => this.logs = logs.sort(sortApplicationActivityLogs)),
				finalize(() => this.switchLoading()))
			.subscribe());
	}

	switchLoading(): Observable<{}> {
		this.loading = !this.loading;
		return of({});
	}

	onOpenTipDrawer(carousel: TipCarousel, tipIndex: number) {
		const outputEvent$ = new Subject<any>();

		this.subscription.add(
			outputEvent$.subscribe(() => this.rightSliderService.close())
		);

		this.rightSliderService.openComponent(HomePageDrawerComponent, {
			carousel: carousel,
			tipIndex: tipIndex
		}, outputEvent$);
	}

	onOpenHistoryDrawer() {
		const outputEvent$ = new Subject<any>();

		this.subscription.add(
			outputEvent$.subscribe(() => this.rightSliderService.close())
		);
		this.rightSliderService.openComponent(HomePageLogHistoryComponent, outputEvent$);
	}

	goTo(log: ApplicationActivityLog) {
		this.goToApp(log.application.id);
	}

	goToApp(appId: string) {
		const data: ApplicationDetailInput = {
			applicationId: appId
		};
		this.rightSliderService.openComponent(ApplicationDetailComponent, data);
	}

	openFastActionDrawer(fastActionId: FastActionType) {
		switch (fastActionId) {
			case FastActionType.APPLICATION:
				this.dialog.open<CatalogV2Component, any, CatalogDialogResult>(CatalogV2Component).afterClosed()
					.pipe(
						filter(result => !!result?.success))
					.subscribe(() => this.router.navigate([APPLICATIONS_URL]));
				break;
			case FastActionType.INSTANCE:
				this.dialog.open(AddEquipmentComponent, { data: {menu: EquipmentMenu.SERVER } })
					.afterClosed()
					.pipe(
						filter((result) => !!result),
						tap(() => this.router.navigate([INFRASTRUCTURE_SERVERS_URL]))
					).subscribe();
				break;
			case FastActionType.USER:
				this.dialog.open(InviteUsersComponent);
				break;
			case FastActionType.ANNOUNCEMENT:
				this.dialog.open(AnnouncementComponent, {
					data: {
						announcement: undefined,
						appId: undefined
					}
				}).afterClosed().subscribe((res: { success: boolean }) => {
					if (res?.success) {
						this.snackbarService.show(this.translate.instant('global.announcements.announcementCreated'))
					}
				});
				break;
		}
	}

	goLeft() {
		try {
			this.scrollContainer.nativeElement.scrollLeft = 0;
			this.leftScroll = false;
			this.rightScroll = true;
		} catch (err) {
		}
	}

	goRight() {
		try {
			this.scrollContainer.nativeElement.scrollLeft = this.scrollContainer.nativeElement.scrollWidth;
		} catch (err) {
		}
	}

	onScroll(event: any) {
		const leftPos = event.target.scrollLeft;
		this.leftScroll = leftPos > 0;
		this.rightScroll = leftPos < event.target.scrollWidth - event.target.clientWidth;
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}

export interface TipCarousel {
	title: string,
	cards: TipCard[]
}

export interface TipCard {
	id: string,
	picture: string,
	video?: string,
	sid: string,
	documentation?: string,
	listSize: number
}

export interface FastAction {
	icon: string,
	id: FastActionType
}

enum FastActionType {
	APPLICATION = 'application',
	INSTANCE = 'instance',
	USER = 'user',
	ANNOUNCEMENT = 'announcement'
}
