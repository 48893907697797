import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {SnackbarService} from "src/app/services/front/snackbar.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-copy-text',
	standalone: false,
  templateUrl: './copy-text.component.html',
  styleUrls: ['./copy-text.component.scss']
})
export class CopyTextComponent {
	constructor(
		private snackbarService: SnackbarService,
		private translateService: TranslateService
	) {}

	@Input() text: string;

	onCopy() {
		this.snackbarService.show(this.translateService.instant('modules.copyText.snackbar'))
	}
}
