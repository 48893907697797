import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'applyScale',
  standalone: false,
  pure: true
})
export class ApplyScalePipe implements PipeTransform {
  transform(value: number, scale: number, minus: number = 0): any {
    return (value * scale) - minus;
  }
}
