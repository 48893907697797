<div class="sticky-element">
	<div style="padding-top: 20px;padding-left: 20px;padding-right: 20px">
		<div [class.tab-box-shadow]="isStickyElementPinned" class="tabs-container pad-XS flex-align-center gap-S" style="z-index: 999">
			<div *ngFor="let tab of tabs" [ngClass]="{'selected': tab.item.isVisible(), 'badge-background': tab.badge() }" (click)="displayTab(tab)" class="c-p single-tab flex-align-center pad-left-S p-relative">
				<div class="flex-align-center gap-S">
					<img width="14" *ngIf="tab.icon()" [src]="'assets/tabs/' + tab.icon() + '.svg'" [ngClass]="{'filter-primary': tab.item.isVisible(), 'filter-grey': !tab.item.isVisible() }" />
					<span>{{ tab.label() }}</span>
				</div>
				<div class="tab-badge flex-center" *ngIf="tab.badge() != undefined">
					<span class="font-S fw-500">{{ tab.badge() }}</span>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-content></ng-content>
