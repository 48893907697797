import {Component, Input} from '@angular/core';

@Component({
	selector: 'app-chips-text',
	standalone: false,
	templateUrl: './chips-text.component.html',
	styleUrls: ['./chips-text.component.scss']
})
export class ChipsTextComponent {
	@Input() color: 'grey' | 'primary' | 'accent' | 'accent-secondary' | 'warn' | 'success' | 'warning' | 'danger' = 'grey';
	@Input() warning?: string = undefined;

	private _bold: boolean;
	@Input()
	get bold() {
		return this._bold;
	}

	set bold(value: any) {
		this._bold = value != null && `${value}` !== 'false';
	}
}
