import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApplicationDocumentationStatistic, ApplicationInstanceService} from 'src/app/services/application-instance.service';
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {ApplicationCompliance, Compliance} from 'src/app/services/tenant.service';
import {NewApplicationService} from 'src/app/services/back/new-application.service';

@Component({
	selector: 'app-architecture-tab',
	standalone: false,
	templateUrl: './architecture-tab.component.html',
	styleUrls: ['./architecture-tab.component.scss']
})
export class ArchitectureTabComponent implements OnInit, OnDestroy {

	tenantId: string;
	applicationId: string;

	_initializing: boolean;
	_loading: boolean;

	docStatistic?: ApplicationDocumentationStatistic;
	complexity?: Compliance;
	obsolescence?: Compliance;

	subscription: Subscription = new Subscription();

	constructor(private applicationDetailService: ApplicationDetailService,
							private newApplicationService: NewApplicationService,
							private applicationService: ApplicationInstanceService) {
	}

	ngOnInit(): void {
		this.subscription.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	private setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.applicationId = data.instance.applicationId;
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				switchMap(() => forkJoin([
					this.fetchApplicationDocumentationStatistic(),
					this.fetchApplicationCompliance()
				])),
				finalize(() => this.switchLoading()))
			.subscribe());
	}

	private fetchApplicationDocumentationStatistic(): Observable<ApplicationDocumentationStatistic> {
		// TODO @TAN getApplicationDocumentationPercent only when needed see KB-646
		return this.applicationService.getApplicationDocumentationStatistic(this.tenantId, this.applicationId)
			.pipe(tap(doc => this.docStatistic = doc));
	}

	private fetchApplicationCompliance(): Observable<ApplicationCompliance> {
		return this.newApplicationService.getApplicationCompliance(this.tenantId, this.applicationId)
			.pipe(tap(arch => {
				this.complexity = arch.complexity;
				this.obsolescence = arch.obsolescence;
			}));
	}

	refreshComplianceIndicators(): void {
		this.fetchApplicationCompliance().subscribe();
	}

	refreshDocumentationIndicator(): void {
		this.fetchApplicationDocumentationStatistic().subscribe();
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
