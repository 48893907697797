<div class="flex-column gap-M pad-L">
	<!-- GENERAL PANEL -->
	<div class="expansion-panel" [class.opened]="openGeneral">
		<div class="flex-align-center flex-space-between c-p pad-M greyed-hover" style="border-radius: 10px;" matRipple
			 (click)="openGeneral = !openGeneral">
			<div class="font-MM fw-700 primary">{{'menu.general'|translate}}</div>
			<img src="assets/icons/arrow-right.svg" style="margin-right: 10px;" alt=""
				 [class.img-close]="!openGeneral" [class.img-open]="openGeneral"/>
		</div>
		<div class="pad-M mt-l" [hidden]="!openGeneral">
			<app-organization-setting-general></app-organization-setting-general>
		</div>
	</div>
</div>
