import {Component, Input} from "@angular/core";

@Component({
	selector: 'app-tab',
	standalone: false,
	styleUrls: ['./tab.component.scss'],
	templateUrl: './tab.component.html'
})
export class TabComponent {
	@Input() label: string;
	@Input() icon?: string;
	@Input()
	set badge(value: string | undefined) {
		this._badge = value;
	}

	_badge: string | undefined;

	public visible = false;

	public get tabBadge() { return this._badge }

	public get tabIcon() { return this.icon }

	public get tabLabel() { return this.label }

	public display() {
		this.visible = true;
	}

	public hide() {
		this.visible = false;
	}

	public isVisible() {
		return this.visible;
	}
}
