import {inject, Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {NetworkRole, Server, SubnetMask} from "../model/resource.model";
import {Observable} from "rxjs";
import {SecurityUtils} from "../security.utils";
import {catchError} from "rxjs/operators";
import {HttpClientUtils} from "../../utils/http-client.utils";
import {ApplicationGeneric} from "../model/new-application.model";
import {CriticalityLevel} from "../tenant.service";
import {Router} from "./router.service";

@Injectable({
	providedIn: 'root'
})
export class NetworkService {

	constructor() {
	}

	private backendUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/networks`;

	private httpClient = inject(HttpClient);

	create(tenantId: string, body: NetworkForm): Observable<boolean> {
		return this.httpClient.post<boolean>(`${this.backendUrl(tenantId)}`, body, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	update(tenantId: string, networkId: string, body: NetworkForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.backendUrl(tenantId)}/${networkId}`, body, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	delete(tenantId: string, networkId: string): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${this.backendUrl(tenantId)}/${networkId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getNetwork(tenantId: string, networkId: string): Observable<Network> {
		return this.httpClient.get<Network>(`${this.backendUrl(tenantId)}/${networkId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getTenantNetworkCount(tenantId: string): Observable<number> {
		return this.httpClient.get<number>(`${this.backendUrl(tenantId)}/count`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, 0)));
	}

	getTenantNetworksTableData(tenantId: string): Observable<NetworkTableData[]> {
		return this.httpClient.get<NetworkTableData[]>(`${this.backendUrl(tenantId)}/table-data`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getTenantNetworks(tenantId: string): Observable<Network[]> {
		return this.httpClient.get<Network[]>(`${this.backendUrl(tenantId)}`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getNetworkOverview(tenantId: string, networkId: string): Observable<NetworkOverview> {
		return this.httpClient.get<NetworkOverview>(`${this.backendUrl(tenantId)}/${networkId}/overview`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	updateNetworkServerList(tenantId: string, networkId: string, servers: {
		serverId: string,
		selected: boolean
	}[]): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.backendUrl(tenantId)}/${networkId}/servers`, servers, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getNetworkRouters(tenantId: string, networkId: string): Observable<Router[]> {
		return this.httpClient.get<Router[]>(`${this.backendUrl(tenantId)}/${networkId}/routers`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	updateNetworkRouterList(tenantId: string, networkId: string, routers: {
		routerId: string,
		selected: boolean
	}[]): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.backendUrl(tenantId)}/${networkId}/routers`, routers, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}
}

export interface NetworkForm {
	name: string,
	role: NetworkRole,
	ipAddress: string,
	subnet: SubnetMask,
	description?: string,
	vlanId?: string
}

export interface NetworkTableData {
	network: Network,
	servers: Server[],
	applications: ApplicationGeneric[]
}

export interface Network {
	id: string,
	name: string,
	role: NetworkRole,
	ipAddress: string,
	subnet: SubnetMask,
	description: string | null,
	vlanId: string | null
}

export interface NetworkOverview {
	servers: NetworkServer[],
	hasFirewall: boolean,
	ipPoolPercentage: number,
	tooMuchServers: boolean,
}

export interface NetworkServer {
	server: Server,
	criticality: CriticalityLevel | null
}

export interface NetworkServers {
	network: Network,
	servers: Server[]
}
