import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ApplicationDetailComponent, ApplicationDetailInput} from '../../applications/application-detail/application-detail.component';
import {RightSliderService} from 'src/app/services/front/right-slider.service';
import {ApplicationContractDeadline, ContractType} from 'src/app/services/model/application-contract.model';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {TenantFinanceService} from 'src/app/services/back/tenant-finance.service';
import {finalize, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {Organization} from 'src/app/services/organization.service';
import {ApplicationDeadlinesDrawerComponent, ApplicationDeadlinesDrawerData} from 'src/app/modules/home/finance-dashboard/application-deadlines/drawer/application-deadlines-drawer.component';
import {ExportService, ExportType} from 'src/app/services/front/export.service';
import {ColumnDefinition} from "../../../design-system/table/table.component";
import {ApplicationUsageRating} from "../../../../services/usage.service";

@Component({
  selector: 'app-application-deadlines',
	standalone: false,
  templateUrl: './application-deadlines.component.html',
  styleUrls: ['./application-deadlines.component.scss']
})
export class ApplicationDeadlinesComponent implements OnInit, OnDestroy {

	@Input() defaultFilter?: Organization|null|undefined;
	@Input() filter?: Subject<Organization|null|undefined>;
	@Input() search?: Observable<string|null|undefined>;
	@Input() export?: Subject<ExportType>;
	@Input() limit?: number;
	@Input() type: 'widget'|'drawer' = 'widget';

	tenantId: string;
	lastFilter?: Organization|null;

	_initializing: boolean;
	_loading: boolean;

	applications: ApplicationContractDeadline[] = [];
	filteredApplications: ApplicationContractDeadline[] = [];
	types: typeof ContractType = ContractType;

	columnDefinitions: ColumnDefinition<ApplicationContractDeadline>[];

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	@ViewChild('applicationTemplate', { static: true }) applicationTemplate: TemplateRef<any>;
	@ViewChild('contractTitleTemplate', { static: true }) contractTitleTemplate: TemplateRef<any>;
	@ViewChild('expiryDateTemplate', { static: true }) expiryDateTemplate: TemplateRef<any>;

	constructor(private currentTenantService: CurrentTenantService,
							private tenantFinanceService: TenantFinanceService,
							private rightSliderService: RightSliderService,
							private exportService: ExportService,
							private translate: TranslateService) {
	}

	ngOnInit() {
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize(this.defaultFilter)));
		if (this.filter) {
			this.subscription.add(this.filter
				.pipe(tap(filter => this.lastFilter = filter))
				.subscribe(filter => this.initialize(filter)));
		}
		if (this.search) {
			this.subscription.add(this.search
				.subscribe(search => this.filterApplications(search!)));
		}
		if (this.export) {
			this.subscription.add(this.export
				.subscribe(type => this.exportData(type)));
		}
	}

	initialize(org?: Organization|null): void {
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				tap(() => this.setColumnDefinitions()),
				switchMap(() => this.tenantFinanceService.getAllSoonExpiredApplicationContract(this.tenantId, org?.organizationId, this.limit)),
				tap(data => this.applications = data),
				tap(data => this.filteredApplications = data),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	setColumnDefinitions(): void {
		this.columnDefinitions = [
			{
				name: 'application',
				label: 'global.table.application.name',
				template: this.applicationTemplate,
				sortFunction: (a, b) => a.application.name.localeCompare(b.application.name),
				width: 40,
			},
			{
				name: 'type',
				label: 'global.table.contract.title',
				template: this.contractTitleTemplate,
				sortFunction: (a, b) => a.type.localeCompare(b.type),
			},
			{
				name: 'expiryDate',
				label: 'global.table.contract.expiryDate',
				template: this.expiryDateTemplate,
				sortFunction: (a, b) => {
					const dateA = new Date(a.expiryDate);
					const dateB = new Date(b.expiryDate);
					return dateA.getTime() - dateB.getTime();
				},
			}
		];
	}

	filterApplications(search?: string|null): void {
		this.filteredApplications = this.applications
			.filter(app => !search || app.application.name.toLowerCase().includes(search.toLowerCase()));
	}

	getTimeDiff(expiryDate: string): string {
		const lastUpdate = Math.round((new Date(new Date(Date.parse(expiryDate)).toDateString()).getTime() - new Date(new Date().toDateString()).getTime()) / (1000 * 60 * 60 * 24))

		if (lastUpdate || lastUpdate === 0) {
			if (lastUpdate === 0) {
				const translation: string = this.translate.instant('global.today')
				return translation.charAt(0).toUpperCase() + translation.slice(1);
			} else if (lastUpdate === 1) {
				const translation: string = this.translate.instant('global.tomorrow')
				return translation.charAt(0).toUpperCase() + translation.slice(1);
			} else if (lastUpdate < 4) {
				const translation: string = this.translate.instant('global.in')
				return translation.charAt(0).toUpperCase() + translation.slice(1) + ' ' + lastUpdate + ' ' + this.translate.instant('page.appDetails.day', { plural: lastUpdate <= 1 ? '' : 's'});
			} else {
				return new Date(expiryDate).toLocaleDateString()
			}
		} else {
			return '-'
		}
	}

	getColor(expiryDate: string): 'danger'|'warning'|'secondary' {
		// get the number of days between today and the expiry date
		const lastUpdate = Math.round((new Date(new Date(Date.parse(expiryDate)).toDateString()).getTime() - new Date(new Date().toDateString()).getTime()) / (1000 * 60 * 60 * 24))

		if (lastUpdate || lastUpdate === 0) {
			if (lastUpdate == 0) {
				return 'danger';
			} else if (lastUpdate == 1) {
				return 'warning'
			} else {
				return 'secondary'
			}
		} else {
			return 'secondary'
		}
	}

	goToApp(applicationInstanceId: string): void {
		const data: ApplicationDetailInput = {
			applicationId: applicationInstanceId
		};
		this.rightSliderService.openComponent(ApplicationDetailComponent, data)
			.subscribe(() => this.filter?.next(this.lastFilter));
	}

	openDrawer(): void {
		if (this.type === 'widget' && this.applications.length > 0) {
			const data: ApplicationDeadlinesDrawerData = {
				filter: this.lastFilter
			};
			this.rightSliderService.openComponent(ApplicationDeadlinesDrawerComponent, data);
		}
	}

	exportData(type: ExportType): void {
		const rows: ApplicationDeadlinesCsv[] = this.filteredApplications.map(a => ({
			name: a.application.name,
			type: this.translate.instant('page.appDetails.finance.contracts.' + a.type),
			expiryDate: this.getTimeDiff(a.expiryDate)
		}));
		this.exportService.export(type, rows, 'application-deadlines');
	}

	onSorted(event: ApplicationContractDeadline[]): void {
		this.filteredApplications = event;
	}

	onRowClick(event: ApplicationContractDeadline): void {
		this.goToApp(event.application.id);
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.initializeSub?.unsubscribe();
	}
}

interface ApplicationDeadlinesCsv {
	name: string;
	type: string;
	expiryDate: string;
}
