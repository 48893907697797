import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UsageRepartition, UsageService} from "src/app/services/usage.service";
import {BaseChartDirective} from "ng2-charts";
import {ChartData, ChartOptions} from "chart.js";
import {ColorEnum} from "src/_variables";
import {finalize, forkJoin, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {Organization} from 'src/app/services/organization.service';
import {Usage} from 'src/app/services/model/application-contract.model';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';

@Component({
	selector: 'app-usage-doughnut',
	standalone: false,
	templateUrl: './usage-doughnut.component.html',
	styleUrls: ['./usage-doughnut.component.scss']
})
export class UsageDoughnutComponent implements OnInit, OnDestroy {

	@Input() filter: Subject<Organization|null|undefined>;
	@Input() limit?: number;

	@ViewChild(BaseChartDirective) chart: BaseChartDirective;

	tenantId: string;

	_initializing: boolean;
	_loading: boolean;

	data: UsageRepartition[] = [];
	realTotalUsage: number = 0;
	team: boolean = false;

	doughnutChartData: ChartData<'doughnut'>;
	polarChartData: ChartData<'polarArea'>;
	chartOptions: ChartOptions<'doughnut'>;
	polarChartOptions: ChartOptions<'polarArea'>;

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	constructor(private currentTenantService: CurrentTenantService,
							private usageService: UsageService) {
	}

	ngOnInit() {
		this.setDefaultChartData();
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.filter
			.pipe(tap(filter => this.team = filter?.type === 'business_unit'))
			.subscribe(filter => this.initialize(filter)));
	}

	private setDefaultChartData(): void {
		this.doughnutChartData = {
			labels: [],
			datasets: [{
				data: [ 0, 0 ],
				backgroundColor: [ColorEnum.accent, ColorEnum.chart_yellow],
				borderColor: [ColorEnum.accent, ColorEnum.chart_yellow],
				borderRadius: _ => {
					return this.data && this.data.length > 1 ? 5 : 0;
				}
			}]
		};
		this.polarChartData = {
			labels: [],
			datasets: [{
				data: [],
				borderWidth: 0,
			}],
		};
		this.chartOptions = {
			responsive: true,
			maintainAspectRatio: true,
			events: [],
			cutout: '65%',
		};
		this.polarChartOptions = {
			responsive: true,
			maintainAspectRatio: true,
			events: [],
			scales: {
				r: {
					beginAtZero: true,
					ticks: {
						font: {
							family: "'Proxima Nova', 'Helvetica Neue', sans-serif",
						},
						color: 'black',
						backdropColor: 'transparent',
						z: 1,
						maxTicksLimit: 5,
						callback: (value) => {if (typeof value == "number" && value % 1 === 0) {return value;} else {return undefined}},
					}
				}
			}
		};
	}

	initialize(org?: Organization|null): void {
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				switchMap(() => forkJoin([
					this.usageService.getUsageRepartitionByTenantId(this.tenantId, org?.organizationId, this.limit, QueryRangeType.PAST_1_MONTH),
					this.usageService.getUsageByTenantId(this.tenantId, org?.organizationId, QueryRangeType.PAST_1_MONTH),
				])),
				tap(([data, usage]) => this.setUsageRepartition(data, usage)),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	private setUsageRepartition(data: UsageRepartition[], totalUsage: Usage): void {
		this.data = data;
		this.realTotalUsage = totalUsage.value ?? 0;
		this.polarChartOptions.scales!.r!.max = this.realTotalUsage ? this.realTotalUsage : 1;
		this.clearDoughnutColor();
		if (this.data) {
			let colors: string[] = [];
			this.data.forEach((usageRepartition: UsageRepartition, index) => {
				if (usageRepartition.usage && this.realTotalUsage) {
					usageRepartition.percentage = Math.round(usageRepartition.usage / this.realTotalUsage * 100) <= 1 ? 1 : Math.round(usageRepartition.usage/ this.realTotalUsage * 100)
				}
				if (index == 0) colors.push(ColorEnum.doughnut_colors_0);
				if (index == 1) colors.push(ColorEnum.doughnut_colors_1);
				if (index == 2) colors.push(ColorEnum.doughnut_colors_2);
				if (index == 3) colors.push(ColorEnum.doughnut_colors_3);
				// interlacing values with transparent values to give the doughnut spacing between each arcs
				if (this.data && index < this.data.length && !this.team) {
					colors.push(ColorEnum.transparent);
				}
			});
			if (!this.team) {
				const interleave = (arr: UsageRepartition[], x: UsageRepartition) => arr.flatMap(e => [e, x]).slice(0, -1)
				const spacing = this.realTotalUsage ? this.realTotalUsage / 100 : 1;
				this.data = interleave(this.data, {id: undefined, name: undefined, usage: spacing, percentage: 0});
				if (this.data.length > 1) this.data.push({id: undefined, name: "", usage: spacing, percentage: 0});
			}
			this.doughnutChartData.datasets[0].data = this.data.map((item) => item.usage);
			this.doughnutChartData.datasets[0].backgroundColor = colors;
			this.doughnutChartData.datasets[0].borderColor = colors;
			this.polarChartData.datasets[0].data = this.data.map((item) => item.usage);
			this.polarChartData.datasets[0].backgroundColor = colors;
			this.polarChartData.datasets[0].borderColor = colors;
			if (!this.realTotalUsage && !this.team) {
				this.doughnutChartData.datasets[0].data.push(1);
				this.addDoughnutColor(ColorEnum.medium_grey);
			}
		}
	}

	protected addDoughnutColor(color:string): void {
		// @ts-ignore
		this.doughnutChartData.datasets[0]?.backgroundColor?.push(color);
		// @ts-ignore
		this.doughnutChartData.datasets[0]?.borderColor?.push(color);
		// @ts-ignore
		this.polarChartData.datasets[0]?.backgroundColor?.push(color);
		// @ts-ignore
		this.polarChartData.datasets[0]?.borderColor?.push(color);
	}

	protected clearDoughnutColor(): void {
		// @ts-ignore
		this.doughnutChartData.datasets[0].backgroundColor = [];
		// @ts-ignore
		this.doughnutChartData.datasets[0].borderColor = [];
		// @ts-ignore
		this.polarChartData.datasets[0].backgroundColor = [];
		// @ts-ignore
		this.polarChartData.datasets[0].borderColor = [];
	}

	get filteredData(): UsageRepartition[] {
		return this.data?.filter((item) => !this.isUndefined(item.id));
	}

	get filteredDataLength(): number {
		return this.filteredData?.length;
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.initializeSub?.unsubscribe();
	}

	isUndefined(val: any): boolean { return typeof val === 'undefined'; }
}
