import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {finalize, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {ApplicationTechnologyService} from 'src/app/services/back/application-technology.service';
import {CatalogTechnologyService} from 'src/app/services/back/catalog-technology.service';
import {MatDialog} from '@angular/material/dialog';
import {ApplicationTechnologyFormData} from '../application-technology-form/application-technology-form.component';
import {ApplicationTechnologyFormDialogComponent} from '../application-technology-form/dialog/application-technology-form-dialog.component';
import {SnackbarService} from 'src/app/services/front/snackbar.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmComponent, ConfirmModel} from '../../../../../global/dialog/confirm/confirm.component';
import {Technology} from 'src/app/services/model/application-technology.model';
import {CatalogTechnologySelect} from 'src/app/services/model/catalog-technology.model';

@Component({
	selector: 'app-application-technology-preview',
	standalone: false,
	templateUrl: './application-technology-preview.component.html',
	styleUrls: ['./application-technology-preview.component.scss']
})
export class ApplicationTechnologyPreviewComponent implements OnInit, OnDestroy {

	@Output() updated: EventEmitter<void> = new EventEmitter<void>();

	tenantId: string;
	applicationId: string;
	technologies: Technology[] = [];

	_initializing: boolean;
	_loading: boolean;
	_loadingDialog: boolean;
	initialized: boolean = false;

	catalogTechnologies: CatalogTechnologySelect[] = [];
	deleteTechnology = (technologyId: string) => this.doDeleteTechnology(technologyId);

	subscription: Subscription = new Subscription();

	constructor(private applicationTechnologyService: ApplicationTechnologyService,
							private technologyCatalogService: CatalogTechnologyService,
							private applicationDetailService: ApplicationDetailService,
							private translate: TranslateService,
							private snackBar: SnackbarService,
							private dialog: MatDialog) {
	}

	ngOnInit(): void {
		this.subscription.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));
	}

	setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.applicationId = data.instance.applicationId;
	}

	initialize(): void {
		this.switchLoading()
			.pipe(
				switchMap(() => this.applicationTechnologyService.getAllTechnologyByApplicationId(this.tenantId, this.applicationId)),
				tap(instanceTechnologies => this.setApplicationTechnologies(instanceTechnologies)),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true);
	}

	private setApplicationTechnologies(technologies: Technology[]): void {
		this.technologies = technologies
			.sort((a, b) => a.technology.name.localeCompare(b.technology.name));
	}

	openApplicationTechnologyFormDialog(): void {
		this.switchLoadingDialog()
			.pipe(
				switchMap(() => this.getCatalogTechnologies()),
				map(technologies => this.buildApplicationTechnologyFormData(technologies)),
				switchMap(data => this.dialog.open(ApplicationTechnologyFormDialogComponent, { position: { right: '162.5px' }, width: '475px', data: data }).afterClosed()),
				filter(updated => !!updated),
				tap(() => this.snackBar.show(this.translate.instant('page.application.detail.update.success'))),
				tap(() => this.updated.emit()),
				finalize(() => this.switchLoadingDialog()))
			.subscribe(() => this.initialize());
	}

	private getCatalogTechnologies(): Observable<CatalogTechnologySelect[]> {
		return of(this.catalogTechnologies).pipe(
			switchMap(catalogTechnologies => catalogTechnologies.length > 0
				? of(catalogTechnologies)
				: this.technologyCatalogService.getAllCatalogTechnologyByTenantId(this.tenantId)),
			tap(catalogTechnologies => this.catalogTechnologies = catalogTechnologies));
	}

	private buildApplicationTechnologyFormData(catalogTechnologies: CatalogTechnologySelect[]): ApplicationTechnologyFormData {
		return {
			tenantId: this.tenantId,
			applicationId: this.applicationId,
			defaultData: this.technologies,
			preloaded: {
				technologies: catalogTechnologies
			}
		}
	}

	doDeleteTechnology(technologyId: string): void {
		const data: ConfirmModel = {
			message: this.translate.instant('confirmModal.deleteTechnology'),
			closeButton: this.translate.instant('button.cancel'),
			confirmButton: this.translate.instant('button.delete')
		};
		this.dialog.open<ConfirmComponent, any, boolean>(ConfirmComponent, { position: { right: '162.5px' }, width: '475px', data: data })
			.afterClosed()
			.pipe(
				filter(result => !!result),
				switchMap(() => this.applicationTechnologyService.deleteApplicationTechnology(this.tenantId, this.applicationId, technologyId)),
				filter(deleted => !!deleted),
				tap(() => this.snackBar.show(this.translate.instant('page.application.detail.update.success'))),
				tap(() => this.updated.emit()))
			.subscribe(() => this.initialize());
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	switchLoadingDialog(): Observable<{}> {
		this._loadingDialog = !this._loadingDialog;
		return of({});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
