import {Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {TenantService} from 'src/app/services/tenant.service';
import {TopbarService} from 'src/app/services/front/topbar.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
	standalone: false,
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

	@ViewChildren('section') sections: QueryList<ElementRef>;

	menuItems: Array<MenuItem|MenuDivider> = [];

	subscription: Subscription = new Subscription();

	constructor(protected tenantService: TenantService,
							public activatedRoute: ActivatedRoute,
							protected translateService: TranslateService,
							protected topbarService: TopbarService,
				protected router: Router) {
	}

	ngOnInit(): void {
		// Set page title
		this.topbarService.onTitleChange(this.translateService.instant('menu.settings'), this.translateService.instant('menu.subtitle.settings'));

		// Build menu
		this.buildMenu();

		this.subscription.add(this.translateService.onDefaultLangChange.subscribe(() => {
			this.topbarService.onTitleChange(this.translateService.instant('menu.settings'), this.translateService.instant('menu.subtitle.settings'));
			this.buildMenu();
		}));


	}

	ngAfterViewInit() {
		if (this.activatedRoute.snapshot.queryParams['tab'] && this.activatedRoute.snapshot.queryParams['tab'] === 'tenant') {
			const element = document.querySelector('#item1')
			if (element) {
				element.scrollIntoView({behavior: 'smooth', block: 'start'});
			}
			this.router.navigate([], {queryParams: {tab: null}, queryParamsHandling: 'merge'});
		}
	}

	buildMenu() {
		this.menuItems = [
			{id: 1, type:'item', title: 'page.settings.menu.account', isSelected: true},
			{id: 2, type: 'divider'},
			{id: 3, type:'item', title: 'page.settings.menu.tenant', isSelected: false},
			{id: 4, type:'item', title: 'page.settings.menu.plan', isSelected: false}
			/**/
		]
	}

	onItemClick(item: MenuItem) {
		this.menuItems.forEach(item => {
			if (item.type === 'item') {
				item.isSelected = false
			}
		});
		item.isSelected = true;

		const element = document.querySelector('#item' + item.id)
		if (element) {
			if (element.id === 'item1') {
				element.scrollIntoView({behavior: 'smooth', block: 'end'});
			} else {
				element.scrollIntoView({behavior: 'smooth', block: 'start'});
			}
		}
	}

	@HostListener('document:mousewheel', ['$event'])
	updateSettingsMenuAccordingToScroll(event:any) {
		this.sections.toArray().forEach(section => {
			if (section.nativeElement.getBoundingClientRect().top < 20) {
				this.menuItems.forEach(item => {
					if (item.type === 'item' && section.nativeElement.id !== 'item' + item.id) {
						item.isSelected = false
					} else if (section.nativeElement.id === 'item' + item.id) {
						(item as MenuItem).isSelected = true
					}
				});
				return;
			}
		})
		/*if (this.plan.nativeElement.getBoundingClientRect().top < 20) {
			this.menuItems.forEach(item => item.type === 'item' ? item.isSelected = false : true);
			(this.menuItems[3] as MenuItem).isSelected = true;
		} else if (this.tenant.nativeElement.getBoundingClientRect().top < 20) {
			this.menuItems.forEach(item => item.type === 'item' ? item.isSelected = false : true);
			(this.menuItems[2] as MenuItem).isSelected = true;
		} else if (this.account.nativeElement.getBoundingClientRect().top < 20) {
			this.menuItems.forEach(item => item.type === 'item' ? item.isSelected = false : true);
			(this.menuItems[0] as MenuItem).isSelected = true;
		}*/
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}

export interface MenuItem {
	type: 'item',
	id: number,
	title?: string,
	isSelected?: boolean,
}

export interface MenuDivider {
	id: number,
	type: 'divider'
}
