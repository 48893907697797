import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	QueryList,
	ViewChildren
} from '@angular/core';
import {
	PerformanceHistory,
	PerformanceRating,
	SingleMetric
} from "src/app/services/application-instance.service";

@Component({
  selector: 'app-performance-chart',
	standalone: false,
  templateUrl: './performance-chart.component.html',
  styleUrls: ['./performance-chart.component.scss']
})
export class PerformanceChartComponent implements OnInit {
	@Input() data: PerformanceHistory[];
	@Output() onGoToSettings = new EventEmitter<boolean>();

	public singleMetrics: SingleMetric[] = [];

	public tooltip = {display: "none", top: "0px", left: "0px", metrics: this.singleMetrics}

	@ViewChildren('performance') performances: QueryList<ElementRef>

	public performanceBarChartData: PerformanceBarChartData[] = []

	public latestPerformance?: PerformanceHistory;

	displayTooltip(perf: PerformanceBarChartData, div: HTMLDivElement) {
		const left = div.offsetLeft + div.offsetWidth / 2 - 10 > 565 ? 565 : div.offsetLeft + div.offsetWidth / 2 - 10;
		const top = div.offsetTop + div.offsetHeight / 2 - 10;
		this.tooltip = {
			display: "block",
			top: top + "px",
			left: left + "px",
			metrics: perf.metrics
		}
	}

	hideTooltip() {
		this.tooltip = {
			display: "none",
			top: "0px",
			left: "0px",
			metrics: []
		}
	}

	ngOnInit(): void {
		if (this.data && this.data.length > 0) {
			if (this.data.length > 1) {
				for (let i = this.data.length - 1; i >= 0; i--) {
					if (this.data[i].globalRating) {
						this.latestPerformance = this.data[i];
						break;
					}
				}
			}
			this.data = this.data.slice(0, this.data.length);
		}
		this.data.forEach((item, index) => {
			const singleMetrics: SingleMetric[] = [];
			if (item.fcp) {
				singleMetrics.push(item.fcp)
			}
			if (item.lcp) {
				singleMetrics.push(item.lcp)
			}

			this.performanceBarChartData.push({day: new Date(item.timestamp), overallRating: item.globalRating, metrics: singleMetrics})
		})
	}
}

export interface PerformanceBarChartData {
	day: Date,
	overallRating?: PerformanceRating,
	metrics: SingleMetric[],
}
