<div class="w-100 h-100">
	<div class="header-height">
		<!-- Title -->
		<div class="pl-l pr-l pt-l pb-l slider-header">
			<div class="flex-center w-100">
				<div class="autodiscover-icon flex-center mb-xs" style="width: 45px; height: 45px">
					<img src="assets/icons/autodiscover.svg" width="24" class="filter-accent-secondary">
				</div>
				<span class="ml-s w-100" style="display: inline-block; line-height: 0.8rem">
					<div class="flex-align-center mb-xs">
						<div
							class="primary flex-column flex-justify-center flex-align-start gap-XS text-overflow-ellipsis"
							style="max-width: 500px;">
							<label class="font-L fw-700">{{ 'page.application.autodiscover.title' | translate }}</label>
							<label class="font-M fw-500">{{ 'page.application.autodiscover.subtitle' | translate }}</label>
						</div>
					</div>
				</span>
			</div>
			<div class="flex-align-center gap-S">
				<app-icon-button type="stroked" background [img]="'assets/icons/close-drawer.svg'"
								 (click)="close()">
				</app-icon-button>
			</div>
		</div>
	</div>
	<div class="content-height border-box overflow-y">
		<div class="sticky-element">
			<div style="padding-top: 20px;padding-left: 20px;padding-right: 20px">
				<div [class.tab-box-shadow]="isStickyElementPinned" class="tabs-container pad-XS flex-align-center gap-S" style="z-index: 999">
					<div [ngClass]="{'selected': selectedTab === tabSelectedEnum.ACTIVE }" (click)="onTabSelectionChange(tabSelectedEnum.ACTIVE)" class="c-p single-tab flex-align-center pad-left-S p-relative">
						<div class="flex-align-center gap-S">
							<img width="14" src="assets/tabs/waiting.svg" [ngClass]="{'filter-primary': selectedTab === tabSelectedEnum.ACTIVE, 'filter-grey': selectedTab !== tabSelectedEnum.ACTIVE }" />
							<span>{{'page.application.autodiscover.waiting' | translate}}</span>
						</div>
					</div>
					<div [ngClass]="{'selected': selectedTab === tabSelectedEnum.DISMISSED }" (click)="onTabSelectionChange(tabSelectedEnum.DISMISSED)" class="c-p single-tab flex-align-center pad-left-S p-relative">
						<div class="flex-align-center gap-S">
							<img width="14" src="assets/tabs/dismissed.svg" [ngClass]="{'filter-primary': selectedTab === tabSelectedEnum.DISMISSED, 'filter-grey': selectedTab !== tabSelectedEnum.DISMISSED }" />
							<span>{{'page.application.autodiscover.dismissed' | translate}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="pad-L">
			<div class="table" style="margin-top: 20px;overflow: visible;">
				<div class="table-header">
					<div [ngClass]="{'sort-active': sorting.key === 'name'}" (click)="sortBy('name', sorting.key === 'name' ? sorting.order === 'asc' ? 'desc' : 'asc' : 'asc')" class="greyed-hover c-p flex-align-center sort-column" style="padding-left: 60px;">
						{{ 'table.application' | translate }} <img src="assets/icons/arrow-right-black.svg" class="filter-grey ml-s" height="10" alt="" [ngStyle]="{'transform': 'rotate(' + (sorting.order === 'desc' && sorting.key === 'name' ? '90' : '-90') + 'deg)'}">
					</div>
					<div [ngClass]="{'sort-active': sorting.key === 'teams'}" (click)="sortBy('teams', sorting.key === 'teams' ? sorting.order === 'asc' ? 'desc' : 'asc' : 'asc')" class="greyed-hover c-p flex-align-center sort-column" style="padding-left: 10px;">
						{{ 'page.appDetails.businessUsers' | translate }} <img src="assets/icons/arrow-right-black.svg" class="filter-grey ml-s" height="10" alt="" [ngStyle]="{'transform': 'rotate(' + (sorting.order === 'desc' && sorting.key === 'teams' ? '90' : '-90') + 'deg)'}">
					</div>
					<div [ngClass]="{'sort-active': sorting.key === 'users'}" (click)="sortBy('users', sorting.key === 'users' ? sorting.order === 'asc' ? 'desc' : 'asc' : 'asc')" class="greyed-hover c-p flex-align-center sort-column" style="padding-left: 10px;">
						{{ 'page.title.activeUsers' | translate }} <img src="assets/icons/arrow-right-black.svg" class="filter-grey ml-s" height="10" alt="" [ngStyle]="{'transform': 'rotate(' + (sorting.order === 'desc' && sorting.key === 'users' ? '90' : '-90') + 'deg)'}">
					</div>
					<div [ngClass]="{'sort-active': sorting.key === 'date'}" (click)="sortBy('date', sorting.key === 'date' ? sorting.order === 'asc' ? 'desc' : 'asc' : 'asc')" class="greyed-hover c-p flex-align-center sort-column" style="padding-left: 10px;">
						{{ 'page.appDetails.overview.date' | translate }} <img src="assets/icons/arrow-right-black.svg" class="filter-grey ml-s" height="10" alt="" [ngStyle]="{'transform': 'rotate(' + (sorting.order === 'desc' && sorting.key === 'date' ? '90' : '-90') + 'deg)'}">
					</div>
					<div></div>
				</div>
				<ng-container *ngIf="displayedApplications.length > 0">
					<div class="table-row"  *ngFor="let app of displayedApplications">
						<div class="no-global flex-align-center gap-S">
							<app-application-icon style="padding-left: 15px;" [width]="35" [height]="35" [logo]="{ url: app.application.logo }"></app-application-icon>
							<div style="display: flex; flex-direction: column; align-items: baseline">
								<span class="fw-700 text-overflow-ellipsis" style="max-width: 160px;">{{app.application.name}}</span>
								<div class="no-global font-S fw-500 disp-f a-i-c gap-XS"
									 [tooltipTriggerFor]="tooltip1" tooltipPosition="right"
									 [tooltipDisabled]="app.fqdns.length <= 1">
									<div class="no-global grey text-overflow-ellipsis" style="max-width: 140px;">{{ app.fqdns[0] }}</div>
									<div class="no-global plus-bubble" *ngIf="app.fqdns.length > 1">+{{ app.fqdns.length - 1 }}</div>
								</div>
								<app-tooltip #tooltip1>
									<div class="flex-column">
										<span class="fw-700">FQDNs</span>
										<ul class="pl-l pr-l">
											<li class="font-S" [ngClass]="{'mb-s': i + 1 < app.fqdns.length}"
												*ngFor="let fqdn of app.fqdns; let i = index">{{ fqdn }}</li>
										</ul>
									</div>
								</app-tooltip>
							</div>
						</div>
						<div class="disp-f f-d-r a-i-c j-c-s" style="padding-left: 10px;">
							<div class="disp-f f-d-r a-i-c j-c-s" style="margin-left: 16px;"
								 *ngIf="app.businessUnits.length > 0"
								 [tooltipTriggerFor]="tooltip2" tooltipPosition="right">
								<div *ngFor="let team of app.businessUnits| slice:0:3;let i = index" style="margin-left: -16px;" [style.z-index]="5 + i">
									<div class="disp-f a-i-c j-c-c col bkg-accent-light radius-half white-border" style="height: 40px; width: 40px">
										<img [src]="!!$any(team).icon ? '/assets/icons/team_icon/' + $any(team).icon + '.svg' : 'assets/icons/team.svg'" class="svg-22 filter-accent">
									</div>
								</div>
								<div *ngIf="app.businessUnits.length > 3" class="disp-f a-i-c j-c-c col bkg-accent-light radius-half white-border" style="height: 40px; width: 40px; margin-left: -16px; z-index: 8">
									<span class="font-M fw-500 accent">+{{app.businessUnits.length - 3}}</span>
								</div>
							</div>
							<app-tooltip #tooltip2>
								<div class="flex-column" *ngFor="let structure of buildTeamTooltipView(app.businessUnits) | keyvalue">
									<span class="fw-700">{{ structure.value.name }}</span>
									<ul class="pl-l pr-l">
										<li class="font-S" [ngClass]="{'mb-s': i + 1 < structure.value.businessServices.length}"
											*ngFor="let team of structure.value.businessServices; let i = index">{{ team }}</li>
									</ul>
								</div>
							</app-tooltip>
						</div>
						<div style="padding-left: 10px;"><span>	<app-chips-count [value]="app.totalUniqueUser" type="user" bold></app-chips-count></span></div>
						<div style="padding-left: 10px;"><span>{{ formattedDateTime(app.discoveredAt) }}</span></div>
						<div style="display:flex; flex-direction: row; gap: 5px; justify-content: start">
							<app-mini-button *ngIf="app.status !== discoverAppStatus.ACCEPTED" (onClick)="onUpdateStatus($event, app.autodiscoverId, app.status === discoverAppStatus.ACTIVE ? discoverAppStatus.ACCEPTED : discoverAppStatus.ACTIVE)" size="s" color="accent" fill="border"><img class="filter-accent" src="assets/icons/add.svg"/> </app-mini-button>
							<app-mini-button *ngIf="app.status !== discoverAppStatus.DISMISSED" (onClick)="onUpdateStatus($event, app.autodiscoverId, app.status === discoverAppStatus.ACTIVE ? discoverAppStatus.DISMISSED : discoverAppStatus.ACTIVE)" size="s" color="danger" fill="border"><img class="filter-danger" src="assets/icons/close.svg"/></app-mini-button>
						</div>
					</div>
				</ng-container>
				<div *ngIf="displayedApplications.length <= 0" class="no-data disp-f j-c-c a-i-c">
					<img src="assets/icons/no.svg" class="filter-lightgrey mr-s">
					<div class="grey "> {{ 'page.application.autodiscover.noApplication' | translate }}</div>
				</div>
			</div>
		</div>
	</div>
</div>
