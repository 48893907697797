import {Component, OnDestroy, OnInit} from '@angular/core';
import {finalize, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {ApplicationLifeCycle, ApplicationLifeCycleForm} from 'src/app/services/model/application-life-cycle.model';
import {ApplicationLifeCycleService} from 'src/app/services/back/application-life-cycle.service';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {FormControl, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-application-life-cycle',
  standalone: false,
  templateUrl: './application-life-cycle.component.html',
  styleUrls: ['./application-life-cycle.component.scss']
})
export class ApplicationLifeCycleComponent implements OnInit, OnDestroy {

  tenantId: string;
  applicationId: string;
  disabled: boolean = false;

  _initializing: boolean;
  _loading: boolean;
  initialized: boolean = false;

  editing: boolean = false;
  percent: number;
  delta: number;
  formGroup: FormGroup;
  now: Date = new Date();

  readonly PHASE_IN_LENGTH: number = 5;
  readonly DEPLOYED_LENGTH: number = 8;
  readonly PHASE_OUT_LENGTH: number = 5;
  readonly TOTAL_LENGTH: number = this.PHASE_IN_LENGTH + this.DEPLOYED_LENGTH + this.PHASE_OUT_LENGTH;

  subscription: Subscription = new Subscription();

  constructor(private applicationLifeCycleService: ApplicationLifeCycleService,
              private applicationDetailService: ApplicationDetailService,
              private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    // TODO @TAN use applicationDetailService for all application drawer data to manage better common data
    //  instance is refresh when commissioning date is updated on overview and that's why its re-initialized here
    this.createForm();
    this.subscription.add(this.applicationDetailService.getInitializingChanges()
      .subscribe(initializing => this._initializing = initializing));
    this.subscription.add(this.applicationDetailService.getApplicationDetailDataChanges()
      .pipe(tap(data => this.setApplicationDetailData(data)))
      .subscribe(() => this.initialize()));
  }

  private createForm(): void {
    this.formGroup = new FormGroup({
      [Form.phaseIn]: new FormControl(undefined, []),
      [Form.deployed]: new FormControl(undefined, []),
      [Form.phaseOut]: new FormControl(undefined, []),
      [Form.retired]: new FormControl(undefined, []),
    });
  }

  private setApplicationDetailData(data: ApplicationDetailData): void {
    this.tenantId = data.tenantId;
    this.applicationId = data.instance.applicationId;
    this.disabled = !data.isEditor;
  }

  initialize(): void {
    this.switchLoading()
      .pipe(
        switchMap(() => this.applicationLifeCycleService.getApplicationLifeCycle(this.tenantId, this.applicationId)),
        tap(lifeCycle => this.setLifeCycle(lifeCycle)),
        finalize(() => this.switchLoading()))
      .subscribe(() => this.initialized = true);
  }

  private setLifeCycle(lifeCycle: ApplicationLifeCycle|null): void {
    this.phaseInFormControl.setValue(!lifeCycle?.phaseInDate ? null : new Date(lifeCycle.phaseInDate));
    this.deployedFormControl.setValue(!lifeCycle?.deployedDate ? null : new Date(lifeCycle.deployedDate));
    this.phaseOutFormControl.setValue(!lifeCycle?.phaseOutDate ? null : new Date(lifeCycle.phaseOutDate));
    this.retiredFormControl.setValue(!lifeCycle?.retiredDate ? null :new Date(lifeCycle.retiredDate));
    this.computePercent();
  }

  private computePercent(): void {
    const now: number = this.now.getTime();
    const phaseIn: number|undefined = this.phaseInFormControl.value?.getTime();
    const deployed: number|undefined = this.deployedFormControl.value?.getTime();
    const phaseOut: number|undefined = this.phaseOutFormControl.value?.getTime();
    const retired: number|undefined = this.retiredFormControl.value?.getTime();
    let leftRef: number;
    if (retired && retired < now) leftRef = retired;
    else if (phaseOut && phaseOut < now) leftRef = phaseOut;
    else if (deployed && deployed < now) leftRef = deployed;
    else if (phaseIn && phaseIn < now) leftRef = phaseIn;
    else leftRef = Number.NEGATIVE_INFINITY;
    let rightRef: number;
    if (phaseIn && phaseIn > now) rightRef = phaseIn;
    else if (deployed && deployed > now) rightRef = deployed;
    else if (phaseOut && phaseOut > now) rightRef = phaseOut;
    else if (retired && retired > now) rightRef = retired;
    else rightRef = Number.POSITIVE_INFINITY;
    if (leftRef === Number.NEGATIVE_INFINITY && rightRef !== Number.POSITIVE_INFINITY) {
      if (rightRef === phaseIn) this.percent = this.phaseInStartPosition();
      else if (rightRef === deployed) this.percent = this.phaseInHalfPosition();
      else if (rightRef === phaseOut) this.percent = this.deployedHalfPosition();
      else if (rightRef === retired) this.percent = this.phaseOutHalfPosition();
      else this.percent = this.retiredStartPosition();
    } else if (leftRef !== Number.NEGATIVE_INFINITY && rightRef === Number.POSITIVE_INFINITY) {
      if (leftRef === retired) this.percent = this.retiredStartPosition();
      else if (leftRef === phaseOut) this.percent = this.phaseOutHalfPosition();
      else if (leftRef === deployed) this.percent = this.deployedHalfPosition();
      else if (leftRef === phaseIn) this.percent = this.phaseInHalfPosition();
      else this.percent = this.phaseInStartPosition();
    } else if (leftRef !== Number.NEGATIVE_INFINITY && rightRef !== Number.POSITIVE_INFINITY) {
      let leftRefLength: number;
      if (leftRef === retired) leftRefLength = this.PHASE_IN_LENGTH + this.DEPLOYED_LENGTH + this.PHASE_OUT_LENGTH;
      else if (leftRef === phaseOut) leftRefLength = this.PHASE_IN_LENGTH + this.DEPLOYED_LENGTH;
      else if (leftRef === deployed) leftRefLength = this.PHASE_IN_LENGTH;
      else leftRefLength = 0;
      let rightRefLength: number;
      if (rightRef === phaseIn) rightRefLength = 0;
      else if (rightRef === deployed) rightRefLength = this.PHASE_IN_LENGTH;
      else if (rightRef === phaseOut) rightRefLength = this.PHASE_IN_LENGTH + this.DEPLOYED_LENGTH;
      else rightRefLength = this.PHASE_IN_LENGTH + this.DEPLOYED_LENGTH + this.PHASE_OUT_LENGTH;
      this.percent = ((((now - leftRef) / (rightRef - leftRef)) * ((rightRefLength - leftRefLength) / this.TOTAL_LENGTH)) * 100)
        + ((leftRefLength / this.TOTAL_LENGTH) * 100);
    } else {
      this.percent = this.deployedHalfPosition();
    }
    // /!\ the delta was calculated empirically... delta might come from borders /!\
    if (this.isPhaseIn()) this.delta = this.percent >= ((5 / 18) * 100 * 0.99) ? 1 : -1;
    else if (this.isDeployed()) this.delta = this.percent >= this.deployedHalfPosition() ? (this.percent >= (((5 + 8) / 18) * 100 * 0.98) ? 6 : 0.5) : 1;
    else if (this.isPhaseOut()) this.delta = this.percent >= this.phaseOutHalfPosition() ? (this.percent >= 99.5 ? 8 : 5.5) : 6;
    else this.delta = 7;
  }

  isPhaseIn = (): boolean => this.percent < this.deployedStartPosition();
  isDeployed = (): boolean => this.percent >= this.deployedStartPosition() && this.percent < this.phaseOutStartPosition();
  isPhaseOut = (): boolean => this.percent >= this.phaseOutStartPosition() && this.percent < this.retiredStartPosition();
  isRetired = (): boolean => this.percent >= this.retiredStartPosition();

  phaseInStartPosition = (): number => 0;
  phaseInHalfPosition = (): number => (((this.PHASE_IN_LENGTH / 2) / this.TOTAL_LENGTH) * 100);
  deployedStartPosition = (): number => ((this.PHASE_IN_LENGTH / this.TOTAL_LENGTH) * 100);
  deployedHalfPosition = (): number => (((this.PHASE_IN_LENGTH + (this.DEPLOYED_LENGTH / 2)) / this.TOTAL_LENGTH) * 100);
  phaseOutStartPosition = (): number => (((this.PHASE_IN_LENGTH + this.DEPLOYED_LENGTH) / this.TOTAL_LENGTH) * 100);
  phaseOutHalfPosition = (): number => (((this.PHASE_IN_LENGTH + this.DEPLOYED_LENGTH + (this.PHASE_OUT_LENGTH / 2)) / this.TOTAL_LENGTH) * 100);
  retiredStartPosition = (): number => 100;

  filterPhaseIn = (date: Date | null): boolean => {
    return !!date
      && date.getTime() < (this.deployedFormControl.value?.getTime() ?? this.phaseOutFormControl.value?.getTime() ?? this.retiredFormControl.value?.getTime() ?? Number.POSITIVE_INFINITY);
  }

  filterDeployed = (date: Date | null): boolean => {
    return !!date
      && date.getTime() > (this.phaseInFormControl.value?.getTime() ?? Number.NEGATIVE_INFINITY)
      && date.getTime() < (this.phaseOutFormControl.value?.getTime() ?? this.retiredFormControl.value?.getTime() ?? Number.POSITIVE_INFINITY);
  }

  filterPhaseOut = (date: Date | null): boolean => {
    return !!date
      && date.getTime() > (this.deployedFormControl.value?.getTime() ?? this.phaseInFormControl.value?.getTime() ?? Number.NEGATIVE_INFINITY)
      && date.getTime() < (this.retiredFormControl.value?.getTime() ?? Number.POSITIVE_INFINITY);
  }

  filterRetired = (date: Date | null): boolean => {
    return !!date
      && date.getTime() > (this.phaseOutFormControl.value?.getTime() ?? this.deployedFormControl.value?.getTime() ?? this.phaseInFormControl.value?.getTime() ?? Number.NEGATIVE_INFINITY);
  };

  get phaseInFormControl(): FormControl {
    return this.formGroup.get(Form.phaseIn) as FormControl;
  }

  get deployedFormControl(): FormControl {
    return this.formGroup.get(Form.deployed) as FormControl;
  }

  get phaseOutFormControl(): FormControl {
    return this.formGroup.get(Form.phaseOut) as FormControl;
  }

  get retiredFormControl(): FormControl {
    return this.formGroup.get(Form.retired) as FormControl;
  }

  save(): void {
    const from: ApplicationLifeCycleForm = this.buildApplicationLifeCycleForm();
    this.applicationLifeCycleService.updateApplicationLifeCycle(this.tenantId, this.applicationId, from)
      // TODO @TAN must not refresh the whole application instance
      .subscribe(() => this.applicationDetailService.refreshApplicationInstance());
  }

  buildApplicationLifeCycleForm(): ApplicationLifeCycleForm {
    return {
      phaseInDate: !this.phaseInFormControl.value
        ? undefined
        : new Date(this.datePipe.transform(this.phaseInFormControl.value, "yyyy-MM-dd")!),
      deployedDate: !this.deployedFormControl.value
        ? undefined
        : new Date(this.datePipe.transform(this.deployedFormControl.value, "yyyy-MM-dd")!),
      phaseOutDate: !this.phaseOutFormControl.value
        ? undefined
        : new Date(this.datePipe.transform(this.phaseOutFormControl.value, "yyyy-MM-dd")!),
      retiredDate: !this.retiredFormControl.value
        ? undefined
        : new Date(this.datePipe.transform(this.retiredFormControl.value, "yyyy-MM-dd")!),
    };
  }

  switchLoading(): Observable<{}> {
    this._loading = !this._loading;
    return of({});
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

enum Form {
  phaseIn = 'phaseIn',
  deployed = 'deployed',
  phaseOut = 'phaseOut',
  retired = 'retired',
}
