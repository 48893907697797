import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ContractMonthlyCost} from 'src/app/services/model/application-contract.model';
import {finalize, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {QueryRangeType, TenantFinanceService} from 'src/app/services/back/tenant-finance.service';
import {FormControl} from '@angular/forms';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {KeyValue} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {rangeIterable} from 'src/app/utils/html.utils';
import {Organization} from 'src/app/services/organization.service';

@Component({
  selector: 'app-financial-projection',
  standalone: false,
  templateUrl: './financial-projection.component.html',
  styleUrls: ['./financial-projection.component.scss']
})
export class FinancialProjectionComponent implements OnInit, OnDestroy {

  @Input() filter: Subject<Organization|null|undefined>;
  lastFilter?: Organization|null;

  tenantId: string;

  _initializing: boolean;
  _loading: boolean;

  costs: CostProjectionGraph[] = [];
  rangeTypeControl: FormControl;
  rangeTypes: KeyValue<QueryRangeType, string>[] = [];
  ordinates: number[] = [];
  readonly NB_GRAPH_ABSCISSA: number = 12;

  initializeSub: Subscription;
  subscription: Subscription = new Subscription();

  constructor(private currentTenantService: CurrentTenantService,
              private tenantFinanceService: TenantFinanceService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.buildRangeTypes();
    this.buildForm();
    this.subscription.add(this.currentTenantService.getInitializingChanges()
      .subscribe(initializing => this._initializing = initializing));
    this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
      .pipe(tap(tenantId => this.tenantId = tenantId))
      .subscribe(() => this.initialize()));
    this.subscription.add(this.filter
      .subscribe(filter => this.initialize(filter)));
  }

  private buildForm(): void {
    this.rangeTypeControl = new FormControl(this.rangeTypes.find(rangeType => rangeType.key === QueryRangeType.PROJECTION_10_YEAR));
    this.subscription.add(this.rangeTypeControl.valueChanges
      .subscribe(() => this.initialize(this.lastFilter)));
  }

  private buildRangeTypes(): void {
    this.rangeTypes = [
      {key: QueryRangeType.PROJECTION_1_YEAR, value: '1 ' + this.translate.instant('page.appDetails.finance.contracts.yearLittle')},
      {key: QueryRangeType.PROJECTION_3_YEAR, value: '3 ' + this.translate.instant('page.appDetails.finance.contracts.yearLittlePlural')},
      {key: QueryRangeType.PROJECTION_10_YEAR, value: '10 ' + this.translate.instant('page.appDetails.finance.contracts.yearLittlePlural')}
    ];
  }

  initialize(org?: Organization|null): void {
    this.lastFilter = org;
    this.initializeSub?.unsubscribe();
    this.initializeSub = this.switchLoading()
      .pipe(
        switchMap(() => this.tenantFinanceService.getAllContractMonthlyCostByTenantId(this.tenantId, org?.organizationId, this.queryRangeType)),
        tap(monthlyCosts => this.setMonthlyCosts(monthlyCosts)),
        finalize(() => this.switchLoading()))
      .subscribe();
  }

  private setMonthlyCosts(monthlyCosts: ContractMonthlyCost[]): void {
    if (this.queryRangeType === QueryRangeType.PROJECTION_1_YEAR) {
      this.setCostProjectionGraphData(monthlyCosts, 1, 'month');
    } else if (this.queryRangeType === QueryRangeType.PROJECTION_3_YEAR) {
      this.setCostProjectionGraphData(monthlyCosts, 3, 'quarter');
    } else if (this.queryRangeType === QueryRangeType.PROJECTION_10_YEAR) {
      this.setCostProjectionGraphData(monthlyCosts, 12, 'year');
    }
  }

  private calculateYAxis(maxValue: number): number[] {
    const nbOrdinates: number = 4;
    const log10: number = Math.log10(maxValue);
    const exponent: number = Math.floor(log10);
    const multiple: number = Math.pow(10, exponent);
    const rawIncrement: number = maxValue / nbOrdinates;
    const roundedIncrement: number = Math.ceil((rawIncrement * 10) / multiple) * (multiple / 10);
    // nbOrdinates + 1 but greater ordinate will not be displayed
    return rangeIterable(0, nbOrdinates + 1)
      .map(i => i * roundedIncrement)
      .reverse();
  }

  private setCostProjectionGraphData(monthlyCosts: ContractMonthlyCost[], groupByMonth: number, dateDisplay: 'month'|'quarter'|'year'): void {
    const totals: number[] = [];
    rangeIterable(0, this.NB_GRAPH_ABSCISSA - 1)
      .forEach(x => totals[x] = monthlyCosts
        .slice(x * groupByMonth, (x * groupByMonth) + groupByMonth)
        .map(monthlyCost => monthlyCost.cost.value ?? 0)
        .reduce((a, b) => a + b, 0));
    const maxTotal: number = Math.max(...totals, 10);
    this.ordinates = this.calculateYAxis(maxTotal);
    const maxScale: number = this.ordinates[0];
    this.costs = totals.map((total, index): CostProjectionGraph => ({
      percentage: (total / maxScale) * 100,
      date: new Date(monthlyCosts[index * groupByMonth].month),
      dateDisplay: dateDisplay,
      value: total
    }));
  }

  get queryRangeType(): QueryRangeType {
    return (this.rangeTypeControl.value as KeyValue<QueryRangeType, string>).key;
  }

  switchLoading(): Observable<{}> {
    this._loading = !this._loading;
    return of({});
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

interface CostProjectionGraph {
  value: number;
  percentage: number;
  date: Date;
  dateDisplay: 'month'|'quarter'|'year';
}
