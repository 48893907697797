import {Component, Input} from '@angular/core';
import {CriticalityLevel} from 'src/app/services/tenant.service';

@Component({
  selector: 'app-chips-criticality',
  standalone: false,
  templateUrl: './chips-criticality.component.html',
  styleUrls: ['./chips-criticality.component.scss']
})
export class ChipsCriticalityComponent {
  @Input() criticality: CriticalityLevel|undefined|null;

  private _short: boolean;
  @Input()
  get short() {
    return this._short;
  }
  set short(value: any) {
    this._short = value != null && `${value}` !== 'false';
  }
}
