import {Component, Input} from "@angular/core";

@Component({
	selector: 'app-check',
	standalone: false,
	template: `
		<svg class="checkmark" [style.width]="width" [style.height]="height" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path class="checkmark__check" stroke-width="5" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
		</svg>
	`,
	styleUrls: ['./check.component.scss']
})
export class CheckComponent {
	@Input() width: string = '20px'
	@Input() height: string = '20px'
}
