import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationIconComponent } from './application-icon.component';


@NgModule({
    declarations: [
        ApplicationIconComponent
    ],
    exports: [
        ApplicationIconComponent
    ],
    imports: [
        CommonModule
    ]
})
export class ApplicationIconModule { }
