import { ChangeDetectionStrategy, Component } from '@angular/core';
import {ContentLoaderComponent} from '@ngneat/content-loader';


@Component({
	selector: 'app-linechart-loader',
	standalone: false,
	template: `
    <content-loader viewBox="0 0 400 120">
		<svg:rect x="0" y="0" width="4" height="120" />
      	<svg:rect x="0" y="120" width="400" height="4" />
		<svg:path
			d="M 0,118.94374257463923 C 0,118.94374257463923 21,116.98466397799639 21,116.98466397799639 C 21,116.98466397799639 42,111.97571317292571 42,111.97571317292571 C 42,111.97571317292571 63,110.60298118033049 63,110.60298118033049 C 63,110.60298118033049 84,106.89803224843814 84,106.89803224843814 C 84,106.89803224843814 105,94.73565512411447 105,94.73565512411447 C 105,94.73565512411447 126,87.87116266477753 126,87.87116266477753 C 126,87.87116266477753 147,90.05623544566265 147,90.05623544566265 C 147,90.05623544566265 168,87.04087263641466 168,87.04087263641466 C 168,87.04087263641466 189,89.06866878127053 189,89.06866878127053 C 189,89.06866878127053 210,75.5892741654658 210,75.5892741654658 C 210,75.5892741654658 231,83.75901459439943 231,83.75901459439943 C 231,83.75901459439943 252,48.18586800809621 252,48.18586800809621 C 252,48.18586800809621 273,43.09054413091465 273,43.09054413091465 C 273,43.09054413091465 294,38.523592650165114 294,38.523592650165114 C 294,38.523592650165114 315,35.3604059106797 315,35.3604059106797 C 315,35.3604059106797 336,29.85141022872423 336,29.85141022872423 C 336,29.85141022872423 357,35.33097546971071 357,35.33097546971071 C 357,35.33097546971071 378,59.88364720658578 378,59.88364720658578 C 378,59.88364720658578 399,53.136652919938385 399,53.136652919938385,L 400 120,L 0 120Z"
			fill="#444cf71a"/>
		<svg:path
			d="M 0,118.94374257463923 C 0,118.94374257463923 21,116.98466397799639 21,116.98466397799639 C 21,116.98466397799639 42,111.97571317292571 42,111.97571317292571 C 42,111.97571317292571 63,110.60298118033049 63,110.60298118033049 C 63,110.60298118033049 84,106.89803224843814 84,106.89803224843814 C 84,106.89803224843814 105,94.73565512411447 105,94.73565512411447 C 105,94.73565512411447 126,87.87116266477753 126,87.87116266477753 C 126,87.87116266477753 147,90.05623544566265 147,90.05623544566265 C 147,90.05623544566265 168,87.04087263641466 168,87.04087263641466 C 168,87.04087263641466 189,89.06866878127053 189,89.06866878127053 C 189,89.06866878127053 210,75.5892741654658 210,75.5892741654658 C 210,75.5892741654658 231,83.75901459439943 231,83.75901459439943 C 231,83.75901459439943 252,48.18586800809621 252,48.18586800809621 C 252,48.18586800809621 273,43.09054413091465 273,43.09054413091465 C 273,43.09054413091465 294,38.523592650165114 294,38.523592650165114 C 294,38.523592650165114 315,35.3604059106797 315,35.3604059106797 C 315,35.3604059106797 336,29.85141022872423 336,29.85141022872423 C 336,29.85141022872423 357,35.33097546971071 357,35.33097546971071 C 357,35.33097546971071 378,59.88364720658578 378,59.88364720658578 C 378,59.88364720658578 399,53.136652919938385 399,53.136652919938385"
			/>
	</content-loader>
  `,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinechartLoaderComponent extends ContentLoaderComponent {}
