import {Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChartData, ChartOptions} from 'chart.js';
import {BaseChartDirective} from 'ng2-charts';
import {finalize, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {ColorEnum} from 'src/_variables';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {filter} from "rxjs/operators";
import {ApplicationContractService} from 'src/app/services/back/application-contract.service';
import {ContractType, ContractTypeCost} from 'src/app/services/model/application-contract.model';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';

@Component({
	selector: 'app-application-cost-distribution',
	standalone: false,
	templateUrl: './application-cost-distribution.component.html',
	styleUrls: ['./application-cost-distribution.component.scss']
})
export class ApplicationCostDistributionComponent implements OnInit, OnDestroy {

	@Input() refreshFinancialData: EventEmitter<void>;

	@ViewChild(BaseChartDirective) chart: BaseChartDirective;

	tenantId: string;
	applicationId: string;
	isEditor: boolean = false;

	_initializing: boolean;
	_loading: boolean;
	initialized: boolean = false;

	costs: FinancialCosts;
	doughnutChartData: ChartData<'doughnut'>;
	chartOptions: ChartOptions<'doughnut'>;
	year: number = new Date().getFullYear();

	subscriptions: Subscription = new Subscription();

	constructor(private applicationDetailService: ApplicationDetailService,
							private applicationContractService: ApplicationContractService) {
	}

	ngOnInit(): void {
		this.setChartData();
		this.subscriptions.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscriptions.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));
		this.subscriptions.add(this.refreshFinancialData
			.pipe(switchMap(() => this.fetchApplicationFinance()))
			.subscribe());
	}

	initialize(): void {
		this.subscriptions.add(this.switchLoading()
			.pipe(
				switchMap(() => this.fetchApplicationFinance()),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	private setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.applicationId = data.instance.applicationId;
	}

	private fetchApplicationFinance(): Observable<{}> {
		return this.applicationContractService.getAllApplicationContractTypeCost(this.tenantId, this.applicationId, QueryRangeType.CURRENT_YEAR)
			.pipe(tap(yearlyFinance => this.setYearlyFinancialCosts(yearlyFinance)));
	}

	private setChartData() {
		this.doughnutChartData = {
			labels: [],
			datasets: [
				{
					data: [ 0, 0 ],
					backgroundColor: [ColorEnum.accent, ColorEnum.chart_yellow],
					borderColor: [ColorEnum.accent, ColorEnum.chart_yellow],
					borderRadius: 5,
					spacing: 2
				}
			],
		};
		this.chartOptions = {
			responsive: true,
			maintainAspectRatio: true,
			events: [],
			cutout: '65%',
			elements: {
				arc: {
					borderWidth: 1,
				}
			}
		};
	}

	private setYearlyFinancialCosts(contractTypeCosts: ContractTypeCost[]): void {
		this.costs = {
			total: 0,
			license: contractTypeCosts
				.filter(c => c.contractType === ContractType.LICENSE)
				.map(c => c.cost.value ?? 0)
				.reduce((a, b) => a + b, 0),
			service: contractTypeCosts
				.filter(c => c.contractType === ContractType.SERVICE)
				.map(c => c.cost.value ?? 0)
				.reduce((a, b) => a + b, 0),
			subscription: contractTypeCosts
				.filter(c => c.contractType === ContractType.SUBSCRIPTION)
				.map(c => c.cost.value ?? 0)
				.reduce((a, b) => a + b, 0),
			licensePercentage: 0,
			servicePercentage: 0
		};

		const licenseCost: number = this.costs.license + this.costs.subscription;
		const serviceCost: number = this.costs.service;

		this.costs.total = licenseCost + serviceCost;
		this.costs.licensePercentage = Math.round((licenseCost / this.costs.total) * 100);
		this.costs.servicePercentage = Math.round((serviceCost / this.costs.total) * 100);

		this.doughnutChartData.datasets[0].data = [];
		this.clearDoughnutColor();

		this.doughnutChartData.datasets[0].data.push(licenseCost);
		this.addDoughnutColor(ColorEnum.licence);

		this.doughnutChartData.datasets[0].data.push(serviceCost);
		this.addDoughnutColor(ColorEnum.service);

		if (this.costs.total === 0) {
			this.doughnutChartData.datasets[0].data.push(1);
			this.addDoughnutColor(ColorEnum.medium_grey);
		}

		if (this.chart) {
			this.chart.update();
		}
	}

	private addDoughnutColor(color:string) {
		(this.doughnutChartData.datasets[0]?.backgroundColor as string[]).push(color);
		(this.doughnutChartData.datasets[0]?.borderColor as string[]).push(color);
	}

	private clearDoughnutColor() {
		this.doughnutChartData.datasets[0].backgroundColor = [];
		this.doughnutChartData.datasets[0].borderColor = [];
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() : void {
		this.subscriptions.unsubscribe();
	}
}

export interface FinancialCosts {
	total: number;
	service: number;
	license: number;
	subscription: number;
	licensePercentage: number;
	servicePercentage: number;
}
