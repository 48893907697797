import {Component, Input} from '@angular/core';
import {CatalogTechnology, CatalogTechnologyVersion} from 'src/app/services/model/catalog-technology.model';

@Component({
	selector: 'app-technology-chips',
	standalone: false,
	templateUrl: './technology-chips.component.html',
	styleUrls: ['./technology-chips.component.scss']
})
export class TechnologyChipsComponent {
	@Input() technology: CatalogTechnology;
	@Input() version?: CatalogTechnologyVersion|null;
	// TODO @TAN design-system/DisplayTechnologyComponent and handle disabled and delete logic in parent component not here
	@Input() disabled: boolean = false;
	@Input() delete?: (technologyId: string) => void;
}
