import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FilterComponent} from "./filter.component";
import {DeckContentLoaderModule} from "../title-content-loader/deck-content-loader.module";
import {TranslateModule} from "@ngx-translate/core";
import {DesignSystemModule} from "../../design-system/design-system.module";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@NgModule({
  declarations: [
	  FilterComponent
  ],
	imports: [
		CommonModule,
		DeckContentLoaderModule,
		TranslateModule,
		DesignSystemModule,
		MatProgressSpinner
	],
	exports: [
		FilterComponent
	]
})
export class FilterModule { }
